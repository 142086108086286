
import FacebookButton from '@/components/FacebookButton.vue';
import GoogleView from '@/components/GoogleView.vue';
export default {
  props: {
    title: {
      type: String,
    },
    redirectToLink: {
      type: String,
      default: null,
    },
  },
  methods: {
    async glogin() {
      const googleUser = await this.$gAuth.signIn();
    },
  },
  components: {
    FacebookButton,
    GoogleView,
  },
};
