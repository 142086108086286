
import { Button } from 'element-ui';
import ConfirmedView from '@/pages/cart/thanks/components/ConfirmedView.vue';
import DeclineView from '@/pages/cart/thanks/components/DeclineView.vue';
import cartMixin from '@/mixins/cartMixin';
import PageNotFound from '@/layouts/components/PageNotFound.vue';
export default {
  layout: 'MainLayout',
  name: 'CartPage',
  // middleware: "auth",
  mixins: [cartMixin],
  asyncData(context) {},
  data() {
    return {
      params: this.$route.query,
      userGuest: null,
    };
  },
  computed: {
    // userGuest() {
    //   let user = this.$store.state.user.guest.guestUser;
    //   return user;
    // },

    userConnected() {
      return this.$auth?.user || this.userGuest;
    },
  },
  methods: {},
  async mounted() {
    // if (!this.userConnected) {
    //   this.$router.push({
    //     path: this.localePath('/'),
    //   });
    // }

    const orderid = this.$route.query.orderid;
    const cartid = this.$route.query.cartid;

    if (String(cartid) === String(this.cartService.idCart)) {
      await this.cartService.finishOrder(orderid, this.$gtag, 'accepted');

      if (this.$store.state.user.guest.guestUser) {
        this.userGuest = { ...this.$store.state.user.guest.guestUser };

        this.$store.dispatch('user/guest/setGuestUser', {
          user: null,
          cookies: this.$cookies,
        });
        setTimeout(() => {
          this.cartService.clearCart();
        }, 500);
      }
    }
  },
  components: { Button, ConfirmedView, DeclineView, PageNotFound },
};
