
export default {
  props: {
    type: {
      type: 'image' | 'multiImage' | 'slider',
      default: 'image',
    },
    alt: {
      type: String,
    },
    title: {
      type: String,
    },
    images: {
      required: true,
      type: Array,
    },
    imagesLg: {
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    firstImage() {
      try {
        return this.images[0].Src;
      } catch (error) {}

      return require(`~/assets/img/default-img.jpg`);
    },
    hoverImage() {
      try {
        return this.images[1].Src;
      } catch (error) {}

      return null;
    },
    allImages() {
      try {
        return this.images;
      } catch (error) {}

      return [];
    },
    first3Images() {
      try {
        return this.images.slice(0, 3);
      } catch (error) {}

      return [];
    },
  },
  components: {},
  mounted() {},
  methods: {},
};
