
import ProductCardDefault from "./ProductCardDefault.vue";
export default {
  props: {
    typeView: {
      type: "image" | "multiImage" | "slider",
    },
    categoryLink: {
      type: String,
      default: "#",
    },
    product: {
      // default:() => ({})
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      relationShipProducts: [],
      relationShipProductActive: null,
      showRelativeShipProductActive: false,
    };
  },
  computed: {
    productActive() {
      return this.showRelativeShipProductActive
        ? this.relationShipProductActive
        : this.product;
    },
  },
  methods: {
    isAlreadyLoaded(product) {
      return this.relationShipProducts?.some(
        (item) => item.IdProduct === product.IdProduct
      );
    },
    addProductToRelationShip(event) {
      if (!this.isAlreadyLoaded(event.product)) {
        this.relationShipProducts.push(event.product);
      }
      this.relationShipProductActive = event.product;
      this.showRelativeShipProductActive = true;
    },
  },
  mounted() {},
  components: {
    ProductCardDefault,
  },
};
