
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    promotion() {
      let price = this.product.Price;
      return price.PromotionalPrice;
    },
    promotionPercentage() {
      const price = Number(this.productPrice);
      const pricePromotion = Number(this.productPricePromotion);

      return ((1 - pricePromotion / price) * 100).toFixed(0);
    },
    productPricePromotion() {
      return this.promotion.PriceTaxIncl;
    },
    productPrice() {
      let price = this.product.Price;

      // return this.$addTax(price.RegularPrice.PriceTaxExcl);
      return price.RegularPrice.PriceTaxIncl;
    },
    currencySign() {
      return this.$localisation.currencySign;
    },
    productColor() {
      try {
        return this.product.Variant[0].Combination.Attributes.find(
          (item) => item.GroupName === 'Color'
        ).AttributeName;
      } catch (error) {}
      return null;
    },
    categoryUrl() {
      try {
        const mainCategory = this.product?.Category?.Main?.SEO?.find(
          (catSeo) => {
            return (
              this.$i18n.locale.toLocaleLowerCase() ===
              catSeo.LanguageIsoCode.toLocaleLowerCase()
            );
          }
        );

        return mainCategory?.URL;
      } catch (error) {}

      return 'product';
    },
  },
  components: {},
};
