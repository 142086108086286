
import CarriersHoraire from "../pages/cart/components/CarriersHoraire.vue";
import SlideDownUp from "./transitions/SlideDownUp.vue";
import { ChevronDownIcon } from "vue-feather-icons";
import { Button } from "element-ui";

export default {
  props: {
    relayPoint: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
    },
  },
  data() {
    return {
      isShowHoraire: false,
    };
  },
  components: { CarriersHoraire, SlideDownUp, ChevronDownIcon, Button },
};
