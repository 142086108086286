
import { InfoIcon } from 'vue-feather-icons';
import LoyaltyPostsCard from './LoyaltyPostsCard.vue';
import LoyaltyPostsCardReward from './LoyaltyPostsCardReward.vue';
import { Alert } from 'element-ui';
export default {
  props: {
    total: {
      type: Number,
      default: 3,
    },
    listRewards: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Array,
      default: () => [],
    },
    tabs: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    stitle: {
      type: String,
    },
    canGetReward: {
      type: Boolean,
      default: false,
    },
    learMoreLink: {
      type: String,
    },
  },
  data() {
    return {
      active: this.tabs ? 'available' : 'All',
    };
  },
  computed: {
    customer() {
      return this.$store.state.shop.loyalty.customer;
    },
    getListRewards() {
      if (this.active === 'All') {
        return this.listRewards;
      } else {
        return this.listRewards?.filter(
          (item) => item?.State?.Label === this.active
        );
      }
    },
  },
  mounted() {},
  components: {
    LoyaltyPostsCard,
    LoyaltyPostsCardReward,
    Alert,
    InfoIcon,
  },
};
