
import ContentLayout from "@/pages/content/components/ContentLayout.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { PrivacyPolicy } from "~/graphql/queries/Cms";
export default {
  layout: "MainLayout",
  name: "CgvPage",
  auth: false,
  apollo: {
    privacyPolicy: {
      query: PrivacyPolicy,
      variables() {
        return {
          lang: this.$i18n.locale,
        };
      },
    },
  },
  computed: {
    breadcrumbLinks() {
      const links = [
        {
          link: "/",
          text: this.$t("Home"),
        },
        {
          text: this.$t("Privacy Policy"),
        },
      ];
      return links;
    },
  },
  components: { ContentLayout, Breadcrumb },
};
