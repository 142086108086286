
import {
  Button,
  Dialog,
  Select,
  Option,
  Label,
  Form,
  FormItem,
  Input,
  Checkbox,
} from 'element-ui';

import { CheckIcon, InfoIcon, MailIcon } from 'vue-feather-icons';
import { ShopHelper } from '@/helpers/ShopHelper';

export default {
  data() {
    return {
      step: 1,
      newsletter: {
        email: '',
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('Please input correct email address'),
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    showed: {
      get() {
        const newsletterState = this.$store.state.popups.newsletter;
        return newsletterState.pageCount >= 5 && !newsletterState.showed;
      },
      set(val) {
        this.$store.commit('popups/newsletter/setPageCount', {
          $cookies: this.$cookies,
          val: 0,
        });
        this.$store.commit('popups/newsletter/setIsShowed', {
          $cookies: this.$cookies,
          val: val,
        });
      },
    },
  },
  methods: {
    async onSubmit() {
      this.$refs['newsletterForm'].validate(async (valid) => {
        if (valid) {
          const shopHelper = new ShopHelper(this.$http, this);
          const response = await shopHelper.newsletterSubscription({
            email: this.newsletter.email,
            languageIsoCode: this.$i18n.locale,
            currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
          });
          if (response.status === 200) {
            this.step++;
          }
        } else {
          return false;
        }
      });
    },
    closeDialog() {
      this.showed = true;
    },
  },
  components: {
    Button,
    Dialog,
    Select,
    Option,
    Label,
    Form,
    FormItem,
    Input,
    CheckIcon,
    InfoIcon,
    MailIcon,
    Checkbox,
  },
};
