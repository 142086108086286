
import favoriteMixin from "@/mixins/favoriteMixin.js";
import { HeartIcon } from "vue-feather-icons";
export default {
  mixins: [favoriteMixin],
  computed: {
    productFavoritesSize() {
      return this.favorite?.TotalProduct;
    },
  },
  components: {
    HeartIcon,
  },
};
