
import Teleport from "vue2-teleport";
import { Button, Drawer, Table, TableColumn } from "element-ui";

export default {
  props: {
    button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const clothesData = [
      {   
        fr_es: "38",
        eu: "36",
        uk_au: "10",
        it: "42",
        us: "6",
        int: "M",
      },
      {   
        fr_es: "40",
        eu: "38",
        uk_au: "12",
        it: "44",
        us: "8",
        int: "M",
      },
      {   
        fr_es: "42",
        eu: "40",
        uk_au: "14",
        it: "46",
        us: "10",
        int: "L",
      },
      {   
        fr_es: "44",
        eu: "42",
        uk_au: "16",
        it: "48",
        us: "12",
        int: "L",
      },
      {   
        fr_es: "46",
        eu: "44",
        uk_au: "18",
        it: "50",
        us: "14",
        int: "XL",
      },
      {   
        fr_es: "48",
        eu: "46",
        uk_au: "20",
        it: "52",
        us: "16",
        int: "XL",
      },
      {   
        fr_es: "50",
        eu: "48",
        uk_au: "22",
        it: "54",
        us: "18",
        int: "2XL",
      },
      {   
        fr_es: "52",
        eu: "50",
        uk_au: "24",
        it: "56",
        us: "20",
        int: "2XL",
      },
      {   
        fr_es: "54",
        eu: "52",
        uk_au: "26",
        it: "58",
        us: "22",
        int: "3XL",
      },
      {   
        fr_es: "56",
        eu: "54",
        uk_au: "28",
        it: "60",
        us: "24",
        int: "3XL",
      },
      {   
        fr_es: "58",
        eu: "56",
        uk_au: "30",
        it: "62",
        us: "26",
        int: "4XL",
      },
      {   
        fr_es: "60",
        eu: "58",
        uk_au: "32",
        it: "64",
        us: "28",
        int: "4XL",
      },

      
    ];

    const measurements_1Data = [
      
      {
        fr: "38/40",
        chest: "100-104",
        size: "80-84",
        hips: "102-106",
      },
      {
        fr: "42/44",
        chest: "108-112",
        size: "88-92",
        hips: "110-114",
      },
      {
        fr: "46/48",
        chest: "116-122",
        size: "96-102",
        hips: "118-124",
      },
      {
        fr: "50/52",
        chest: "128-134",
        size: "108-114",
        hips: "130-136",
      },
      {
        fr: "54/56",
        chest: "140-146",
        size: "120-126",
        hips: "142-148",
      },
      {
        fr: "58/60",
        chest: "152-158",
        size: "132-138",
        hips: "154-160",
      },
    ];

    const measurements_2Data = [
      {
        fr: "40",
        chest: "104",
        size: "84",
        hips: "106",
      },
      {
        fr: "42",
        chest: "108",
        size: "88",
        hips: "110",
      },
      {
        fr: "44",
        chest: "112",
        size: "92",
        hips: "114",
      },
      {
        fr: "46",
        chest: "116",
        size: "96",
        hips: "118",
      },
      {
        fr: "48",
        chest: "122",
        size: "102",
        hips: "124",
      },
      {
        fr: "50",
        chest: "128",
        size: "108",
        hips: "130",
      },
      {
        fr: "52",
        chest: "134",
        size: "114",
        hips: "136",
      },
      {
        fr: "54",
        chest: "140",
        size: "120",
        hips: "142",
      },
      {
        fr: "56",
        chest: "146",
        size: "126",
        hips: "148",
      },
      {
        fr: "58",
        chest: "152",
        size: "132",
        hips: "154",
      },
      {
        fr: "60",
        chest: "158",
        size: "138",
        hips: "160",
      },
    ];

    return {
      drawer: false,
      drawerTitle: "Guide des tailles",
      clothesData,
      measurements_1Data,
      measurements_2Data,
    };
  },
  methode: {},
  computed: {
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },
    tel() {
      if (this.$i18n.locale === "fr") {
        return "01.85.14.62.85";
      }

      return "+33.1.85.14.62.85";
    },
  },
  components: { Drawer, Table, TableColumn, Teleport, Button },
};
