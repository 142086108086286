
import CardBlog from "@/pages/content/components/CardBlog.vue";
import { Button } from "element-ui";
import CardBlogFull from "./CardBlogFull.vue";
export default {
  props: {
    title: {
      type: String,
    },
    articles: {
      type: Array,
      required: true,
    },
    disableLoadMore: {
      type: Boolean,
      default: false,
    },
    hideLoadMore: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CardBlog,
    Button,
    CardBlogFull,
  },
};
