
export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
    mbBodyAbsolute: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
};
