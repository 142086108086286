
import { Button } from "element-ui";
import { InfoIcon } from "vue-feather-icons";

export default {
  data() {
    return {
      giropayInstance: null,
      hipay: null,
      errors: []
    };
  },
  computed: {
    defaultLocal() {
      return this.$i18n.locale;
    }
  },
  mounted() {
    try {
      this.initHipay();
      this.giropayPayement();
    } catch (error) {}
  },
  methods: {
    initHipay() {
      this.hipay = HiPay({
        username: process.env.HIPAY_USERNAME,
        password: process.env.HIPAY_PASSWORD,
        environment: process.env.HIPAY_ENVIRONMENT,
        lang: this.defaultLocal
      });
    },
    giropayPayement() {
      const option = {
        // selector: "hipay-giropay",
        // template: "auto",
        fields: {
          issuer_bank_id: {
            selector: "hipay-giropay"
          }
        }
      };
      if (this.hipay != null) {
        this.giropayInstance = this.hipay.create("giropay", option);
      }
    }
  },
  components: { Button, InfoIcon }
};
