
import { PasswordHelper } from "@/helpers/PasswordHelper.js";
import { Input, Button, Form, FormItem } from "element-ui";
import { EyeIcon, EyeOffIcon } from "vue-feather-icons";
export default {
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value !== this.password.newPass) {
        callback(new Error("Two inputs don't match!"));
      } else {
        callback();
      }
    };

    return {
      showPassNew: false,
      showPassConfirm: false,
      password: {
        newPass: "",
        confirmPass: "",
      },
      rules: {
        newPass: [
          {
            required: true,
            message: "Please input Activity name",
            trigger: "blur",
          },
          {
            min: 6,
            message: "6 character minimum",
            trigger: "blur",
          },
        ],
        confirmPass: [
          { validator: validatePass2, trigger: "blur" },
          {
            required: true,
            message: "Please input Activity name",
            trigger: "blur",
          },
          {
            min: 6,
            message: "6 character minimum",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    submitForm() {
      this.$refs["passwordForm"].validate((valid) => {
        if (valid) {
          this.updatPassword();
        } else {
          return false;
        }
      });
    },

    async updatPassword() {
      const { token, email } = this.$route.query;

      const passwordHelper = new PasswordHelper(this.$http, this.$store);
      try {
        const response = await passwordHelper.resetPassword(
          token,
          email,
          this.password.newPass,
          this.$i18n.locale
        );

        this.$router.push(this.localePath("/authenticate/login"));
        this.$notify({
          title: this.$t("Success"),
          message: this.$t("The password was changed successfully"),
          type: "success",
        });
      } catch (error) {
        this.$message.error(error.message);
      }
    },
  },
  components: {
    Input,
    Button,
    EyeIcon,
    EyeOffIcon,
    Form,
    FormItem,
  },
};
