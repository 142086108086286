
import {
  Collapse,
  CollapseItem,
  Input,
  Button,
  FormItem,
  Form,
} from "element-ui";

import { OrderHelper } from "/helpers/OrderHelper";

export default {
  data() {
    return {
      isFocus: false,
      promoCodeForm: {
        code: "",
      },
      error: {
        status: false,
        message: "",
      },
      rules: {
        code: [
          {
            required: true,
            message: this.$t("Required Field"),
            trigger: "change",
          },
        ],
      },
    };
  },

  mounted() {
    const cookies = this.$cookies;

    let promo = cookies.get("PROMO_CODE");
    if (promo) {
      this.promoCodeForm.code = promo;
      this.getPromoCodeData();
    }
  },
  methods: {
    async onSubmit() {
      this.$refs["promoCodeForm"].validate((valid) => {
        if (valid) {
          this.getPromoCodeData();
        } else {
          return false;
        }
      });
    },

    async getPromoCodeData() {
      const orderHelper = new OrderHelper(
        this.$http,
        this.$store,
        this.$i18n.locale
      );

      try {
        const data = await orderHelper.applyPromoCode({
          code: this.promoCodeForm.code,
          idCart: this.$store.state.shop.cart.idCart,
        });

        if (data.message) {
          this.error = {
            status: true,
            message: data.message,
          };
        } else {
          const promoCode = data.PromoCodeDetail;
          promoCode.Code = this.promoCodeForm.code;
          this.$store.commit("shop/cart/setPromoCode", {
            $auth: this.$auth,
            data: promoCode,
          });

          this.error = {
            status: false,
            message: "",
          };
        }
      } catch (error) {}
    },
  },
  components: {
    Collapse,
    CollapseItem,
    Input,
    Button,
    FormItem,
    Form,
  },
};
