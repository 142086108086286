
import IdealForm from './IdealForm.vue';
import { Button, Checkbox } from 'element-ui';
import hipayMixin from '../hipayMixin';

export default {
  mixins: [hipayMixin],
  props: {
    paymentMethod: {
      type: Object,
    },
  },
  data() {
    return {
      generalConditionsSale: false,
    };
  },
  computed: {},
  methods: {
    submit() {
      this.startPayment({
        paymentData: { payment_product: 'iDEAL' },
        paymentMethod: this.paymentMethod,
      });
    },
  },
  components: {
    Button,
    Checkbox,
    IdealForm,
  },
};
