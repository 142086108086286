
import CardProduct2 from './CardProduct2.vue';

export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    items: {
      required: true,
      type: Array,
    },
    textCenter: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      responsiveSlider: {
        0: {
          slidesPerView: 2.2,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 3.2,
          spaceBetween: 16,
        },
      },
    };
  },
  methods: {
    randomKey() {
      return (
        new Date().getTime() + Math.floor(Math.random() * 10000).toString()
      );
    },
  },
  mounted() {},
  components: { CardProduct2 },
};
