
import { LoyaltyService } from '@/helpers/LoyaltyService';
import { ChevronLeftIcon } from 'vue-feather-icons';
import { Button, Dialog, Alert } from 'element-ui';
export default {
  data() {
    return {
      loading: false,
      showed: false,
      error: null,
    };
  },
  computed: {
    googlewallet() {
      return this.$store.state.account.googlewallet;
    },
    walletData() {
      return this.googlewallet.data;
    },
  },
  methods: {
    loadGoogleWalletLink() {
      this.$store.dispatch('account/googlewallet/fetchData');
    },
    closeDialog() {
      this.showed = false;
    },
  },
  mounted() {
    this.loadGoogleWalletLink();
  },
  components: {
    Button,
    Dialog,
    ChevronLeftIcon,
    Alert,
  },
};
