
import { Button, Dialog } from "element-ui";
import GetCardCadeau from "./GetCardCadeau";
import { CheckIcon, InfoIcon } from "vue-feather-icons";
export default {
  props: {
    unavailable: {
      type: Boolean,
      default: false,
    },
    reward: {
      type: Object,
    },
    canGetReward: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showed: false,
    };
  },
  computed: {
    rewardState() {
      if (this.reward.State) {
        return this.reward.State.Label;
      } else {
        return "default";
      }
    },
    customer() {
      return this.$store.state.shop.loyalty.customer;
    },
    pointsNeed() {
      try {
        return this.reward?.Points - this.customer?.TotalPoints;
      } catch (error) {}

      return 0;
    },
  },
  methods: {
    openGetCardCadeauDialogue() {
      this.$refs["GetCardCadeauRef"].openDialog();
    },
    showCadeauDialogue() {
      this.$refs["GetCardCadeauRef"].showReward();
    },
  },
  components: {
    Button,
    Dialog,
    GetCardCadeau,
    CheckIcon,
    InfoIcon,
  },
};
