
import { Steps, Step, Button, Collapse, CollapseItem } from 'element-ui';

import { ShopSettings } from '@/services/ShopSettings';

export default {
  layout: 'MainLayout',
  name: 'CartPage',
  auth: false,
  props: {
    activeIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      active: 1,
    };
  },
  methods: {
    loadShopSettings() {
      const shopSettings = new ShopSettings(this.$http, this.$store);
      shopSettings.getSettingWithKey('DeliveryFreeFrom');
    },
  },
  created() {
    this.loadShopSettings();
  },
  components: {
    Steps,
    Step,
    Button,
    Collapse,
    CollapseItem,
  },
};
