
import LoyaltyPostsCard from "./LoyaltyPostsCard.vue";
import LoyaltyPostsCardReward from "./LoyaltyPostsCardReward.vue";
export default {
  props: {
    total: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    customerEvents() {
      return this.$store.state.shop.loyalty.customerEvents;
    },
  },
  mounted() {
    this.$store.dispatch("shop/loyalty/fetchEvents");
  },
  components: {
    LoyaltyPostsCard,
    LoyaltyPostsCardReward,
  },
};
