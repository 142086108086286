
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
    },
    discription: {
      type: String,
    },
  },
};
