
import { Button } from "element-ui";
import Teleport from "vue2-teleport";
import { XIcon } from "vue-feather-icons";

const COOKIES_ACCEPTED = "COOKIES_ACCEPTED";

export default {
  data() {
    return {
      open: false,
      animated: false,
    };
  },
  mounted() {
    if (!this.$cookies.get(COOKIES_ACCEPTED)) {
      this.open = true;
    }
    setTimeout(() => {
      this.animated = true;
    }, 500);
  },
  methods: {
    accepted() {
      this.$cookies.set(COOKIES_ACCEPTED, true, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365,
      });
      this.animated = false;
      this.$gtag.optIn();
    },
  },
  components: { Teleport, Button, XIcon },
};
