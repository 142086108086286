
import { InfoIcon } from "vue-feather-icons";
export default {
  props: {
    errors: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InfoIcon,
  },
};
