
import StepCart from '@/pages/cart/components/StepCart.vue';
import CartMain from './components/CartMain.vue';
import LoginForm from '@/pages/authenticate/components/LoginForm.vue';
import RegisterFormGuestTunnel from '@/pages/authenticate/components/RegisterFormGuestTunnel.vue';

export default {
  layout: 'tunnel',
  name: 'CartPage',
  auth: false,

  data() {
    return {};
  },
  computed: {
    guestUser() {
      return this.$store.state.user.guest.guestUser;
    },

    isLogin() {
      return this.$auth.loggedIn || this.guestUser;
    },
  },
  async mounted() {
    if (this.$auth.loggedIn) {
      this.$router.push({
        path: this.localePath('/cart'),
      });
    }
  },
  methods: {
    scrollTo(ref) {
      if (process.client) {
        window.scrollTo({
          top: this.$refs[ref].offsetTop - 60,
          behavior: 'smooth',
        });
      }
    },
  },
  activated() {},
  components: {
    StepCart,
    CartMain,
    LoginForm,
    RegisterFormGuestTunnel,
  },
};
