
export default {
  props: {
    colorUrls: {
      type: String,
      default: null,
    },
    products: {
      type: Array,
    },
    defaultProduct: {
      type: Object,
    },
    productSelectedId: {
      type: String,
      required: false,
    },
    goToProductLink: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  mounted() {
  },
  methods: {
    getProductTitle(product) {
      if (product != null) {
        // return product.ProductDescription[0][0];
        if (this.$i18n.locale === "en") {
          if (Array.isArray(product.ProductDescription)) {
            return product.ProductDescription[0][1].Title;
          }
          return product.ProductDescription[1].Title;
        }

        if (Array.isArray(product.ProductDescription)) {
          return product.ProductDescription[0][0].Title;
        }
        return product.ProductDescription[0].Title;
      }
      return "";
    },
    getProductImage(product) {
      try {
        if (product.ImagesUrls) {
          if (product.ImagesUrls.product_xsmall) {
            const imgurl = product.ImagesUrls.product_xsmall[0].Url;
            return imgurl;
          }
        }

        if (product.ProductImageUrl) {
          const imgurl = product.ProductImageUrl;
          return imgurl;
        }

        return require(`~/assets/img/default-img.jpg`);
      } catch (error) {
        return require(`~/assets/img/default-img.jpg`);
      }
    },
    colorClick(product) {
      this.$emit("onColorClick", product);
    },

    urlCategory(product) {
      try {
        const seo = product.SEO;
        if (seo) {
          const mainCategory = seo.MainCategory.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode;
          });
          if (!mainCategory) {
            return "product";
          }
          if (mainCategory.CategoryDescriptionFriendlyURL) {
            return mainCategory.CategoryDescriptionFriendlyURL;
          }
        }
      } catch (error) {}
      return "product";
    },

    urlProduct(product) {
      try {
        const seo = product.SEO;
        if (seo) {
          const productSeo = seo.Product.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode;
          });

          if (productSeo.ProductDescriptionFriendlyURL) {
            return productSeo.ProductDescriptionFriendlyURL;
          }
        }
      } catch (error) {}
      return this.product.IdProduct;
    },

    link(product) {
      try {
        return this.localePath(
          `/${this.urlCategory(product)}/${this.urlProduct(product)}`
        );
      } catch (error) {}

      return this.localePath(
        `/product/${this.getProductTitle(product)}-${product.IdProduct}`
      );
    },
  },
};
