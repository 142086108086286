
import { InputNumber, Select, Option } from "element-ui";
import { XIcon, InfoIcon } from "vue-feather-icons";

let timerUpdateQuantity;

export default {
  props: {
    product: {
      required: true,
      type: Object,
    },
    size: {
      required: true,
      type: String,
    },
    color: {
      required: true,
      type: String,
    },
    imgHeight: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    disabledEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quantitSelected: 1,
      sizeSelected: this.size,
    };
  },

  mounted() {
  },

  computed: {
    productImageThumbnailUrl() {
      try {
        return this.product.ProductImagesUrl.product_large[0].Url;
      } catch (error) {}
      return require(`~/assets/img/default-img.jpg`);
    },

    productTitle() {
      return this.product.ProductDescription[0].Title;
    },
    availableSizes() {
      return this.product.AvailableSizes;
    },
    maxQuantiy() {
      if (this.itemSelected) {
        if (Number(this.itemSelected.Quantity) < this.quantitSelected) {
          this.quantitSelected = Number(this.itemSelected.Quantity);
        }
        if (this.itemSelected) {
          return Number(this.itemSelected.Quantity);
        }
      }
      return 0;
    },
    hasPromotion() {
      let price = this.product.Prices[0];
      return price.InPromotion === "true";
    },
    promotionPercentage() {
      const price = Number(this.productPrice);
      const pricePromotion = Number(this.productPricePromotion);

      return ((1 - pricePromotion / price) * 100).toFixed(0);
    },
    productPricePromotion() {
      let price = this.product.Prices;
      return this.$productPrice(price[0].PromotionPrices[0].TaxExcl);
    },
    productPrice() {
      let price = this.product.Prices;

      return this.$productPrice(price[0].RegularPrices[0].TaxExcl);
    },
    currencySign() {
      return this.$localisation.currencySign;
    },
    urlCategory() {
      try {
        const seo = this.product.SEO;
        if (seo) {
          const mainCategory = seo.MainCategory.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode;
          });
          if (!mainCategory) {
            return "product";
          }
          if (mainCategory.CategoryDescriptionFriendlyURL) {
            return mainCategory.CategoryDescriptionFriendlyURL;
          }
        }
      } catch (error) {
      }
      return "product";
    },

    urlProduct() {
      try {
        const seo = this.product.SEO;
        if (seo) {
          const productSeo = seo.Product.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode;
          });
          if (!productSeo) {
            return this.product.IdProduct;
          }
          if (productSeo.ProductDescriptionFriendlyURL) {
            return productSeo.ProductDescriptionFriendlyURL;
          }
        }
      } catch (error) {
      }
      return this.product.IdProduct;
    },

    link() {
      try {
        if (this.product.SEO) {
          return this.localePath(`/${this.urlCategory}/${this.urlProduct}`);
        }
      } catch (error) {}

      return "#";
    },
  },
  methods: {},

  components: { InputNumber, XIcon, InfoIcon, Select, Option },
};
