
export default {
  props: {
    lookbook: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    getLink(item) {
      try {
        const link = item.link;
        if (link.charAt(link.length - 1) === '/') {
          link = link.slice(0, -1);
        }

        // const parts = link.split("/");
        // const permalink = parts[parts.length - 1];
        return link;
      } catch (error) {}

      return '/';
    },
  },
};
