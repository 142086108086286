
import { Button, Checkbox } from 'element-ui';
import StripePayment from '@/services/payments/StripePayment';
import cartMixin from '@/mixins/cartMixin';
import addressMixin from '@/mixins/addressMixin';

export default {
  mixins: [cartMixin, addressMixin],
  props: {
    paymentMethod: {
      type: Object,
    },
    paymentMethodTypes: {
      type: String,
      default: 'card', //'card,klarna,...'
    },
    name: {
      type: String,
      default: 'card', //'card,klarna,...'
    },
  },
  data() {
    return {
      token: null,
      elements: null,
      clientSecret: null,
      stripe: null,
      generalConditionsSale: false,
      loading: true,
    };
  },
  computed: {
    stripePayment() {
      return new StripePayment({
        cart: this.cart,
        auth: this.$auth,
        http: this.$http,
        addressService: this.addressService,
      });
    },
  },
  methods: {
    async initialize() {
      try {
        const data = await this.stripePayment.intent({
          paymentMethodTypes: this.paymentMethodTypes,
        });

        this.clientSecret = data.ClientSecret;

        if (this.clientSecret) {
          this.elements = this.stripe.elements({
            clientSecret: this.clientSecret,
            locale: this.$i18n.locale ?? 'fr',
          });

          const paymentElementOptions = {
            layout: 'tabs',
          };

          const paymentElement = this.elements.create(
            'payment',
            paymentElementOptions
          );

          paymentElement.mount(`#${this.name}-payment-element`);
        }
      } catch (error) {
        console.error('stripe : ', error);
      }

      this.loading = false;
    },
    async handleSubmit() {
      if (this.clientSecret) {
        if (await this.postData()) {
          this.toggleLoader(true);
          const idorder = this.cart.IdCart;

          const { error, paymentIntent } = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: {
              return_url: `${process.env.URL}cart/thanks/accepted?orderid=${idorder}&cartid=${this.cartService.idCart}`,
            },
            redirect: 'if_required',
          });

          if (error) {
            this.showMessage(error.message);
          } else if (paymentIntent && paymentIntent.status === 'succeeded') {
            this.$router.push({
              path: this.localePath(
                `/cart/thanks/accepted?orderid=${idorder}&cartid=${this.cartService.idCart}`
              ),
            });
          }

          this.toggleLoader(false);
        }
      }
    },
    async postData() {
      try {
        const response = await this.stripePayment.postData({
          clientSecret: this.clientSecret,
          paymentMethod: this.paymentMethod,
        });
        return true;
      } catch (error) {
        return false;
      }
    },
    showMessage(messageText) {
      if (process.client) {
        const messageContainer = document.querySelector(
          `#${this.name}-payment-message`
        );

        messageContainer.classList.remove('hidden');
        messageContainer.textContent = messageText;

        setTimeout(function () {
          messageContainer.classList.add('hidden');
          messageContainer.textContent = '';
        }, 4000);
      }
    },
    toggleLoader(isLoading) {
      this.$store.commit('shared/wind/setIsLoading', isLoading);
    },
  },
  mounted() {
    this.$stripe.then((stripe) => {
      this.stripe = stripe;
      this.initialize();
    });
  },
  components: {
    Button,
    Checkbox,
  },
};
