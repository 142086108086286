
import { XIcon, SearchIcon } from "vue-feather-icons";
import { Button, Input, FormItem, Form } from "element-ui";
import { SearchHelper } from "@/helpers/SearchHelper.js";
import ProductCardDefaultMultiple from "./ProductCard/ProductCardDefaultMultiple.vue";

let page = 1;
let timerSearch;
export default {
  components: {
    XIcon,
    SearchIcon,
    Button,
    Input,
    Input,
    FormItem,
    Form,
    ProductCardDefaultMultiple,
  },

  data() {
    return {
      SearchForm: { val: "" },
      isLoding: false,
      productsList: [],
      page: 1,
      count: 30,
      rules: {
        val: [
          {
            required: true,
            message: this.$t("Required Field"),
            trigger: "blur",
          },
          {
            min: 3,
            message: this.$t("At least {0} characters", [3]),
            trigger: "blur",
          },
        ],
      },
      searshing: false,
      noMoreProduct: false,
    };
  },

  async mounted() {
    // const permalink = 15;
    // const productHelpers = new ProductHelpers(this.$http);
    // const data = await productHelpers.getListByCategory(permalink, page, 30);
    // this.productsList = data[0].Products;
  },
  computed: {
    products() {
      return this.productsList;
    },
    showbody() {
      return this.SearchForm.val.length > 2;
    },
  },
  watch: {
    $route(to, from) {
      this.refreshSearch();
      this.close();
    },
  },

  methods: {
    close() {
      if (process.client) {
        const elBody = document.body;
        elBody.classList.remove("overflow-hidden");
      }
      this.$emit("onClose");
    },
    getCategoryLink(product) {
      if (product.Category) {
        const seoCat = product.Category.Main.SEO;
        try {
          let seo = seoCat[0];
          if (this.$i18n.locale === "en") {
            seo = seoCat[1];
          }
          if (this.$i18n.locale === "de") {
            seo = seoCat[2];
          }
          return seo.URL;
        } catch (error) {
        }
      }

      return "product";
    },
    clearSearch() {
      this.SearchForm.val = "";
    },
    refreshSearch() {
      this.clearSearch();
    },
    loadMore() {
      this.page++;
      this.isLoding = true;
      this.loadProduct();
    },
    async startSearch() {
      this.$refs["formSearch"].validate((valid) => {
        if (valid) {
          this.productsList = [];
          this.page = 1;
          this.isLoding = true;
          this.searshing = true;
          this.noMoreProduct = false;

          this.$store.commit("shared/wind/setIsLoading", true);
          this.loadProduct();
        } else {
          return false;
        }
      });
    },
    loadProduct() {
      // this.page++;
      // const start = (this.page - 1) * this.count;
      // const end = this.page * this.count - 1;
      const searchHelper = new SearchHelper(this.$http);
      // try {
      //   const data = await searchHelper.getData(
      //     this.SearchForm.val,
      //     start,
      //     end
      //   );
      //   // this.productsList = data.ProductsList;
      //   this.productsList = [...this.productsList, ...data.ProductsList];
      //   this.$store.commit("shared/wind/setIsLoading", false);
      // } catch (error) {}

      searchHelper
        .getData({
          term: this.SearchForm.val,
          languageIsoCode: this.$i18n.locale,
          currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
          page: this.page,
        })
        .then((data) => {
          this.noMoreProduct = !data.HasNextPage;

          this.productsList = [...this.productsList, ...data.Products];
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoding = false;
          this.searshing = false;
          this.$store.commit("shared/wind/setIsLoading", false);
        });
    },
    searchHandler() {
      clearTimeout(timerSearch);
      timerSearch = setTimeout(() => {
        this.startSearch();
      }, 400);
    },
  },
};
