
import { Input, Button, Form, FormItem } from 'element-ui';
import FacebookButton from '@/components/FacebookButton.vue';
import GoogleView from '@/components/GoogleView.vue';
import SocialLoginBtns from '@/pages/authenticate/components/SocialLoginBtns.vue';
export default {
  props: {
    tunnel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showInput: false,
      login: {
        user: '',
        pass: '',
      },
      rules: {
        user: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('Please input correct email address'),
            trigger: 'blur',
          },
        ],
      },
      isLogin: false,
    };
  },
  components: {
    Input,
    Button,
    Form,
    FormItem,
    FacebookButton,
    GoogleView,
    SocialLoginBtns,
  },
  methods: {
    openInput() {
      this.showInput = true;
      // this.$refs["email"].$el.focus()
      setTimeout(() => {
        this.$refs.email.focus();
      }, 200);
    },
    registerClick() {
      this.$refs['registerForm0'].validate((valid) => {
        if (valid) {
          this.$store.dispatch('user/guest/setEmailGuest', this.login.user);

          this.$router.push({
            path: this.localePath('/cart/delivery'),
          });
        } else {
          return false;
        }
      });
    },
    async glogin() {
      const googleUser = await this.$gAuth.signIn();
    },
  },
};
