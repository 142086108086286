
import { Button } from "element-ui";

export default {
  props: {
    address: {
      type: Object,
      required: true,
    },
    imgRight: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Button,
  },
};
