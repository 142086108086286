
import { Button, Dialog } from "element-ui";
import { InfoIcon } from "vue-feather-icons";
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sizeSelected: null,
      colorSelected: null,
      valide: true,
      idProductAttributeSelected: null,
      mobileShowSizes: false,
    };
  },
  computed: {
    tel() {
      if (this.$i18n.locale === "fr") {
        return "01.85.14.62.85";
      }

      return "+33.1.85.14.62.85";
    },
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },
    hasPromotion() {
      let price = this.product.Price;
      return price.PromotionalPrice;
    },
    getProductImgs() {
      try {
        return this.product.Media.Images.product_medium;
      } catch (error) {}
      return [];
    },
    productImage() {
      if (this.getProductImgs.length) {
        return this.getProductImgs[0].Src;
      }
      return "";
    },

    productTitle() {
      if (this.product != null) {
        try {
          return this.product.Description.Title;
        } catch (error) {}
      }
      return "";
    },
    productPricePromotion() {
      let price = this.product.Price;
      return this.$productPrice(price.PromotionalPrice.PriceTaxExcl);
    },
    currencySign() {
      return this.$localisation.currencySign;
    },

    promotionPercentage() {
      const price = Number(this.productPrice);
      const pricePromotion = Number(this.productPricePromotion);

      return ((1 - pricePromotion / price) * 100).toFixed(0);
    },
    productPrice() {
      let price = this.product.Price;

      return this.$productPrice(price.RegularPrice.PriceTaxExcl);
    },
    productColor() {
      try {
        return this.product.Variant[0].Combination.Attributes.find(
          (item) => item.GroupName === "Color"
        ).AttributeName;
      } catch (error) {}
      return "";
    },
  },
  methods: {
    selectSize(variant) {
      if (variant.Quantity > 0) {
        this.colorSelected = this.getColor(
          this.getCombination(variant)
        ).AttributeName;
        this.sizeSelected = this.getSize(
          this.getCombination(variant)
        ).AttributeName;
        this.idProductAttributeSelected =
          this.getCombination(variant).IdProductAttribute;
        this.valide = true;
      }

      if (this.$SETTINGS.isMobile) {
        this.emitNext();
      }
    },

    getCombination(variant) {
      try {
        const size = variant.Combination;
        return size;
      } catch (error) {}
      return null;
    },
    getSize(combination) {
      try {
        const size = combination.Attributes.find(
          (attribute) => attribute.GroupName === "Size"
        );
        return size;
      } catch (error) {}
      return {};
    },
    getColor(combination) {
      try {
        const size = combination.Attributes.find(
          (attribute) => attribute.GroupName === "Color"
        );
        return size;
      } catch (error) {}
      return {};
    },
    emitNext() {
      this.$emit("next", {
        size: this.sizeSelected,
        color: this.colorSelected,
        idProductAttribute: this.idProductAttributeSelected,
      });
    },
    next() {
      if (!this.$SETTINGS.isMobile) {
        if (this.idProductAttributeSelected === null) {
          this.valide = false;
          return;
        }
        this.emitNext();
      } else {
        this.mobileShowSizes = true;
      }
    },
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      if (this.mobileShowSizes) {
        this.mobileShowSizes = false;
      }
    },
  },
  mounted() {},
  created() {
    if (process.client) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  unmounted() {
    if (process.client) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  },
  components: {
    Button,
    Dialog,
    InfoIcon,
  },
};
