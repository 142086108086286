
import Teleport from 'vue2-teleport';
import CartItems from '@/pages/cart/components/CartItems.vue';
import MiniCartSlider from './MiniCartSlider.vue';
import { Button, Drawer, Form, FormItem, Input, Checkbox } from 'element-ui';
import { MailIcon, CheckIcon } from 'vue-feather-icons';
import { ProductHelpers } from '@/helpers/ProductHelpers.js';
import { ShopHelper } from '@/helpers/ShopHelper.js';
import cartMixin from '@/mixins/cartMixin.js';

const ABANDONED_VISIT = 'ABANDONED_VISIT';

export default {
  mixins: [cartMixin],
  data() {
    return {
      direction: 'rtl',
      step: 1,
      productSimilar: [],
      formAbandoned: {
        email: '',
        privacy: [],
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('Please input correct email address'),
            trigger: 'change',
          },
        ],
        privacy: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
      },
      // products,
    };
  },
  computed: {
    showAbandonedVisit: {
      get() {
        return this.$store.state.shared.wind.abandonedVisit;
        // return true;
      },
      set(value) {
        this.$store.commit('shared/wind/toggleAbandonedVisit', value);
      },
    },
    productsVisited() {
      return this.$store.state.products_visited.products;
    },
  },
  methods: {
    async onSubmit() {
      this.$refs['formAbandoned'].validate((valid) => {
        if (valid) {
          this.send();
        } else {
          return false;
        }
      });
    },

    async send() {
      const shopHelper = new ShopHelper(this.$http, this);
      const email = this.formAbandoned.email;
      const idCart = this.cart.IdCart;
      try {
        await shopHelper.cancelingVisit({ email, idCart });
        this.showAbandonedVisit = false;
      } catch (error) {}

      this.step++;
    },

    abandonedVisit() {
      if (process.client) {
        const $this = this;
        const auth = this.$auth;
        document.body.addEventListener('mouseout', function (e) {
          if (
            !auth.loggedIn &&
            !$this.$store.state.shared.wind.abandonedVisit &&
            $this.cart.IdCart != null
          ) {
            if (!e.relatedTarget && !e.toElement) {
              if (!$this.$cookies.get(ABANDONED_VISIT)) {
                $this.loadProductSimilar();
                $this.$store.commit('shared/wind/toggleAbandonedVisit', true);
              }
            }
          }
        });
      }
    },

    closeDialog() {
      this.$cookies.set(ABANDONED_VISIT, true, {
        path: '/',
        maxAge: 60 * 60 * 24 * 7,
      });
    },
    loadProductSimilar() {
      const productHelpers = new ProductHelpers(this.$http, this);

      const lastItem = this.cart?.Products[0];

      if (lastItem) {
        const permalink = lastItem.IdProduct;
        if (permalink) {
          productHelpers
            .getProductstSimilar(permalink)
            .then((response) => {
              this.productSimilar = response;
            })
            .catch((error) => {});
        }
      }
    },
  },
  created() {},
  mounted() {
    this.abandonedVisit();
  },
  components: {
    Button,
    Drawer,
    Teleport,
    Form,
    FormItem,
    Input,
    Checkbox,
    CartItems,
    MiniCartSlider,
    MailIcon,
    CheckIcon,
  },
};
