
export default {
  props: {
    srcImg: {
      type: String,
    },
    alt: {
      type: String,
    },
    title: {
      type: String,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  computed: {
    webpSrc() {
      // return this.$productImage.image(this.srcImg, this.size, "webp");
      return this.srcImg;
    },
    defaultSrc() {
      // return this.$productImage.image(this.srcImg, this.size);
      return this.srcImg;
    },
    width() {
      if (this.size === "md") {
        return 372;
      }
    },
    height() {
      if (this.size === "md") {
        return 558;
      }
    },
  },
};
