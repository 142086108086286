
import { TopBanner } from "~/graphql/queries/Shop";
import sharedMixin from "@/mixins/sharedMixin.js";

export default {
  mixins: [sharedMixin],
  data() {
    return {
      topBanner: null,
      baseLink: ""
    };
  },
  computed: {
    body() {
      try {
        return this.$store.state.shared.topBanner.body;
      } catch (error) {
        return "";
      }
    },
    link() {
      try {
        return this.$store.state.shared.topBanner.link;
      } catch (error) {
        return "";
      }
    },
    safeLink() {
      const link = this.link;
      let url = this.localePath(link);
      if (link.includes("http")) {
        const listWords = link.split("/");
        url = "";
        for (let i = 3; i < listWords.length; i++) {
          const element = listWords[i];
          url += "/" + element;
        }
        return url;
      }
      if (link[0] !== "/") {
        url = this.localePath("/" + link);
      }
      return url;
    }
  },
  async mounted() {
    try {
      const res = await this.$apolloProvider.defaultClient.query({
        query: TopBanner,
        variables: {
          lang: this.$i18n.locale,
        },
      });
      const topBanner = res.data.topBanner;
      const baseLink = topBanner.data.attributes.link;
      this.$store.commit("shared/topBanner/init", {
        body: topBanner.data.attributes.body,
        link: topBanner.data.attributes.link,
        active: topBanner.data.attributes.active,
      });
      this.baseLink = baseLink;
    } catch (error) {
      console.error("Error fetching top banner:", error);
    }
  },
};
