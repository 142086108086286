
import AccountInfo from '@/pages/account/components/AccountInfo.vue';
import AccountBody from '@/pages/account/components/AccountBody.vue';
export default {
  layout: 'account',
  name: 'accountPageInformation',
  // middleware: "authenticated",
  middleware: 'auth',
  nuxtI18n: {
    paths: {
      en: '/account/informations',
      fr: '/account/informations',
      de: '/konto/information',
    },
  },

  data() {
    return {};
  },
  components: {
    AccountInfo,
    AccountBody,
  },
  mounted() {},
};
