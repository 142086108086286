import { render, staticRenderFns } from "./ContinueMyShopping.vue?vue&type=template&id=546a7fd3&scoped=true"
import script from "./ContinueMyShopping.vue?vue&type=script&lang=js"
export * from "./ContinueMyShopping.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546a7fd3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContinueMyShoppingLink: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/ContinueMyShoppingLink.vue').default})
