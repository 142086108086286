
import { Button, Select, Option, Checkbox, Alert } from 'element-ui';
import CardAddress from '@/pages/account/components/CardAddress.vue';
import { PlusIcon } from 'vue-feather-icons';
import addressMixin from '@/mixins/addressMixin.js';
import carriersMixin from '@/mixins/carriersMixin.js';

// update address api on address mixin computed

export default {
  mixins: [addressMixin, carriersMixin],
  data() {
    return {
      hasOtherInvoiceAddress: false,
    };
  },
  computed: {
    valide() {
      if (this.getAddressDelivery?.MobilePhone === '') {
        return false;
      }
      if (!this.hasOtherInvoiceAddress) {
        return this.getAddressDelivery !== null;
      } else {
        return (
          this.getAddressDelivery !== null && this.getAddressInvoice !== null
        );
      }
    },
  },
  mounted() {
    const event = {
      valide: this.valide,
      addressDelivery: this.getAddressDelivery,
      addressInvoice: this.getAddressInvoice,
    };
    this.$emit('onChange', event);
  },
  watch: {
    valide() {
      const event = {
        valide: this.valide,
        addressDelivery: this.getAddressDelivery,
        addressInvoice: this.getAddressInvoice,
      };
      this.$emit('onChange', event);
    },
    addresses(val) {
      if (val.length) {
        if (this.idAddressDelivery !== this.idAddressInvoice) {
          this.hasOtherInvoiceAddress = true;
        }
      }
    },
  },
  methods: {
    showAddressFormEdit(event, idAddress) {
      event.stopPropagation();
      this.idAddressEdit = idAddress;
    },
    async checkBoxAddressInvoice(checked) {
      if (!checked) {
        //   if (this.idAddressDelivery) {
        //     try {
        //       this.idAddressInvoice = this.idAddressDelivery;
        //     } catch (error) {
        //     }
        //   }
        await this.addressService.updateAddressType({
          idAddress: this.idAddressDelivery,
          isDelivery: true,
          isInvoice: true,
        });
        await this.addressService.fetchAddresses();
      }
    },
    async updateAddressInvoice(event) {
      await this.addressService.updateAddressType({
        idAddress: event,
        isDelivery: this.idAddressDelivery === event,
        isInvoice: true,
      });
      await this.addressService.fetchAddresses();
    },
    async updateAddressDelivery(event) {
      await this.addressService.updateAddressType({
        idAddress: event,
        isDelivery: true,
        isInvoice: event === this.idAddressInvoice,
      });
      await this.addressService.fetchAddresses();

      this.carriersService.fetchCarrierTypes({
        idAddress: event,
        onSuccess: (data) => {
          if (this.carrierTypes) {
            this.carriersService.fetchCarriers({
              idAddress: event,
              carrierType: this.carrierTypes[0],
              responseLevel: 'details',
            });
            this.$store.commit('carriers/setActiveTab', this.carrierTypes[0]);
          }
        },
      });
    },
  },
  components: {
    CardAddress,
    Button,
    Select,
    Option,
    Checkbox,
    PlusIcon,
    Alert,
  },
};
