
import { Button } from "element-ui";
export default {
  props: {
    title: {
      type: String,
      default: "Page not found",
    },
    message: {
      type: String,
      default: "This page does not exist !",
    },
    redirectLink: {
      type: String,
      default: "/",
    },
    redirectText: {
      type: String,
      default: "Home",
    },
  },
  components: {
    Button,
  },
};
