
import { MailIcon, CheckIcon } from "vue-feather-icons";
export default {
  computed: {
    email() {
      return this.$store.state.password.email;
    },
  },
  components: {
    MailIcon,
    CheckIcon,
  },
};
