
import { Button } from "element-ui";
export default {
  props: {
    to: {
      type: String,
    },
  },
  components: {
    Button,
  },
};
