
export default {
  computed: {
    tel() {
      if (this.$i18n.locale === 'fr') {
        return '01.85.14.62.85';
      }

      return '+33.1.85.14.62.85';
    },
    currencySign() {
      return this.$localisation.currencySign;
    }
  },
};
