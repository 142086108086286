
import { LocalisationHelper } from '@/helpers/LocalisationHelper';
import CarouselImages from '@/components/pages/product/CarouselImages.vue';
import ProductInformations from '@/components/pages/product/ProductInformations.vue';
import ProductLoading from '@/components/pages/product/ProductLoading.vue';
import ProductRelationship from '@/components/pages/product/ProductRelationship.vue';
import ProductSizes from '@/components/pages/product/ProductSizes.vue';
import { Button, Collapse, CollapseItem } from 'element-ui';
import ProductNotAvailable from '@/components/products/ProductNotAvailable.vue';
import { MapPinIcon } from 'vue-feather-icons';
import cartMixin from '@/mixins/cartMixin.js';
import PageNotFound from '@/layouts/components/PageNotFound.vue';

// const fetchProduct = async (store, { permalink }) => {
//   return await store.dispatch("product/fetchProduct", {
//     permalink,
//   });
// };
// const pageNotFound = (context) => {
//   context.error({
//     statusCode: 404,
//     message: context.app.i18n.t("the product you requested no longer exists"),
//   });
// };

export default {
  layout: 'MainLayout',
  name: 'ProductPage',
  auth: false,
  mixins: [cartMixin],

  data() {
    return {
      taillesIsOpen: false,
      isSelectedSize: true,
      colorSelected: '',
      sizeSelected: null,
      isSelectedSize: true,
      isAvailbale: true,
      loading: true,
      notFound: false,
      hideAddToCartMobile: false,
      idProductAttributeSelected: null,
    };
  },
  computed: {
    product() {
      return this.$store.state.product.currentProduct;
    },
    productTitle() {
      if (this.product != null) {
        try {
          return this.product.Description.Title;
        } catch (error) {}
      }
      return '';
    },
    productDescription() {
      if (this.product != null) {
        try {
          return this.product.Description.Description;
        } catch (error) {}
      }
      return '';
    },
    productDescriptionShort() {
      if (this.product != null) {
        try {
          return this.product.Description.DescriptionShort;
        } catch (error) {}
      }
      return '';
    },
    productImages() {
      try {
        if (this.product.Media) {
          return this.product.Media.Images.product_large;
        }
      } catch (error) {}

      return [];
    },
    productImagesXLarge() {
      try {
        if (this.product.Media) {
          return this.product.Media.Images.product_xlarge;
        }
      } catch (error) {}

      return [];
    },
    productVideos() {
      try {
        if (this.product.Media) {
          return this.product.Media.Videos;
        }
      } catch (error) {}

      return [];
    },

    breadcrumbLinks() {
      const links = [
        {
          link: '/',
          text: this.$t('Home'),
        },
      ];
      if (this.mainCatgoryTitle) {
        links.push({
          link: this.localePath(`/${this.urlCategory}`),
          text: this.mainCatgoryTitle,
        });
      }

      links.push({
        text: this.productTitle,
      });
      return links;
    },

    variants() {
      return this.product.Variant;
    },

    mainCatgoryTitle() {
      try {
        const seo = this.product?.Category?.Main?.SEO;
        if (seo) {
          const mainCategory = seo.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode.toLowerCase();
          });
          if (!mainCategory) {
            return null;
          }
          if (mainCategory.MetaTitle) {
            return mainCategory.MetaTitle;
          }
        }
      } catch (error) {}
      return null;
    },
    urlCategory() {
      try {
        const seo = this.product?.Category?.Main?.SEO;

        if (seo) {
          const mainCategory = seo.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode.toLowerCase();
          });
          if (!mainCategory) {
            return 'product';
          }
          if (mainCategory?.URL) {
            return mainCategory?.URL;
          }
        }
      } catch (error) {}
      return 'product';
    },
    Delivery() {
      if (this.product.Delivery) {
        return this.product.Delivery;
      }
      return null;
    },
    dateOfDeilvery() {
      if (this.Delivery === null) {
        return false;
      }
      if (this.Delivery.Time) {
        const formatDate = new FormatDate();
        if (this.Delivery.Time.From) {
          const dateMin = formatDate.date(
            this.covertDate(this.Delivery.Time.From)
          );
          const dateMax = formatDate.date(
            this.covertDate(this.Delivery.Time.To)
          );
          return {
            dateMin,
            dateMax,
          };
        } else {
          const dateMax = formatDate.date(
            this.covertDate(this.Delivery.Time.To)
          );
          return {
            dateMax,
          };
        }
      }

      return false;
    },

    countryDelivery() {
      try {
        const localisationHelper = new LocalisationHelper(
          this.$http,
          this.$store,
          this.$auth
        );

        return localisationHelper.getAppCountry()
          ? localisationHelper.getAppCountry()
          : '';
      } catch (error) {}

      return '';
    },
    tel() {
      if (this.$i18n.locale === 'fr') {
        return '01.85.14.62.85';
      }

      return '+33.1.85.14.62.85';
    },
    productsVisited() {
      return this.$store.state.products_visited.products;
    },
    seo() {
      try {
        if (Array.isArray(this.product.SEO)) {
          let seo = this.product.SEO[0];
          if (this.$i18n.locale === 'en') {
            seo = this.product.SEO[1];
          }
          if (this.$i18n.locale === 'de') {
            seo = this.product.SEO[2];
          }
          return seo;
        } else {
          const seo = this.product.SEO;
          return seo;
        }
      } catch (error) {}

      return null;
    },
    getLinkShopTheLook() {
      try {
        return this.localePath('/shop-the-look/' + this.seo.URL);
      } catch (error) {}
      return '#';
    },
  },
  methods: {
    async permalinkLangUrl() {
      const links = {};

      if (this.product.SEO) {
        this.product?.SEO.map((item, index) => {
          const mainCategory = this.product?.Category?.Main?.SEO?.find(
            (catSeo) => {
              return (
                item.LanguageIsoCode.toLocaleLowerCase() ===
                catSeo.LanguageIsoCode.toLocaleLowerCase()
              );
            }
          );

          links[item.LanguageIsoCode.toLowerCase()] = {
            cat: mainCategory?.URL,
            product: item.URL,
          };
        });
        await this.$store.dispatch('i18n/setRouteParams', links);
      }
    },
    selectSize(event) {
      const attr = event.variant;
      this.colorSelected = event.color;
      this.sizeSelected = event.size;
      this.isSelectedSize = true;

      this.isAvailbale = attr.Quantity > 0;
      this.idProductAttributeSelected =
        event.variant.Combination.IdProductAttribute;

      if (process.client) {
        if (this.$SETTINGS.isTablette) {
          if (this.isSelectedSize) {
            if (this.isAvailbale) {
              this.setProductToCart();

              this.taillesIsOpen = false;
            } else {
              this.$refs['ProductNotAvailable'].open();
            }
            this.taillesIsOpen = false;
          }
        }
      }
    },

    toggleAddToCart() {
      const refs = this.$refs;
      let taillesWrapper = refs.tailles;
      let btnAddWrapper = refs.btnAdd;

      if (this.taillesIsOpen) {
        this.taillesIsOpen = false;
      } else {
        this.taillesIsOpen = true;
      }
    },
    addToCart() {
      if (process.client) {
        if (this.$SETTINGS.isTablette) {
          this.toggleAddToCart();
        } else {
          this.isSelectedSize = this.sizeSelected !== null;

          if (this.isSelectedSize) {
            this.setProductToCart();
          }
        }
      }
    },

    setProductToCart() {
      this.cartService
        .addToCart({
          idCart: this.cart.IdCart,
          product: this.product,
          idProductAttribute: this.idProductAttributeSelected,
        })
        .then((data) => {
          this.cartService.fetchCart({
            onSuccess: (data) => {
              this.openRightBarCart();
            },
          });

          this.$gtag.event('Add to cart', {
            event_category: 'Cart',
            event_label: 'Product : ' + this.productTitle,
            value: 'Id product : ' + this.product.IdProduct,
          });

          fbq('track', 'InitiateCheckout', {
            content_ids: [this.product.IdProduct + '-' + this.productTitle],
            eventref: '', // or set to empty string
          });
        });
    },

    openBookingDialog() {
      this.$refs['bookingDialog'].openDialog();
    },

    isElementInViewport(el) {
      if (el) {
        const rect = el.getBoundingClientRect();
        return rect.top < 0;
      }
      return false;
    },
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      if (this.taillesIsOpen) {
        this.taillesIsOpen = false;
        this.isAvailbale = true;
      }
      const element = this.$refs.elementToCheck;

      if (this.isElementInViewport(element)) {
        this.hideAddToCartMobile = true;
      } else {
        this.hideAddToCartMobile = false;
      }
    },

    loadProductSimilar() {
      // const productHelpers = new ProductHelpers(this.$http, this);
      // const permalink = this.$route.params.product;
      // productHelpers
      //   .getProductstSimilar(permalink)
      //   .then((response) => {
      //     this.productsSimilar = response.data;
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    },
  },
  mounted() {
    this.$store.commit('products_visited/init', {
      $auth: this.$auth,
    });

    this.$store.commit('products_visited/addProduct', {
      product: this.product,
      $auth: this.$auth,
    });

    this.$store.commit('shared/menu/setAlwaysMenuSticky', true);
    this.$store.commit('shared/topBanner/setAlwaysDisable', true);
    const permalink = this.$route.params.product;
    this.$store
      .dispatch('product/fetchProduct', {
        permalink,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
      })
      .then((data) => {
        this.permalinkLangUrl();
      })
      .catch((err) => {
        this.notFound = true;
      })
      .finally(() => {
        this.loading = false;
      });
  },

  created() {
    if (process.client) {
      window.addEventListener('scroll', this.handleScroll);
    }

    this.loadProductSimilar();
  },

  unmounted() {
    if (process.client) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },

  destroyed() {
    this.$store.commit('shared/menu/setAlwaysMenuSticky', false);
    this.$store.commit('shared/topBanner/setAlwaysDisable', false);
  },

  components: {
    CarouselImages,
    ProductInformations,
    ProductRelationship,
    ProductSizes,
    Button,
    ProductNotAvailable,
    Collapse,
    CollapseItem,
    MapPinIcon,
    ProductLoading,
    PageNotFound,
  },
};
