
export default {
  props: {
    height: { type: [Number, String], default: 500 },
    src: {
      type: String,
      default: "~/assets/img/default-img.jpg",
    },
  },
  computed: {
    _srcset() {
      return this.$img.getSizes(this.src, {
        sizes: "xs:100vw sm:100vw md:100vw lg:100vw xl:100vw",
        modifiers: {
          format: "webp",
          quality: 70,
          height: 500,
        },
      });
    },
  },
};
