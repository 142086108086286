
import { Button, Popover } from 'element-ui';
import { InfoIcon, HelpCircleIcon } from 'vue-feather-icons';

export default {
  data() {
    return {
      // formIsValide: false,
      cardInstance: null,
      hipay: null,

      errors: [],
    };
  },
  computed: {
    cardNumberValidate() {
      const error = this.errors.filter((item) => {
        return item.field === 'cardNumber';
      })[0];
      if (error) {
        return {
          valid: false,
          error,
        };
      }
      return {
        valid: true,
      };
    },
    expiryDateValidate() {
      const error = this.errors.filter((item) => {
        return item.field === 'expiryDate';
      })[0];

      if (error) {
        return {
          valid: false,
          error,
        };
      }
      return {
        valid: true,
      };
    },
    cvcValidate() {
      const error = this.errors.filter((item) => {
        return item.field === 'cvc';
      })[0];

      if (error) {
        return {
          valid: false,
          error,
        };
      }
      return {
        valid: true,
      };
    },
    defaultLocal() {
      return this.$i18n.locale;
    },
    guestUser() {
      return this.$store.state.user.guest.guestUser;
    },
  },
  mounted() {
    if (this.$refs['hipay-card-holder'].innerHTML.trim() === '') {
      try {
        this.initHipay();
        this.cardPayment();
      } catch (error) {
        console.error(error);
      }
    }
  },
  // watch: {
  //   formIsValide() {

  //   },
  // },
  methods: {
    async onSubmit() {
      const $this = this;
      /* Tokenize your card information when the submit button is clicked */
      return await this.cardInstance.getPaymentData();
    },
    initHipay() {
      this.hipay = HiPay({
        username: process.env.HIPAY_USERNAME,
        password: process.env.HIPAY_PASSWORD,
        environment: process.env.HIPAY_ENVIRONMENT,
        lang: this.defaultLocal,
      });
    },
    cardPayment() {
      let user = this.$auth.user || this.guestUser;

      const { Firstname, Lastname } = user?.Customer;

      const config = {
        fields: {
          cardHolder: {
            selector: 'hipay-card-holder', // card holder div id
            defaultLastname: Lastname,
            defaultFirstname: Firstname,
          },
          cardNumber: {
            selector: 'hipay-card-number', // card number div id
          },
          expiryDate: {
            selector: 'hipay-expiry-date', // expiry date div id
          },
          cvc: {
            selector: 'hipay-cvc', // cvc div id
          },
        },
        brand: ['visa', 'mastercard', 'maestro', 'american-express'],
      };

      if (this.hipay != null) {
        this.cardInstance = this.hipay.create('card', config);

        const $this = this;
        this.cardInstance.on('change', function (event) {
          $this.errors = event.errors;
          /* Display error(s), if any */
          /* Enable / disable submit button */
          $this.formIsValide = event.valid;
          
          $this.$emit('change', {
            valid: event.valid,
            errors: $this.errors,
          });
        });
      }
    },
  },
  components: { Button, InfoIcon, HelpCircleIcon, Popover },
};
