
export default {
  props: {
    images: {
      type: Array,
      required: false,
    },
    imagesXLarg: {
      type: Array,
      required: false,
    },
    videos: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      imageZoom: {
        startImgIndex: 0,
        isZooming: false,
      },
    };
  },
  methods: {
    showZoom(i) {
      this.imageZoom.isZooming = true;
      this.imageZoom.startImgIndex = i;
    },
  },
};
