
import { RadioButton, RadioGroup, Button, Checkbox } from 'element-ui';
import AlmaPayment from '../../../../../services/payments/AlmaPayment';
import cartMixin from '@/mixins/cartMixin';
import addressMixin from '@/mixins/addressMixin';
export default {
  mixins: [cartMixin, addressMixin],
  props: {
    alma1x: {
      type: String,
      default: null,
    },
    alma2x: {
      type: String,
      default: null,
    },
    alma3x: {
      type: String,
      default: null,
    },
    alma4x: {
      type: String,
      default: null,
    },
    paymentMethods: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      generalConditionsSale: false,
      almaRadio: '',
    };
  },
  mounted() {
    this.setDefaultActive();
  },
  computed: {
    almaPayment() {
      return new AlmaPayment({
        cart: this.cart,
        auth: this.$auth,
        http: this.$http,
        addressService: this.addressService,
      });
    },
    paymentMethod() {
      return this.paymentMethods?.find(
        (item) => item.PaymentCode === this.almaRadio
      );
    },
  },
  methods: {
    setDefaultActive() {
      if (this.alma2x) {
        this.almaRadio = this.alma2x;
      } else if (this.alma3x) {
        this.almaRadio = this.alma3x;
      } else if (this.alma4x) {
        this.almaRadio = this.alma4x;
      } else if (this.alma1x) {
        this.almaRadio = this.alma1x;
      }
    },
    toggleLoader(val) {
      this.$store.commit('shared/wind/setIsLoading', val);
    },
    async checkoutAlma() {
      let installmentsCount = 1;
      if (this.almaRadio === 'ALMA1X') {
        installmentsCount = 1;
      }
      if (this.almaRadio === 'ALMA2X') {
        installmentsCount = 2;
      }
      if (this.almaRadio === 'ALMA3X') {
        installmentsCount = 3;
      }
      if (this.almaRadio === 'ALMA4X') {
        installmentsCount = 4;
      }

      this.toggleLoader(true);

      try {
        const response = await this.almaPayment.checkout({
          installmentsCount,
          paymentMethod: this.paymentMethod,
        });
        if (process.client) {
          window.open(response.url, '_self');
        }
      } catch (error) {
        console.error(error);
        // if (error.response.data.errors) {
        //   this.$message.error(error.response.data.errors[0].message);
        // }
      } finally {
        this.toggleLoader(false);
      }
    },
  },
  components: { RadioButton, RadioGroup, Button, Checkbox },
};
