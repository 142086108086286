
import { Progress } from "element-ui";
import { Button, Collapse, CollapseItem } from "element-ui";
import CartItems from "@/pages/cart/components/CartItems.vue";
import DutyInformation from "@/pages/cart/components/DutyInformation.vue";
import CardInfo from "@/pages/cart/components/CardInfo.vue";
import TotalOrder from "@/pages/cart/components/TotalOrder.vue";
import TunnelOrderLayout from "./TunnelOrderLayout.vue";
import CodePromo from "./CodePromo.vue";
import CodePromo2 from "./CodePromo2.vue";
import { ProductHelpers } from "@/helpers/ProductHelpers.js";
import cartMixin from "@/mixins/cartMixin.js";

export default {
  mixins: [cartMixin],
  data() {
    return {
      posBoxA: 0,
      posBoxB: 0,
      hasError: false,
      productSimilar: [],
    };
  },
  computed: {
    productCartSize() {
      return this.cart?.Total?.Product?.Quantity;
    },
    disableBtn() {
      return this.productCartSize === 0 || this.hasError;
    },
  },
  methods: {
    next() {
      // this.$emit("onCommandeClick");
      this.$store.commit("shared/wind/setLoginGoTo", "/cart/delivery");
      if (this.$auth.loggedIn) {
        this.$router.push(this.localePath("/cart/delivery"));
      } else {
        // this.$router.push(this.localePath("/authenticate/login?redirect=2"));
        this.$router.push(this.localePath("/cart/connection"));
      }
    },
    checkHasError($event) {
      this.hasError = $event;
    },

    loadProductSimilar() {
      const productHelpers = new ProductHelpers(this.$http, this);

      const lastItem = this.cart?.Products[0];

      if (lastItem) {
        const permalink = String(lastItem.IdProduct);
        try {
          productHelpers
            .getProductstSimilar(permalink)
            .then((response) => {
              if (Array.isArray(response)) {
                this.productSimilar = response;
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } catch (error) {}
      }
    },
  },
  created() {
    this.loadProductSimilar();
  },
  components: {
    DutyInformation,
    CartItems,
    Progress,
    Button,
    Collapse,
    CollapseItem,
    CardInfo,
    TotalOrder,
    TunnelOrderLayout,
    CodePromo,
    CodePromo2,
  },
};
