
import DefaultLayout from "./DefaultLayout.vue";
import sharedMixin from "@/mixins/sharedMixin.js";

export default {
  mixins: [sharedMixin], //hasTopBanner
  components: { DefaultLayout },
  computed: {
    menuIsSticky() {
      if (
        this.$store.state.shared.menu.alwaysSticky &&
        this.$SETTINGS.isMobile
      ) {
        return true;
      } else {
        return this.$store.state.shared.menu.sticky;
      }
    },
  },
};
