
import GoogleAutoComplete from "./GoogleAutoComplete.vue";

export default {
  components: {
    GoogleAutoComplete,
  },
  props: {
    points: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // Map variables
      currentPoint: this.points[0],
      circleOptions: {},
      pins: {
        selected: require(`~/assets/img/icons/map-marker-selected.png`),
        notSelected: require(`~/assets/img/icons/map-marker.png`),
      },
      clusterStyle: [
        {
          url: require(`~/assets/img/icons/map-marker-group.png`),
          width: 56,
          height: 56,
          textColor: "#fff",
        },
      ],
      mapStyles: [
        {
          featureType: "administrative.land_parcel",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.neighborhood",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.business",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getLat(point) {
      return Number(point.latitude);
    },
    getlng(point) {
      return Number(point.longitude);
    },
    getPosition(point) {
      return { lat: this.getLat(point), lng: this.getlng(point) };
    },
  },
};
