
import LookbookCardLink from "./LookbookCardLink.vue";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    videoUrl: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    vdScale() {
      if (process.client) {
        return (window.innerHeight / window.innerWidth) * 2;
      }

      return 1;
    },
  },
  methods: {
    getFullImgCardImageUrl(val) {
      if (val.image.data) {
        return val.image.data.attributes.url;
      }
      return "";
    },
    getProduct(id) {
      const list = this.products.filter((item) => {
        return item.IdProduct === id;
      });
      if (list.length) {
        return list[0];
      }

      return {};
    },
  },
  components: { LookbookCardLink },
};
