
import cartMixin from '@/mixins/cartMixin.js';
import { ShoppingBagIcon } from 'vue-feather-icons';
export default {
  mixins: [cartMixin],
  computed: {
    productCartSize() {
      return this.cart?.Total?.Product?.Quantity;
    },
  },
  components: {
    ShoppingBagIcon,
  },
};
