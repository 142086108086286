
import AccountBody from "@/pages/account/components/AccountBody.vue";
import TopMobileBack from "@/pages/account/components/TopMobileBack.vue";
import ReturnCard from "@/pages/account/components/ReturnCard.vue";
import { OrderHelper } from "/helpers/OrderHelper";
import { ShoppingBagIcon, ChevronLeftIcon } from "vue-feather-icons";
import ReturnProductCard from "@/pages/account/components/ReturnProductCard.vue";
import { Button } from "element-ui";
export default {
  layout: "account",
  name: "returnDetailPage",
  // middleware: "authenticated",
  middleware: "auth",
  async asyncData(context) {
    // return {
    //   order,
    // };
  },
  data() {
    return {
      tab: "",
      TopMobileBackInfo: {
        title: "Mon compte",
        icon: "",
      },
      feedback: false,
    };
  },
  methods: {
    getHistoryLocation(history) {
      const index = history.OrderReturnState.findIndex(
        (item) => item.LanguageIsoCode === this.$i18n.locale
      );
      if (index !== -1) {
        return history.OrderReturnState[index];
      }
      return history.OrderReturnState[0];
    },
    getHistoryImage(history) {
      if (history.OrderReturnState.length > 0) {
        if (history.OrderReturnState[0].OrderReturnStateCode === "1") {
          return require(`~/assets/img/box-shipped.svg`);
        } else if (history.OrderReturnState[0].OrderReturnStateCode === "2") {
          return require(`~/assets/img/box-progress.svg`);
        } else if (history.OrderReturnState[0].OrderReturnStateCode === "3") {
          return require(`~/assets/img/return-complet.svg`);
        } else {
          return require(`~/assets/img/box-valid.svg`);
        }
      }
      return require(`~/assets/img/box-valid.svg`);
    },
  },
  async mounted() {
    const idOrderReturn = this.$route.params.id;
    const orderHelper = new OrderHelper(
      this.$http,
      this.$store,
      this.$i18n.locale
    );
    const feedback = await orderHelper.getReturnDetail({
      idOrderReturn,
      languageIsoCode: this.$i18n.locale,
    });

    this.feedback = feedback;
  },
  computed: {},
  components: {
    AccountBody,
    TopMobileBack,
    ReturnCard,
    ShoppingBagIcon,
    ChevronLeftIcon,
    ReturnProductCard,
    Button,
  },
};
