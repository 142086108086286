
import { Select, Option } from "element-ui";
import { FilterIcon, XIcon } from "vue-feather-icons";
export default {
  data() {
    let sortOptions = {
      options: [
        {
          value: "price_asc",
          label: this.$t("Ascending price"),
        },
        {
          value: "price_desc",
          label: this.$t("Decreasing price"),
        },
        {
          value: "newest_desc",
          label: this.$t("Newest"),
        },
        {
          value: "newest_asc",
          label: this.$t("Oldest"),
        },
      ],
      value: "",
    };
    return {
      showSort: true,
      sortOptions,
    };
  },
  computed: {
    btnResetSort() {
      return this.sortOptions.value !== "";
    },
    orderBy() {
      return this.$store.state.newCategory.orderBy;
    },
  },
  mounted() {
    this.sortOptions.value = this.orderBy;
  },
  methods: {
    resetSort() {
      this.sortOptions.value = "";
      this.$store.commit("newCategory/setOrderBy", {
        value: this.sortOptions.value,
      });
      this.$emit("onSort");
    },
    onSort() {
      this.$store.commit("newCategory/setOrderBy", {
        value: this.sortOptions.value,
      });
      this.$emit("onSort");
    },
  },
  components: {
    Select,
    Option,
    FilterIcon,
    XIcon,
  },
};
