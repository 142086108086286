
import { Radio } from "element-ui";
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
  },
  components: {
    Radio,
  },
  methods: {
    scrollToRadio() {
      this.$nextTick(() => {
        this.$refs.paypalRadio.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    }
  },
};
