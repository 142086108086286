
import { Button } from 'element-ui';
import LoyaltyOurStatus from '@/pages/account/components/LoyaltyOurStatus.vue';
import EarnPoints from '@/pages/account/components/EarnPoints.vue';
import LoyaltyAwardsCatalog from '@/pages/account/components/LoyaltyAwardsCatalog.vue';
import LoyaltyJoinUs from '@/pages/account/components/LoyaltyJoinUs.vue';

export default {
  layout: 'MainLayout',
  auth: false,
  nuxtI18n: {
    paths: {
      en: '/loyalty-program/index',
      fr: '/programme-fidelite/index',
      de: '/programm-fidelite/index',
    },
  },
  data() {
    return {
      shopPage: null,
    };
  },

  computed: {
    breadcrumbLinks() {
      return [
        {
          link: '/',
          text: this.$t('Home'),
        },
        {
          text: this.$t('Our shops'),
        },
      ];
    },
    loyaltyLink() {
      let link = this.localePath(`/account/loyalty/membership`);
      if (!this.$auth.loggedIn) {
        return this.localePath(
          `/authenticate/login?redirect=${this.$t('loyalty_path')}`
        );
      }
      return link;
    },
  },
  components: {
    Button,
    EarnPoints,
    LoyaltyJoinUs,
    LoyaltyOurStatus,
    LoyaltyAwardsCatalog,
  },
};
