
import { Collapse, CollapseItem } from 'element-ui';
import cartMixin from '@/mixins/cartMixin.js';
import {
  UserIcon,
  ShoppingBagIcon,
  HeartIcon,
  CornerDownRightIcon,
  CornerUpLeftIcon,
  AwardIcon,
  MessageSquareIcon,
  LogOutIcon,
  UsersIcon,
} from 'vue-feather-icons';

import {
  loadFbSdk,
  getFbLoginStatus,
  fbLogout,
} from '/plugins/facebookLogin/helpers.js';
import CustomerService from '@/services/CustomerService';

export default {
  mixins: [cartMixin],
  data() {
    return {
      // activeName: "",
      selectedItem: '',
      customerService: new CustomerService({
        http: this.$http,
        auth: this.$auth,
        store: this.$store,
      }),
    };
  },
  computed: {
    tabActive: {
      get() {
        return this.$store.state.shared.menu.acountSideBarActive;
      },
      set(val) {
        this.$store.commit('shared/menu/setAcountSideBarActive', val);
      },
    },
  },
  components: {
    Collapse,
    CollapseItem,
    UserIcon,
    ShoppingBagIcon,
    HeartIcon,
    CornerDownRightIcon,
    CornerUpLeftIcon,
    AwardIcon,
    MessageSquareIcon,
    LogOutIcon,
    UsersIcon,
  },
  mounted() {
    for (let key in this.$refs) {
      let item = this.$refs[key];

      if (item.$el.classList.contains('nuxt-link-active')) {
        const words = key.split('--');
        // this.activeName = words[0];

        this.tabActive = words[0];
      }
    }
  },
  methods: {
    async logout() {
      try {
        const faceboookStatusConnected = await getFbLoginStatus();
        if (faceboookStatusConnected.status === 'connected') {
          await loadFbSdk(process.env.FACEBOOK_APP_ID, 'v2.10');
          await fbLogout();
        }
      } catch {}

      this.$store.commit('cart/clear', this.$auth);
      
      this.cartService.clearCart();

      this.$store.dispatch('user/guest/setGuestUser', {
        user: null,
        cookies: this.$cookies,
      });

      this.$store.commit('favorite/clear', this.$auth);
      this.$store.commit('shop/tunnel/address/clearStore', this.$auth);

      await this.$auth.logout();
      this.customerService.logOut();
      this.$router.replace(this.localePath('/'));
    },
  },
};
