
import { Empty } from 'element-ui';
import favoriteMixin from '@/mixins/favoriteMixin.js';
import ProductCardDefault from '@/components/ProductCard/ProductCardDefault.vue';

const responsiveSlider = {
  0: {
    slidesPerView: 2,
    spaceBetween: 16,
  },
  767: {
    slidesPerView: 2.1,
    spaceBetween: 16,
  },
  1024: {
    slidesPerView: 4.9,
    spaceBetween: 16,
  },
};
const responsiveSliderSm = {
  0: {
    slidesPerView: 2.2,
    spaceBetween: 16,
  },
  1024: {
    slidesPerView: 3.2,
    spaceBetween: 16,
  },
};

export default {
  props: {
    rowcard: {
      type: Boolean,
      default: false,
    },
    smallSlider: {
      type: Boolean,
      default: false,
    },
    /* TODO dynamise category text data */
    categoryLink: {
      default: 'category',
      type: String,
    },
  },
  mixins: [favoriteMixin],
  data() {
    return {
      responsiveSlider,
    };
  },
  mounted() {},

  computed: {
    sliderOptions() {
      if (this.smallSlider) {
        return responsiveSliderSm;
      }
      return responsiveSlider;
    },
    productFavorites() {
      return this.favorite?.Products;
    },
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },
  },
  components: { Empty, ProductCardDefault },
};
