
import { UserIcon, ChevronLeftIcon } from "vue-feather-icons";
import AccountBody from "@/pages/account/components/AccountBody.vue";
import AddressNew from "@/pages/account/components/AddressNew.vue";
import TopMobileBack from "../components/TopMobileBack.vue";
export default {
  layout: "account",
  name: "accountPage",
  middleware: "auth",
  data() {
    return {
      tab2: "",
      TopMobileBackInfo: {
        title: this.$t("My addresses"),
        icon: "shopping-bag",
      },
    };
  },

  mounted() {},
  methods: {},
  components: {
    UserIcon,
    ChevronLeftIcon,
    AccountBody,
    AddressNew,
    TopMobileBack,
  },
};
