
import NewCompte from "@/pages/authenticate/components/NewCompte.vue";
import ResetForm from "@/pages/password/components/ResetForm.vue";
import PasswordLayout from "./components/PasswordLayout.vue";
export default {
  layout: "MainLayout",
  name: "ResetPasswordPage",
  auth: false,
  middleware: ["authenticated"],
  // nuxtI18n: {
  //   paths: {
  //     en: "/reset",
  //     fr: "/reinitialiser",
  //   },
  // },

  created() {
    this.$store.commit("shared/menu/toggle", {
      tabActive: 1,
      open: false,
    });
  },
  components: {
    ResetForm,
    NewCompte,
    PasswordLayout,
  },
};
