
import { Button, Dialog } from "element-ui";
import { ChevronRightIcon } from "vue-feather-icons";
import { ProductHelpers } from "@/helpers/ProductHelpers";
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      step: 1,
      color: null,
      idProductAttribute: null,
      size: null,
      shop: null,
      customer: null,
    };
  },
  mounted() {
    this.step = 1;
  },
  computed: {},
  methods: {
    goToStep(step) {
      if (step < this.step && this.step != 4) {
        this.step = step;
      }
    },
    openDialog() {
      this.showDialog = true;
    },
    stepProductNext($event) {
      this.size = $event.size;
      this.color = $event.color;
      this.idProductAttribute = $event.idProductAttribute;
      this.step = 2;
    },
    stepShopNext($event) {
      this.shop = $event.shop;
      this.step = 3;
    },
    async startReservation($event) {
      this.customer = $event.contactForm;
      const productHelpers = new ProductHelpers(this.$http, this);
      const responseData = await productHelpers.reservationProduct({
        customer: this.customer,
        product: this.product,
        shop: this.shop,
        reservation: {
          idProductAttribute: this.idProductAttribute,
          quantity: 1,
        },
      });
      if (responseData.status === 200) {
        this.step = 4;
      }
    },
  },
  created() {},
  components: {
    Button,
    Dialog,
    ChevronRightIcon,
  },
};
