
import PrivacyBloc from "@/pages/authenticate/components/PrivacyBloc.vue";
export default {
  layout: "MainLayout",
  name: "ResetPage",
  auth: false,
  nuxtI18n: {
    paths: {
      en: "/reset",
      fr: "/reinitialiser",
    },
  },
  components: {
    PrivacyBloc,
  },
};
