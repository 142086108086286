
import { Button } from 'element-ui';
import LoyaltyPostsCardReward from './LoyaltyPostsCardReward.vue';
export default {
  props: {
    connected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      staticReward: [
        {
          title: this.$t('GIFT CARD', ['10€']),
          discription: this.$t(
            'to spend as you wish! Store, website… treat yourself !',
            ['10€']
          ),
        },
        {
          title: this.$t('GIFT CARD', ['20€']),
          discription: this.$t(
            'to spend as you wish! Store, website… treat yourself !',
            ['20€']
          ),
        },
        {
          title: this.$t('GIFT CARD', ['50€']),
          discription: this.$t(
            'to spend as you wish! Store, website… treat yourself !',
            ['50€']
          ),
        },
      ],
    };
  },
  computed: {
    loyaltyLink() {
      let link = this.localePath(`/account/loyalty/membership`);
      if (!this.$auth.loggedIn) {
        return this.localePath(
          `/authenticate/login?redirect=${this.$t('loyalty_path')}`
        );
      }
      return link;
    },
  },
  components: {
    Button,
    LoyaltyPostsCardReward,
  },
};
