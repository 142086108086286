
import { ChevronRightIcon } from "vue-feather-icons";
import { Amount } from "@/helpers/Amount.js";
export default {
  props: {
    lookbookProduct: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nbrCol: 6,
      nbrRow: 6,
      hovered: false,
      amount: new Amount(this),
    };
  },
  computed: {
    // link() {
    //   if (this.product.Title) {
    //     return this.localePath(
    //       `/product/${this.product.Title.replace("/", "-").replace("#", "-")}-${this.lookbookProduct.product_url
    //       }`
    //     );
    //   }
    //   return this.localePath("/");
    // },
    hasPromotion() {
      if (this.product.Prices) {
        return this.product.Prices.InPromotion;
      }
      return false;
    },
    regularPrice() {
      if (this.product.Prices) {
        const price = this.amount.price(
          this.product.Prices.RegularPriceTaxExcl
        );
        return price.ttc;
      }
      return 0;
    },
    promotionPrice() {
      if (this.hasPromotion) {
        const price = this.amount.price(
          this.product.Prices.PromotionPriceTaxExcl
        );
        return price.ttc;
      }
      return this.regularPrice;
    },
    promotionValue() {
      if (this.hasPromotion) {
        return this.product.PromotionType.Name;
      }

      return "";
    },
    currencySign() {
      return this.$localisation.currencySign;
    },
    urlCategory() {
      try {
        const seo = this.product.SEO;
        if (seo) {
          const mainCategory = seo.MainCategory.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode;
          });
          if (!mainCategory) {
            return "product";
          }
          if (mainCategory.CategoryDescriptionFriendlyURL) {
            return mainCategory.CategoryDescriptionFriendlyURL;
          }
        }
      } catch (error) {
        console.error(error);
      }
      return "product";
    },

    urlProduct() {
      try {
        const seo = this.product.SEO;
        if (seo) {
          const productSeo = seo.Product.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode;
          });
          if (!productSeo) {
            return this.product.IdProduct;
          }
          if (productSeo.ProductDescriptionFriendlyURL) {
            return productSeo.ProductDescriptionFriendlyURL;
          }
        }
      } catch (error) {
        console.error(error);
      }
      return this.product.IdProduct;
    },

    link() {
      try {
        if (this.product.SEO) {
          return this.localePath(`/${this.urlCategory}/${this.urlProduct}`);
        }
      } catch (error) { }

      return "#";
    },
  },
  methods: {
    position(lookbookProduct) {
      const row = Number(lookbookProduct.row.split("_")[1]);
      const col = Number(lookbookProduct.column.split("_")[1]);

      const top = (row / this.nbrRow) * 100;
      const left = (col / this.nbrCol - 1 / (this.nbrCol * 2)) * 100;

      return {
        top: top + "%",
        left: left + "%",
      };
    },
    display(lookbookProduct) {
      const col = Number(lookbookProduct.column.split("_")[1]);

      if (col >= this.nbrCol - 1) {
        return "right";
      }
      return "left";
    },
    showLink(val) {
      this.hovered = val;
    },
  },
  components: { ChevronRightIcon },
};
