
import BanContactForm from './BanContactForm.vue';
import { Button, Checkbox } from 'element-ui';
import hipayMixin from '../hipayMixin';

export default {
  mixins: [hipayMixin],
  props: {
    paymentMethod: {
      type: Object,
    },
  },
  data() {
    return {
      generalConditionsSale: false,
    };
  },
  computed: {},
  components: {
    Button,
    Checkbox,
    BanContactForm,
  },
  methods: {
    submit() {
      this.startPayment({
        paymentData: { payment_product: 'bancontact' },
        paymentMethod: this.paymentMethod,
      });
    },
  },
};
