
import { Button } from "element-ui";
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    size: {
      required: true,
      type: String,
    },
    shop: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  components: {
    Button,
  },
};
