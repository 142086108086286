
import { ShoppingBagIcon } from "vue-feather-icons";
import AccountBody from "@/pages/account/components/AccountBody.vue";
import Orders from "@/pages/account/components/Orders.vue";
import TopMobileBack from "@/pages/account/components/TopMobileBack.vue";
export default {
  layout: "account",
  name: "ordersPage",
  // middleware: "authenticated",
  middleware: "auth",
  data() {
    return {
      tab: "",
    };
  },
  components: {
    AccountBody,
    Orders,
    TopMobileBack,
    ShoppingBagIcon,
  },
  mounted() {},
};
