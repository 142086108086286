
export default {
  props: {
    light: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },
    menuIsLight() {
      return this.$store.state.shared.menu.isTransparent;
    },
    isH1() {
      try {
        return this.$route.name.includes("index___");
      } catch (error) {
        return false;
      }
    },
  },
};
