
import ProductCardDefault from '@/components/ProductCard/ProductCardDefault.vue';
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      responsiveSlider: {
        0: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    };
  },
  methods: {
    randomKey() {
      return (
        new Date().getTime() + Math.floor(Math.random() * 10000).toString()
      );
    },
    openFavSideBar() {
      this.$store.commit('shared/menu/toggle', {
        tabActive: 2,
        open: true,
      });
    },
    getCategorySeo(product) {
      if (product.Category) {
        const seoCat = product.Category.Main.SEO;
        try {
          let seo = seoCat[0];
          if (this.$i18n.locale === 'en') {
            seo = seoCat[1];
          }
          if (this.$i18n.locale === 'de') {
            seo = seoCat[2];
          }
          return seo;
        } catch (error) {}
      }

      return null;
    },
    categoryId(product) {
      try {
        return '-' + product.Category.Main.IdCategory;
      } catch (error) {}

      return '-';
    },
    categoryUrl(product) {
      try {
        return this.getCategorySeo(product).URL + this.categoryId(product);
      } catch (error) {}

      return 'product';
    },
  },
  components: {
    ProductCardDefault,
  },
};
