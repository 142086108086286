
export default {
  props: {
    dir: {
      type: String,
      default: "left",
    },
    sender: {
      type: String,
    },
    date: {
      type: String,
    },
    full: {
      type: Boolean,
    },
  },
  setup() {
    return {};
  },
};
