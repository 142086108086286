import { render, staticRenderFns } from "./IdealHeader.vue?vue&type=template&id=d3810c1e&scoped=true"
import script from "./IdealHeader.vue?vue&type=script&lang=js"
export * from "./IdealHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3810c1e",
  null
  
)

export default component.exports