
import {
  ArrowLeftIcon,
  CornerUpRightIcon,
  CornerDownLeftIcon,
} from "vue-feather-icons";
export default {
  components: {
    ArrowLeftIcon,
    CornerUpRightIcon,
    CornerDownLeftIcon,
  },
};
