
import { UserIcon, ChevronLeftIcon } from "vue-feather-icons";
import AccountInfo from "@/pages/account/components/AccountInfo.vue";
import AccountBody from "@/pages/account/components/AccountBody.vue";
import AccountInfoEdit from "@/pages/account/components/AccountInfoEdit.vue";
import OrderDetails from "@/pages/account/components/OrderDetails.vue";
import LoyaltyPosts from "@/pages/account/components/LoyaltyPosts.vue";
import LoyaltyMyRewards from "@/pages/account/components/LoyaltyMyRewards.vue";
import LoyaltyHistory from "@/pages/account/components/LoyaltyHistory.vue";
import LoyaltyMembership from "@/pages/account/components/LoyaltyMembership.vue";
import TopMobileBack from "../components/TopMobileBack.vue";
export default {
  layout: "account",
  name: "accountPage",
  middleware: "auth",
  data() {
    return {
      tab2: "",
      TopMobileBackInfo: {
        title: "Mon compte",
        icon: "",
      },
    };
  },
  computed: {
    getTab2() {
      const tab = this.$route.params.tab;
      const tab2 = this.$route.params.tab2;

      if (tab === "informations") {
        if (tab2 === "edit") {
          return "accountEdit";
        }
      }

      if (tab === "order") {
        // if (tab2 === "new") {
        this.TopMobileBackInfo.title = this.$t("Order Detail");
        this.TopMobileBackInfo.icon = "shopping-bag";
        return "orderDetails";
        // }
      }

      if (tab === "loyalty") {
        if (tab2 === "membership") {
          return "loyalty-membership";
        } else if (tab2 === "advantages") {
          return "loyalty-advantages";
        } else if (tab2 === "history") {
          return "loyalty-history";
        } else if (tab2 === "myrewards") {
          return "loyalty-rewards";
        }
      }
    },
  },
  mounted() {
    // // isLogin();
    // window.addEventListener("scroll", (e) => {
    //   const header = document.querySelector(".header.transparent");
    //   if (header) {
    //     if (window.scrollY > 100) {
    //       header.classList.add("light");
    //     } else {
    //       header.classList.remove("light");
    //     }
    //   }
    // });
  },
  methods: {
    isLogin() {
      try {
        this.$store.commit("user/initialiseStore", this.$session);

        if (!this.$store.state.user.userSession.user.Customer) {
          this.$router.replace("/authenticate/login");
        } else {
        }
        this.userSession = this.$store.state.user.userSession;
      } catch (error) {
        this.$router.replace("/authenticate/login");
      }
    },
  },
  components: {
    UserIcon,
    ChevronLeftIcon,
    AccountInfo,
    AccountBody,
    OrderDetails,
    TopMobileBack,
    AccountInfoEdit,
    LoyaltyHistory,
    LoyaltyMembership,
    LoyaltyPosts,
    LoyaltyMyRewards,
  },
};
