
import { Button } from "element-ui";
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    size: {
      required: true,
      type: String,
    },
    shop: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    productColor() {
      try {
        return this.product.Variant[0].Combination.Attributes.find(
          (item) => item.GroupName === "Color"
        ).AttributeName;
      } catch (error) {}
      return "";
    },
  },
  methods: {},
  mounted() {},
  components: {
    Button,
  },
};
