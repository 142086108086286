import { render, staticRenderFns } from "./CarouselImages.vue?vue&type=template&id=d48f94c2"
import script from "./CarouselImages.vue?vue&type=script&lang=js"
export * from "./CarouselImages.vue?vue&type=script&lang=js"
import style0 from "./CarouselImages.vue?vue&type=style&index=0&id=d48f94c2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZoomImagesV2: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/ZoomImagesV2.vue').default,ImageProduct: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/ImageProduct.vue').default})
