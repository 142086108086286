
import ProductCardTop from './ProductCardTop.vue';
import ProductCardSizes from './ProductCardSizes.vue';
import ProductCardRelationShip from './ProductCardRelationShip.vue';
import { ProductHelpers } from '@/helpers/ProductHelpers.js';
import ProductCardDefault from '@/components/ProductCard/ProductCardDefault.vue';

import { Button } from 'element-ui';
import { ShoppingBagIcon } from 'vue-feather-icons';

export default {
  props: {
    typeView: {
      type: 'image' | 'multiImage' | 'slider',
    },
    categoryLink: {
      type: String,
      default: '#',
    },
    product: {
      // default:() => ({})
      required: true,
      type: Object,
    },
    noRelativeProduct: {
      type: Boolean,
      default: false,
    },
    hideSizes: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    imageSize: {
      type: String,
      default: 'product_medium',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productHelpers: new ProductHelpers(this.$http, this, this.$i18n.locale),
      variant: [],
      hovered: false,
    };
  },
  computed: {
    currencySign() {
      return this.$localisation.currencySign
        ? this.$localisation.currencySign
        : '€';
    },
    title() {
      try {
        if (this.product.Description) {
          return this.product.Description.Title;
        }
      } catch (error) {}
      return 'No title';
    },
    images() {
      try {
        if (this.product.Media.Images[this.imageSize])
          return this.product.Media.Images[this.imageSize];
      } catch (error) {}

      return [];
    },
    imagesLg() {
      try {
        if (this.product.Media.Images.product_large)
          return this.product.Media.Images.product_large;
      } catch (error) {}

      return [];
    },
    price() {
      try {
        return this.product.Price;
      } catch (error) {}
      return '-0';
    },

    seo() {
      try {
        if (Array.isArray(this.product.SEO)) {
          let seo = this.product.SEO[0];
          if (this.$i18n.locale === 'en') {
            seo = this.product.SEO[1];
          }
          if (this.$i18n.locale === 'de') {
            seo = this.product.SEO[2];
          }
          return seo;
        } else {
          const seo = this.product.SEO;
          return seo;
        }
      } catch (error) {}

      return null;
    },
    hasPromtion() {
      try {
        if (this.price.PromotionalPrice) return true;
      } catch (error) {}
      return false;
    },
    regularPrice() {
      try {
        return this.price.RegularPrice;
      } catch (error) {}
      return false;
    },
    promotionalPrice() {
      try {
        return this.price.PromotionalPrice;
      } catch (error) {}
      return false;
    },

    relationshipProducts() {
      try {
        return this.product.Relationship.Child;
      } catch (error) {}
      return [];
    },
    getLink() {
      try {
        return this.localePath('/' + this.categoryLink + '/' + this.seo.URL);
      } catch (error) {}
      return '#';
    },
  },
  methods: {
    async getVariant() {
      this.hovered = true;
      if (this.variant.length === 0) {
        try {
          const variant = await this.productHelpers.getProductV2Variant({
            idProduct: this.product.IdProduct,
            languageIsoCode: this.$i18n.locale,
          });

          this.variant = variant;
        } catch (error) {}
      }
    },

    getProductUrl() {
      try {
        return this.localePath(`/v2/${this.seo.URL}/${this.product.IdProduct}`);
      } catch (error) {}

      return this.localePath('/v2/category/' + this.product.IdProduct);
    },

    onRelashionShipSelected(event) {
      this.$emit('relationShipItemSelected', { product: event.product });
    },
    showAddToCartMobile() {
      this.$refs.productCardSizes.openDrawer();
    },
  },
  mounted() {},
  components: {
    ProductCardTop,
    ProductCardSizes,
    ProductCardRelationShip,
    Button,
    ShoppingBagIcon,
    ProductCardDefault,
  },
};
