
import { Button, Popover, Drawer } from 'element-ui';
import { MapPinIcon, ClockIcon, ChevronDownIcon } from 'vue-feather-icons';
import CarriersHoraire from '@/pages/cart/components/CarriersHoraire.vue';
import SlideDownUp from '@/components/transitions/SlideDownUp.vue';
import RelaiPointCard from '@/components/RelaiPointCard.vue';
import CarrierCard from '../../../components/CarrierCard.vue';
export default {
  props: {
    listCarriers: {
      type: Array,
      required: true,
    },
    idAddress: {
      type: String | Number,
      required: true,
    },
    carrierType: {
      type: String,
    },
  },
  data() {
    return {
      carrierSelected: null,
    };
  },
  mounted() {},
  components: {
    Button,
    Popover,
    MapPinIcon,
    ClockIcon,
    Drawer,
    CarriersHoraire,
    SlideDownUp,
    ChevronDownIcon,
    RelaiPointCard,
    CarrierCard,
  },
};
