
import DefaultLayout from "./DefaultLayout.vue";
import Banner from "../components/Banner.vue";
import { Collapse, CollapseItem } from "element-ui";
import sharedMixin from "@/mixins/sharedMixin.js";
import {
  UserIcon,
  ShoppingBagIcon,
  HeartIcon,
  CornerDownRightIcon,
  CornerUpLeftIcon,
} from "vue-feather-icons";
export default {
  name: "accountLayout",
  mixins: [sharedMixin],

  components: {
    DefaultLayout,
    Banner,
    Collapse,
    CollapseItem,
    UserIcon,
    ShoppingBagIcon,
    HeartIcon,
    CornerDownRightIcon,
    CornerUpLeftIcon,
  },
  computed: {
    menuIsSticky() {
      return this.$store.state.shared.menu.sticky;
    },
  },

  mounted() {
    this.setMenuLight();
  },

  methods: {
    setMenuLightToState() {
      let isLight = false;

      if (window.scrollY > 100) {
        isLight = true;
      }
      this.$store.commit("shared/menu/setIsTransparent", isLight);
    },
    setMenuLight() {
      if (process.client) {
        window.addEventListener("scroll", (e) => {
          this.setMenuLightToState();
        });
      }
    },
  },

  updated() {},
};
