
import AccountBody from "@/pages/account/components/AccountBody.vue";
import Orders from "@/pages/account/components/Orders.vue";
import TopMobileBack from "@/pages/account/components/TopMobileBack.vue";
import ReturnDetailsContent from "@/pages/account/components/ReturnDetailsContent.vue";
import ReturnCard from "@/pages/account/components/ReturnCard.vue";
import { ShopHelper } from "/helpers/ShopHelper";
import { ShoppingBagIcon, ChevronLeftIcon } from "vue-feather-icons";
import AccountService from "@/services/AccountService";

import { Alert } from "element-ui";
export default {
  layout: "account",
  name: "returnsPage",
  // middleware: "authenticated",
  middleware: "auth",
  async asyncData(context) {
    // return {
    //   order,
    // };
  },
  data() {
    return {
      tab: "",
      TopMobileBackInfo: {
        title: "Mon compte",
        icon: "",
      },
      order: false,
    };
  },
  async mounted() {
    const idOrder = this.$route.params.id;
    // const orderHelper = new OrderHelper(this.$http, this.$store,this.$i18n.locale);
    // // const order = await orderHelper.getOrderById(idOrder);
    // const order = await orderHelper.getOrderById(idOrder);

    const accountService = new AccountService({
      auth: this.$auth,
      http: this.$http,
      store: this.$store,
    });

    this.order = await accountService.getOrder({
      idOrder,
      languageIsoCode: this.$i18n.locale,
      currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
    });

    const shopHelper = new ShopHelper(this.$http, this, this.$i18n.locale);
    shopHelper.setReturnsReasons({ languageIsoCode: this.$i18n.locale });
  },
  computed: {},
  components: {
    AccountBody,
    Orders,
    TopMobileBack,
    ReturnDetailsContent,
    ReturnCard,
    ShoppingBagIcon,
    ChevronLeftIcon,
    Alert,
  },
};
