
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getColClass() {
      switch (this.items.length) {
        case 1:
          return "col-span-12";
        case 2:
          return "col-span-12 md:col-span-6 lg:col-span-6";
        case 3:
          return "col-span-12 md:col-span-6 lg:col-span-4";
        default:
          return "col-span-12 lg:col-span-6 xl:col-span-3 cols4";
      }
    },
  },
};
