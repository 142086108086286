
import Teleport from 'vue2-teleport';
import { Button, Drawer, Form, FormItem, Input } from 'element-ui';
import { CheckIcon, InfoIcon, MailIcon } from 'vue-feather-icons';
import { ShopHelper } from '@/helpers/ShopHelper';
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    size: {
      default: null,
      type: String,
    },
    color: {
      required: true,
      type: String,
    },
    productsSimilar: {
      type: Array,
    },
    idProductAttribute: {
      required: false,
      type: Number,
    },
  },
  computed: {
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },
  },
  data() {
    return {
      drawer: false,
      step: 1,
      responsiveSlider: {
        0: {
          slidesPerView: 2.2,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 3.2,
          spaceBetween: 16,
        },
      },
      backInStore: {
        email: this.$auth.loggedIn ? this.$auth.user.Customer.Email : '',
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    open() {
      this.drawer = true;
      this.$emit('open');
    },
    async sendBackInStore() {
      const shopHelper = new ShopHelper(this.$http, this);
      try {
        const response = await shopHelper.backInStock({
          email: this.backInStore.email,
          idProduct: this.product.IdProduct,
          idProductAttribute: this.idProductAttribute,
          languageIsoCode: this.$i18n.locale,
        });
        if (response.status === 200) {
          this.step++;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async onSubmit() {
      this.$refs['backInStoreForm'].validate((valid) => {
        if (valid) {
          this.sendBackInStore();
        } else {
          return false;
        }
      });
    },
  },
  components: {
    Button,
    Drawer,
    Form,
    FormItem,
    Input,
    InfoIcon,
    MailIcon,
    CheckIcon,
    Teleport,
  },
};
