
import {
  Progress,
  Tabs,
  TabPane,
  Button,
  Select,
  Option,
  Checkbox,
} from 'element-ui';
import {
  PackageIcon,
  CreditCardIcon,
  HomeIcon,
  MapPinIcon,
  PlusIcon,
  ClockIcon,
  ChevronLeftIcon,
} from 'vue-feather-icons';
import CartItems from '@/pages/cart/components/CartItems.vue';
import AddressDeliveryInvoice from '@/pages/cart/components/AddressDeliveryInvoice.vue';
import TotalOrder from '@/pages/cart/components/TotalOrder.vue';
import CardInfo from '@/pages/cart/components/CardInfo.vue';
import DeliverySelected from '@/pages/cart/components/DeliverySelected.vue';
import TunnelOrderLayout from './TunnelOrderLayout.vue';
import AddessForm from '../../account/components/AddessForm.vue';
import DeliveryTabs from '@/pages/cart/components/DeliveryTabs.vue';
import MiniCart from '@/pages/cart/components/MiniCart.vue';
import ErrorsMsgs from '../../../components/ErrorsMsgs.vue';
import addressMixin from '@/mixins/addressMixin.js';
import carriersMixin from '@/mixins/carriersMixin.js';
import cartMixin from '@/mixins/cartMixin.js';
import RegisterFormInfoGuest from '@/pages/authenticate/components/RegisterFormInfoGuest.vue';

export default {
  mixins: [addressMixin, carriersMixin, cartMixin],
  data() {
    return {
      valide: true,
      addressesError: false,
      optioDeliveryError: false,
      showDelivery: true,
      isOpenAddAddress: false,
    };
  },
  computed: {
    carrier() {
      return this.cart?.Shipping?.Carrier;
    },
    guestUser() {
      return this.$store.state.user.guest.guestUser;
    },

    isLogin() {
      return this.$auth.loggedIn || this.guestUser;
    },
  },
  created() {
    if (this.isLogin) {
      this.fetchAddresses();
    }
  },
  mounted() {},
  methods: {
    validateAddress($event) {
      this.valide = $event.valide;
    },
    fetchAddresses() {
      this.addressService.fetchAddresses({
        onSuccess: (data) => {
          this.isOpenAddAddress = this.addresses?.length === 0;
          this.carriersService.fetchCarrierTypes({
            idAddress: this.idAddressDelivery,
            onSuccess: (data) => {
              if (this.carrierTypes) {
                this.carriersService.fetchCarriers({
                  idAddress: this.idAddressDelivery,
                  carrierType: this.carrierTypes[0],
                  responseLevel: 'details',
                });
                
                this.$store.commit(
                  'carriers/setActiveTab',
                  this.carrierTypes[0]
                );
              }
            },
          });
        },
      });
    },
    addressValidate() {
      if (this.$refs['addressDeliveryInvoiceRef']) {
        const isOk = this.$refs['addressDeliveryInvoiceRef'].valide;
        this.addressesError = !isOk;
        return isOk;
      }
      return false;
    },
    async next() {
      let isOk = true;
      this.optioDeliveryError = false;
      this.addressesError = false;

      if (!this.addressValidate()) {
        isOk = false;
        this.addressesError = true;
      }

      if (!this.carrier) {
        isOk = false;
        this.optioDeliveryError = true;
      }

      if (isOk) {
        this.$router.push(this.localePath('/cart/payment'));
      }
    },
    scrollTo(ref) {
      window.scrollTo({
        top: this.$refs[ref].offsetTop - 146,
        behavior: 'smooth',
      });
    },
    fetshCarriers() {
      this.carriersService.fetchCarrierTypes({
        idAddress: this.idAddressDelivery,
        onSuccess: (data) => {
          if (this.carrierTypes) {
            this.carriersService.fetchCarriers({
              idAddress: this.idAddressDelivery,
              carrierType: this.carrierTypes[0],
              responseLevel: 'details',
            });
            this.$store.commit('carriers/setActiveTab', this.carrierTypes[0]);
            this.isOpenAddAddress = false;
          }
        },
      });
    },
  },
  components: {
    CartItems,
    PackageIcon,
    CreditCardIcon,
    PlusIcon,
    HomeIcon,
    MapPinIcon,
    Progress,
    Tabs,
    TabPane,
    Button,
    TotalOrder,
    CardInfo,
    TunnelOrderLayout,
    AddessForm,
    ClockIcon,
    Select,
    Option,
    Checkbox,
    AddressDeliveryInvoice,
    DeliverySelected,
    DeliveryTabs,
    MiniCart,
    ErrorsMsgs,
    ChevronLeftIcon,
    RegisterFormInfoGuest,
  },
};
