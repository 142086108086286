
export default {
    props: {
        imgsrc: {
            // default:() => ({})
            required: true,
            type: String,
        },

        text: {
            required: true,
            type: String,
        },
    },
}
