
export default {
  props: {
    colorUrls: {
      type: String,
      default: null,
    },
    categoryUrl: {
      type: String,
      default: null,
    },
    products: {
      type: Array,
    },
    defaultProduct: {
      type: Object,
    },
    productSelectedId: {
      type: String,
      required: false,
    },
    goToProductLink: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  mounted() {},
  methods: {
    getProductTitle(product) {
      try {
        if (product != null) {
          return product.SEO.MetaTitle;
        }
      } catch (error) {}

      return "";
    },
    getProductImage(product) {
      try {
        if (product.Media) {
          if (product.Media.Images.product_xsmall) {
            const imgurl = product.Media.Images.product_xsmall[0].Src;
            return imgurl;
          }
        }

        if (product.ProductImageUrl) {
          const imgurl = product.ProductImageUrl;
          return imgurl;
        }

        return require(`~/assets/img/default-img.jpg`);
      } catch (error) {
        return require(`~/assets/img/default-img.jpg`);
      }
    },
    colorClick(product) {
      this.$emit("onColorClick", product);
    },

    urlCategory(product) {
      try {
        const seo = product.SEO;
        if (seo) {
          const mainCategory = seo.MainCategory.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode;
          });
          if (!mainCategory) {
            return "product";
          }
          if (mainCategory.CategoryDescriptionFriendlyURL) {
            return mainCategory.CategoryDescriptionFriendlyURL;
          }
        }
      } catch (error) {}
      return "product";
    },

    urlProduct(product) {
      try {
        const seo = product.SEO;
        if (seo) {
          // const productSeo = seo.Product.find((item) => {
          //   return this.$i18n.locale === item.LanguageIsoCode;
          // });

          // if (productSeo.ProductDescriptionFriendlyURL) {
          //   return productSeo.ProductDescriptionFriendlyURL;
          // }
          return product.SEO.URL;
        }
      } catch (error) {}
      return this.product.IdProduct;
    },

    link(product) {
      try {
        return this.localePath(
          `/${this.categoryUrl}/${this.urlProduct(product)}`
        );
      } catch (error) {}

      return this.localePath(`/product/${product.IdProduct}`);
    },
  },
};
