
import CartItems from "./CartItems.vue";
import cartMixin from "@/mixins/cartMixin.js";
export default {
  mixins: [cartMixin],
  data() {
    return {
    };
  },
  computed: {
    allItems() {
      return this.cart?.Products;
    },

    totalProduct() {
      return this.cart?.Total?.Product?.TaxIncl;
    },
    currencySign() {
      return this.$localisation.currencySign;
    },
  },
  components: {
    CartItems,
  },
};
