
import { ChevronLeftIcon } from "vue-feather-icons";
import AddessForm from "./AddessForm.vue";
export default {
  methods: {
    onAddressFormSubmit() {
      let redirectTo = "/account/address";
      
      if (this.$route.query.redirect) {
        redirectTo = "/cart/delivery";
      }

      this.$router.push({
        path: this.localePath(redirectTo),
      });
    },
  },
  components: {
    ChevronLeftIcon,
    AddessForm,
  },
};
