
import { ClockIcon, InfoIcon } from "vue-feather-icons";
import { Button } from "element-ui";

export default {
  props: {
    reward: {
      type: Object,
    },
    rewardReponse: {
      type: Object,
    },
  },
  data() {
    return {
      animeCopy: false,
    };
  },
  methods: {
    copyPromoCode(text) {
      if (process.client) {
        navigator.clipboard.writeText(text);
        this.animeCopy = true;
        setTimeout(() => {
          this.animeCopy = false;
        }, 1000);
      }
    },
  },
  components: {
    InfoIcon,
    Button,
    ClockIcon,
  },
};
