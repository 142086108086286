
//  http://localhost:3000/en/account/autologin?token=!oPWwl$u2HGU8J6IZfc5z7NFYSAK3k&email=kazadiabondance@gmail.com&redirect=https://dev.jeanmarcphilippe.com/en/account/loyalty

import { CustumerHelpers } from '@/helpers/CustumerHelpers';
import CustomerService from '@/services/CustomerService';

export default {
  layout: 'MainLayout',
  name: 'AutoLoginPage',
  data() {
    return {
      customerService: new CustomerService({
        http: this.$http,
        auth: this.$auth,
        store: this.$store,
      }),
    };
  },
  created() {
    const { token, email, redirect } = this.$route.query;
    // this.$router.replace({
    //   query: {},
    // });

    const custumerHelpers = new CustumerHelpers(this.$http, this.$auth);
    // this.rdirectTo();

    if (token && email) {
      this.customerService
        .loginAuto({
          email,
          token,
        })
        .then((data) => {
          this.redirectTo();
        })
        .catch((err) => {
          this.$router.push({
            path: this.localePath('/'),
          });
        });
    }
  },
  methods: {
    redirectTo() {
      const redirect = this.$route.query.redirect;
      let goTo = this.$t('account_profil');
      if (redirect) {
        const path = new URL(redirect).pathname;
        // if (this.$route.query.redirect === 'loyalty-program') {
        //   redirectTo = `/account/loyalty/membership`;
        // }
        goTo = path;
      } else {
        goTo = '/cart/delivery';
      }
      this.$router.push({
        path: this.localePath(goTo),
      });
    },
  },
};
