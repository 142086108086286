
export default {
  data() {
    return {};
  },
  computed: {
    items() {
      return [
        {
          text: this.$t('Our history ; 40 years of fashion'),
          link: this.localePath('/marque'),
          image: require('@/assets/img/menu/universe/history.jpg'),
        },
        {
          text: this.$t("In line with women's curves"),
          link: this.localePath('/marque'),
          image: require('@/assets/img/menu/universe/fashion.jpg'),
        },
        {
          text: this.$t('Creation with audacity and passion'),
          link: this.localePath('/marque'),
          image: require('@/assets/img/menu/universe/creation.jpg'),
        },
        {
          text: this.$t('Responsible manufacturing in France'),
          link: this.localePath('/marque'),
          image: require('@/assets/img/menu/universe/fabrication.jpg'),
        },
        {
          text: this.$t('Our news in the newspaper'),
          link: this.localePath('/blogs'),
          image: require('@/assets/img/menu/universe/journal.jpg'),
        },
      ];
    },
  },
  methods: {},
};
