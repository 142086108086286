
import { Button, Checkbox } from 'element-ui';
import hipayMixin from '../hipayMixin';
export default {
  mixins: [hipayMixin],
  props: {
    paymentMethod: {
      type: Object,
    },
  },
  data() {
    return {
      generalConditionsSale: false,
    };
  },
  methods: {
    submit() {
      this.startPayment({
        paymentData: { payment_product: 'sofort-uberweisung' },
        paymentMethod: this.paymentMethod,
      });
    },
  },
  components: {
    Button,
    Checkbox,
  },
};
