
import StepCart from "@/pages/cart/components/StepCart.vue";
import CartMain from "./components/CartMain.vue";
import HipayPayment from "@/services/payments/HipayPayment";
import cartMixin from "@/mixins/cartMixin.js";
import addressMixin from "@/mixins/addressMixin.js";

export default {
  layout: "tunnel",
  name: "CartPage",
  auth: false,

  mixins: [cartMixin, addressMixin],
  data() {
    return {};
  },
  computed: {
    hipayPayment() {
      return new HipayPayment({
        cart: this.cart,
        auth: this.$auth,
        addressService: this.addressService,
      });
    },
  },
  async mounted() {
    this.cartService.fetchCart();
  },
  components: {
    StepCart,
    CartMain,
  },
};
