
import { BookmarkIcon } from "vue-feather-icons";
import { Button } from "element-ui";
import addressMixin from "@/mixins/addressMixin";

export default {
  mixins: [addressMixin],
  data() {
    return {
      isUpdatingDelivery: false,
      isUpdatingInvoice: false,
    };
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
    noborder: {
      type: Boolean,
      default: false,
    },
    updateBtn: {
      type: Boolean,
      default: false,
    },
    optionsBtns: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    setAddressDelviryDefault() {
      this.isUpdatingDelivery = true;
      this.addressService
        .updateAddressType({
          idAddress: this.address.IdAddress,
          isInvoice: this.address.IsInvoice,
          isDelivery: true,
        })
        .then((data) => {
          this.addressService.fetchAddresses();
        })
        .finally(() => {
          this.isUpdatingDelivery = false;
        });
    },
    setAddressInvoiceDefault() {
      this.isUpdatingInvoice = true;
      this.addressService
        .updateAddressType({
          idAddress: this.address.IdAddress,
          isInvoice: true,
          isDelivery: this.address.IsDelivery,
        })
        .then((data) => {
          this.addressService.fetchAddresses();
        })
        .finally(() => {
          this.isUpdatingInvoice = false;
        });
    },
  },
  components: {
    BookmarkIcon,
    Button,
  },
};
