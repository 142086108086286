
import { UserIcon, ChevronLeftIcon } from "vue-feather-icons";
import Loyalty from "@/pages/account/components/Loyalty.vue";
import AccountBody from "@/pages/account/components/AccountBody.vue";
import Orders from "@/pages/account/components/Orders.vue";
import TopMobileBack from "@/pages/account/components/TopMobileBack.vue";
export default {
  layout: "account",
  name: "accountPage",
  // middleware: "authenticated",
  middleware: "auth",
  data() {
    return {
      tab: "",
      TopMobileBackInfo: {
        title: "Mon compte",
        icon: "",
      },
    };
  },
  components: {
    UserIcon,
    ChevronLeftIcon,
    AccountBody,
    Orders,
    TopMobileBack,
    Loyalty,
  },
  mounted() {
    // window.addEventListener("scroll", (e) => {
    //   const header = document.querySelector(".header.transparent");
    //   if (header) {
    //     if (window.scrollY > 100) {
    //       header.classList.add("light");
    //     } else {
    //       header.classList.remove("light");
    //     }
    //   }
    // });
    this.tab = this.$route.params.tab;

    if (this.tab === "favorites") {
      this.TopMobileBackInfo.title = this.$t("My favorites");
      this.TopMobileBackInfo.icon = "heart";
    }
    if (this.tab === "loyalty") {
      this.TopMobileBackInfo.title = this.$t("Loyalty");
      this.TopMobileBackInfo.icon = "heart";
    }
  },
};
