
import Menu from "@/components/Menu.vue";
import ProductNotAvailable from "@/components/products/ProductNotAvailable.vue";
import { Collapse, CollapseItem } from "element-ui";
import Reasurances from "@/components/Reasurances.vue";
import BlockSlider2 from "@/components/BlockSlider2.vue";
import ZoomImagesV2 from "@/components/ZoomImagesV2.vue";
import { Button } from "element-ui";
import { CartHelper } from "@/helpers/CartHelper.js";
import { ProductHelpers } from "@/helpers/ProductHelpers.js";
import { FormatDate } from "@/helpers/FormatDate.js";
import { MapPinIcon, PackageIcon } from "vue-feather-icons";
import { LocalisationHelper } from "@/helpers/LocalisationHelper";

const permalinkLangUrl = async (product, store) => {
  const links = {};

  if (product.SEO) {
    product.SEO.MainCategory.map((item, index) => {
      const productUrl = product.SEO.Product[index]
        ? product.SEO.Product[index].DescriptionFriendlyURL
        : "product";

      links[item.LanguageIsoCode] = {
        cat: item.CategoryDescriptionFriendlyURL,
        product: productUrl,
      };
    });
    await store.dispatch("i18n/setRouteParams", links);
  }
};

export default {
  layout: "MainLayout",
  name: "ProductPage",
  auth: false,
  head() {
    return {
      title: this.productTitle,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("home_meta_discription"),
        },
      ],
    };
  },
  async asyncData(context) {
    const permalink = context.params.product;
    const productHelpers = new ProductHelpers(context.$http, context);
    let product = null;
    try {
      const response = await productHelpers.getProductV2({ permalink });
      if (response.status === 200) {
        product = response.data;
      }
    } catch (error) {}

    // if (product.Reference === null) {
    //   return context.error({
    //     statusCode: 404,
    //     message: context.app.i18n.t(
    //       "the product you requested no longer exists"
    //     ),
    //   });
    // }

    if (product) {
      // await permalinkLangUrl(product, context.store);

      return {
        product,
      };
    } else {
      return {
        product: null,
      };
    }
  },

  data() {
    return {
      title2: "My new product",
      // product: null,
      sizeSelected: null,
      idProductAttributeSelected: null,
      colorSelected: null,
      isSelectedSize: true,
      taillesIsOpen: false,
      productAssoci: [],
      imageZoom: {
        startImgIndex: 0,
        isZooming: false,
      },
      isAvailbale: true,
      productsSimilar: [],
    };
  },
  computed: {
    tel() {
      if (this.$i18n.locale === "fr") {
        return "01.85.14.62.85";
      }

      return "+33.1.85.14.62.85";
    },
    getProductImgs() {
      try {
        if (this.product.Media) {
          return this.product.Media.Images;
        }
      } catch (error) {}

      return {};
    },
    getProductVideos() {
      try {
        if (this.product.Media) {
          return this.product.Media.Videos;
        }
      } catch (error) {}

      return [];
    },

    productTitle() {
      if (this.product != null) {
        try {
          if (this.$i18n.locale === "en") {
            return this.product.Description[1].Title;
          }
          if (this.$i18n.locale === "de") {
            return this.product.Description[2].Title;
          }
          return this.product.Description[0].Title;
        } catch (error) {}
      }
      return "";
    },

    productDescription() {
      if (this.product != null) {
        try {
          if (this.$i18n.locale === "en") {
            return this.product.Description[1].Description;
          }
          if (this.$i18n.locale === "de") {
            return this.product.Description[2].Description;
          }
          return this.product.Description[0].Description;
        } catch (error) {}
      }
      return "";
    },
    productDescriptionShort() {
      if (this.product != null) {
        try {
          if (this.$i18n.locale === "en") {
            return this.product.Description[1].DescriptionShort;
          }
          if (this.$i18n.locale === "de") {
            return this.product.Description[2].DescriptionShort;
          }
          return this.product.Description[0].DescriptionShort;
        } catch (error) {}
      }
      return "";
    },
    productColor() {
      try {
        return this.product.Variant[0].Combination.Attributes.find(
          (item) => item.Group === "Color"
        ).Attribute;
      } catch (error) {}
      return null;
    },
    getproductsSimilar() {
      if (Array.isArray(this.productsSimilar)) {
        return this.productsSimilar;
      }
      return [];
    },
    promotion() {
      let price = this.product.Price;
      return price.PromotionalPrice;
    },
    promotionPercentage() {
      const price = Number(this.productPrice);
      const pricePromotion = Number(this.productPricePromotion);

      return ((1 - pricePromotion / price) * 100).toFixed(0);
    },
    productPricePromotion() {
      return this.$productPrice(this.promotion.PriceTaxExcl);
    },
    productPrice() {
      let price = this.product.Price;

      return this.$productPrice(price.RegularPrice.PriceTaxExcl);
    },
    currencySign() {
      return this.$localisation.currencySign;
    },
    variants() {
      const variants = this.product.Variant;
      return variants;
    },
    breadcrumbLinks() {
      const links = [
        {
          link: "/",
          text: this.$t("Home"),
        },
      ];
      if (this.mainCatgoryTitle) {
        links.push({
          link: this.localePath(`/${this.urlCategory}`),
          text: this.mainCatgoryTitle,
        });
      }

      links.push({
        text: this.productTitle,
      });
      return links;
    },
    productsVisited() {
      return this.$store.state.products_visited.products;
    },
    mainCatgoryTitle() {
      try {
        const seo = this.product.SEO;
        if (seo) {
          const mainCategory = seo.MainCategory.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode;
          });
          if (!mainCategory) {
            return null;
          }
          if (mainCategory.CategoryTitle) {
            return mainCategory.CategoryTitle;
          }
        }
      } catch (error) {}
      return null;
    },
    urlCategory() {
      try {
        const seo = this.product.SEO;
        if (seo) {
          const mainCategory = seo.MainCategory.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode;
          });
          if (!mainCategory) {
            return "product";
          }
          if (mainCategory.CategoryDescriptionFriendlyURL) {
            return mainCategory.CategoryDescriptionFriendlyURL;
          }
        }
      } catch (error) {}
      return "product";
    },
    Delivery() {
      if (this.product.Delivery) {
        return this.product.Delivery;
      }
      return null;
    },
    dateOfDeilvery() {
      if (this.Delivery === null) {
        return false;
      }
      if (this.Delivery.Time) {
        const formatDate = new FormatDate();
        if (this.Delivery.Time.From) {
          const dateMin = formatDate.date(
            this.covertDate(this.Delivery.Time.From)
          );
          const dateMax = formatDate.date(
            this.covertDate(this.Delivery.Time.To)
          );
          return {
            dateMin,
            dateMax,
          };
        } else {
          const dateMax = formatDate.date(
            this.covertDate(this.Delivery.Time.To)
          );
          return {
            dateMax,
          };
        }
      }

      return false;
    },

    countryDelivery() {
      try {
        const localisationHelper = new LocalisationHelper(
          this.$http,
          this.$store,
          this.$auth
        );

        return localisationHelper.getAppCountry()
          ? localisationHelper.getAppCountry()
          : "";
      } catch (error) {}

      return "";
    },
  },

  methods: {
    covertDate(inputDate) {
      const date = new Date(inputDate);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = date.getFullYear();

      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    },
    getCombination(variant) {
      try {
        const size = variant.Combination;
        return size;
      } catch (error) {}
      return null;
    },
    getSize(combination) {
      try {
        const size = combination.Attributes.find(
          (attribute) => attribute.Group === "Size"
        );
        return size;
      } catch (error) {}
      return null;
    },
    toggleAddToCart() {
      const refs = this.$refs;
      let taillesWrapper = refs.tailles;
      let btnAddWrapper = refs.btnAdd;

      if (this.taillesIsOpen) {
        this.taillesIsOpen = false;
      } else {
        this.taillesIsOpen = true;
      }
    },
    addToCart() {
      if (process.client) {
        if (this.$SETTINGS.isMobile) {
          this.toggleAddToCart();
        } else {
          this.isSelectedSize = this.sizeSelected !== null;
          if (this.isSelectedSize) {
            if (this.$auth.loggedIn) {
              this.setProductToCart();
            } else {
              this.setProductToCartGuest();
            }
          }
        }
      }
    },
    outSideClick(e) {},
    showZoom(i) {
      this.imageZoom.isZooming = true;
      this.imageZoom.startImgIndex = i;
    },
    selectSize(variant) {
      const combination = variant.Combination;
      const attr = this.getSize(combination);

      this.idProductAttributeSelected = combination.IdProductAttribute;
      this.colorSelected = attr.Attribute.Color;
      this.sizeSelected = attr.Attribute.Size;
      this.isSelectedSize = true;

      this.isAvailbale = variant.Quantity > 0;

      if (process.client) {
        if (this.$SETTINGS.isMobile) {
          if (this.isSelectedSize) {
            if (this.isAvailbale) {
              if (this.$auth.loggedIn) {
                this.setProductToCart();
              } else {
                this.setProductToCartGuest();
              }
            } else {
              this.$refs["ProductNotAvailable"].open();
            }
          }
        }
      }
    },
    clearPanier() {
      this.$store.commit("shop/cart/clear", this.$auth);
    },
    setProductToCart() {
      const cartHelper = new CartHelper(this.$http, this.$store, this.$auth);

      cartHelper.addProductToCart({
        product: this.product,
        color: this.colorSelected,
        size: this.sizeSelected,
        idProductAttribute: this.idProductAttributeSelected,
      });

      this.$gtag.event("Add to cart", {
        event_category: "Cart",
        event_label: "Product : " + this.productTitle,
        value: "Id product : " + this.product.IdProduct,
      });

      fbq("track", "InitiateCheckout", {
        content_ids: [this.product.IdProduct + "-" + this.productTitle],
        eventref: "", // or set to empty string
      });
    },
    setProductToCartGuest() {
      const cartHelper = new CartHelper(this.$http, this.$store, this.$auth);

      cartHelper.addProductToCartGuest({
        product: this.product,
        color: this.colorSelected,
        size: this.sizeSelected,
        quantity: 1,
        idProductAttribute: this.idProductAttributeSelected,
      });

      this.$gtag.event("Add to cart", {
        event_category: "Cart",
        event_label: "Product : " + this.productTitle,
        value: "Id product : " + this.product.IdProduct,
      });

      fbq("track", "InitiateCheckout", {
        content_ids: [this.product.IdProduct + "-" + this.productTitle],
        eventref: "", // or set to empty string
      });
    },
    openBookingDialog() {
      this.$refs["bookingDialog"].openDialog();
    },
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      if (this.taillesIsOpen) {
        this.taillesIsOpen = false;
        this.isAvailbale = true;
      }
    },
    async getproductsSimilarApi() {},

    openLangueDialog() {
      this.$store.commit("shop/localisation/toggleDialog", true);
    },

    loadProductSimilar() {
      const productHelpers = new ProductHelpers(this.$http, this);
      const permalink = this.$route.params.product;
      productHelpers
        .getProductstSimilar(permalink)
        .then((response) => {
          this.productsSimilar = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  mounted() {
    // this.$store.commit("products_visited/init", {
    //   $auth: this.$auth,
    // });
    // this.$store.commit("products_visited/addProduct", {
    //   product: this.product,
    //   $auth: this.$auth,
    // });
  },
  created() {
    if (process.client) {
      window.addEventListener("scroll", this.handleScroll);
    }

    this.loadProductSimilar();
  },
  unmounted() {
    if (process.client) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  },
  components: {
    Menu,
    Collapse,
    CollapseItem,
    Reasurances,
    BlockSlider2,
    ZoomImagesV2,
    Button,
    MapPinIcon,
    ProductNotAvailable,
    PackageIcon,
  },
};
