
import { ChevronLeftIcon } from "vue-feather-icons";
export default {
  props: {
    hideTextOnMobile: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    lastCat() {
      try {
        const LAST_CATEGORY_VISITED = "LAST_CATEGORY_VISITED";
        const lastCat = this.$cookies.get(LAST_CATEGORY_VISITED);

        if (lastCat) {
          return lastCat;
        }
      } catch (error) {}
      return this.localePath("/");
    },
    // continueMyShopping() {
    //   this.$router.push({
    //     path: this.lastCat(),
    //   });
    // },
  },
  components: {
    ChevronLeftIcon,
  },
};
