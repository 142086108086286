import { render, staticRenderFns } from "./_tab2.vue?vue&type=template&id=f580013e"
import script from "./_tab2.vue?vue&type=script&lang=js"
export * from "./_tab2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports