
export default {
  props: {
    links: {
      type: Array,
      default: () => [
        {
          link: "/",
          text: "Accueil",
        },
        {
          link: "/collection",
          text: "Collection",
        },
        {
          text: "Robe grande taille pour femme",
        },
      ],
    },
    light: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Boolean,
      default: false,
    },
  },
};
