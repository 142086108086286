
export default {
  props: {
    colors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      colorsSelected: [],
    };
  },
  methods: {
    addcolor(color) {
      if (this.iscolorselected(color)) {
        // let index = this.colorsSelected.findIndex(
        //   (item) => item.IdAttribute === color.IdAttribute
        // );
        this.colorsSelected = this.colorsSelected.filter((item) => {
          return item.IdAttribute !== color.IdAttribute;
        });
      } else {
        this.colorsSelected.push(color);
      }

      this.$emit("onSelected", { colors: this.colorsSelected });
    },
    iscolorselected(color) {
      return (
        this.colorsSelected.find(
          (item) => item.IdAttribute === color.IdAttribute
        ) !== undefined
      );
    },
    setColorSelected(color) {
      this.colorsSelected.push(color);
    },
    initFilter() {
      const query = this.$route.query;
      let idAttributes = [];
      if (query.attributes) {
        idAttributes = query.attributes.split(",");
      }

      idAttributes.map((item) => {
        const hasColor = this.colors.find((color) => {
          if (color) {
            return item === color.IdAttribute;
          }
          return false;
        });
        if (hasColor !== undefined) {
          this.colorsSelected.push(hasColor);
        }
      });
    },
    clear() {
      this.colorsSelected = [];
    },
  },
  mounted() {
    this.colorsSelected = [];
    this.initFilter();
    this.$emit("onSelected", { colors: this.colorsSelected });
  },
};
