
import { Button, Popover } from "element-ui";
import { MapPinIcon, ClockIcon } from "vue-feather-icons";
import CarriersHoraire from "@/pages/cart/components/CarriersHoraire.vue";
export default {
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    shop: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onClick() {
      this.$emit("click", this);
    },
  },
  computed: {
    disabled() {
      return Number(this.shop.Quantity) === 0;
    },
  },
  components: {
    MapPinIcon,
    ClockIcon,
    Button,
    Popover,
    CarriersHoraire,
  },
};
