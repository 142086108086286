
import { CustumerHelpers } from "@/helpers/CustumerHelpers";
export default {
  data() {
    return {
      custumerHelpers: new CustumerHelpers(this.$http, this.$auth),
    };
  },
  created() {
    try {
      this.getLastBoughtProducts();
    } catch (error) {}
  },
  methods: {
    async getLastBoughtProducts() {
      try {
        const products =
          this.$store.state.user.customerService.lastBoughtProducts;

        if (products.length === 0) {
          const lastBoughtProducts =
            await this.custumerHelpers.lastBoughtProducts(0, 8);
          this.$store.commit(
            "user/customerService/init",
            lastBoughtProducts.Products
          );
        }
      } catch (error) {
      }
    },
  },
};
