
export default {
  props: {
    sizes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sizesSelected: [],
    };
  },

  computed: {},
  methods: {
    addSize(size) {
      if (this.isSizeSelected(size)) {
        let index = this.sizesSelected.indexOf(size);
        this.sizesSelected.splice(index, 1);
      } else {
        this.sizesSelected.push(size);
      }

      this.$emit("onSelected", { sizes: this.sizesSelected });
    },
    isSizeSelected(size) {
      return this.sizesSelected.find(
        (item) => item.IdAttribute === size.IdAttribute
      );
    },
    setSizeSelected(size) {
      this.sizesSelected.push(size);
    },

    initFilter() {
      const query = this.$route.query;
      let idAttributes = [];
      if (query.attributes) {
        idAttributes = query.attributes.split(",");
      }

      idAttributes.map((item) => {
        const hasSize = this.sizes.find((size) => {
          if (size) {
            return item === size.IdAttribute;
          }
          return false;
        });
        if (hasSize !== undefined) {
          this.sizesSelected.push(hasSize);
        }
      });
    },
    clear() {
      this.sizesSelected = [];
    },
  },
  mounted() {
    this.sizesSelected = [];
    this.initFilter();
    this.$emit("onSelected", { sizes: this.sizesSelected });
  },
};
