
import { Slider } from "element-ui";

export default {
  components: { Slider },
  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    defaultMin: {
      type: Number,
    },
    defaultMax: {
      type: Number,
    },
  },
  data() {
    return {
      value: [
        this.defaultMin ? this.defaultMin : this.min,
        this.defaultMax ? this.defaultMax : this.max,
      ],
    };
  },
  computed: {
    spanMinLeft() {
      return ((this.value[0] - this.min) / (this.max - this.min)) * 100 + "%";
    },
    spanMaxLeft() {
      return ((this.value[1] - this.min) / (this.max - this.min)) * 100 + "%";
    },
    currencySign() {
      return this.$localisation.currencySign;
    },
  },
  methods: {
    clear() {
      this.value = [this.min, this.max];
    },
  },
};
