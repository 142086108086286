
export default {
  props: {
    title: {
      type: String,
    },
    article: {
      type: Object,
    },
  },
};
