
import { Input, Button, Form, FormItem } from "element-ui";
import { PasswordHelper } from "@/helpers/PasswordHelper.js";
export default {
  data() {
    return {
      resetUser: {
        email: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t("Required Field"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs["resetUser"].validate((valid) => {
        if (valid) {
          this.sentMAil();
        } else {
          return false;
        }
      });
    },
    async sentMAil() {
      const passwordHelper = new PasswordHelper(this.$http, this.$store);
      try {
        const response = await passwordHelper.sentMail(this.resetUser.email, this.$i18n.locale );

        if (response.status === 200) {
          this.$router.push(this.localePath("/password/validate"));
        } else if (response.status === 400) {
          this.$message.error(this.$t(response.data.Message));
        } else {
          this.$message.error(this.$t("There is something not right"));
        }
      } catch (error) {
      }
    },
  },
  components: {
    Input,
    Button,
    Form,
    FormItem,
  },
};
