
import { XIcon, PlayIcon } from "vue-feather-icons";

// const imgUrls = [
//   "https://cdn.digipart.fr/upload/images/retouched/4420-REGIA1009-MARINE-6.jpg",
//   "https://cdn.digipart.fr/upload/images/retouched/4325-CALOU102-ECRU-GROSPOIS-56402.jpg",
//   "https://cdn.digipart.fr/upload/images/retouched/4325-CALOU102-ECRU-GROSPOIS-56401.jpg",
// ];
export default {
  props: {
    imgUrls: {
      required: false,
    },
    hide: {
      type: Boolean,
    },
    startImgIndex: {
      type: Number,
    },
    videoUrl: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      imgSrc: "",
      isImg: true,
    };
  },
  components: {
    XIcon,
    PlayIcon,
  },
  methods: {
    setBgImagePos($e) {
      const bigImg = this.$refs.bigImg;
      const y = $e.clientY;
      const h = bigImg.clientHeight;
      bigImg.style.backgroundPositionY = (y / h) * 100 + "%";
    },
    changeUrlImg(imgUrl, type) {
      if (type === "video") {
        this.isImg = false;
        // this.imgSrc = imgUrl;
      } else {
        // const bigImg = this.$refs.bigImg;
        // bigImg.style.backgroundImage = `url(${imgUrl})`;
        this.imgSrc = imgUrl;
        this.isImg = true;
      }
    },
    close() {
      this.$emit("onClose");
    },
  },
  mounted() {
    if (this.startImgIndex === this.imgUrls.length) {
      this.isImg = false;
      this.imgSrc = this.videoUrl;
    } else {
      this.isImg = true;
      this.imgSrc = this.imgUrls[this.startImgIndex].Url;
    }
  },
};
