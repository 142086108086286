
import { MessageSquareIcon } from "vue-feather-icons";
import { Button } from "element-ui";
import AccountBody from "@/pages/account/components/AccountBody.vue";
import TopMobileBack from "@/pages/account/components/TopMobileBack.vue";
import AccountContentCard from "@/pages/account/components/AccountContentCard.vue";
import MessageBubble from "@/pages/account/components/MessageBubble.vue";
import { CustumerHelpers } from "@/helpers/CustumerHelpers";
import MessageTicketForm from "@/pages/account/components/MessageTicketForm.vue";
export default {
  layout: "account",
  name: "serviceAccountPage",
  // middleware: "authenticated",
  middleware: "auth",
  data() {
    return {
      custumerHelpers: new CustumerHelpers(this.$http, this.$auth),

      ticket: null,
    };
  },
  computed: {
    product() {
      try {
        return this.ticket.Product;
      } catch (error) {
        return null;
      }
    },
    image() {
      try {
        return this.product.productImages.product_small[0].Url;
      } catch (error) {
        return require(`~/assets/img/default-img.jpg`);
      }
    },
    productTitle() {
      if (this.product) {
        if (this.$i18n.locale === "en") {
          return this.product.Title[1].Title;
        } else if (this.$i18n.locale === "de") {
          return this.product.Title[2].Title;
        }
        return this.product.Title[0].Title;
      }
      return "";
    },
  },
  methods: {
    async loadMessage() {
      try {
        this.ticket = await this.custumerHelpers.listMessageTicket(
          this.$route.params.idTickets
        );
      } catch (error) {}
    },
    async sendMessage(message) {
      const data = {
        IdTicket: this.$route.params.idTickets,
        Message: message,
      };
      try {
        const response = await this.custumerHelpers.sendMessage(data);
        if (response.Success) {
          this.$refs["messageTicketForm"].resetForm();
          this.loadMessage();
        }
      } catch (error) {}
    },
  },
  created() {
    this.loadMessage();
  },
  components: {
    AccountBody,
    TopMobileBack,
    MessageSquareIcon,
    AccountContentCard,
    MessageBubble,
    MessageTicketForm,
    Button,
  },
};
