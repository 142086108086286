
import CartMain from './components/CartMain.vue';
import StepPayment from './components/StepPayment.vue';
import cartMixin from '@/mixins/cartMixin.js';
import CartEmptyError from '@/pages/cart/components/CartEmptyError.vue';

export default {
  layout: 'tunnel',
  name: 'payementCartPage',
  mixins: [cartMixin],

  created() {
    this.loadedCart = false;
    this.cartService.fetchCart({
      onSuccess: () => {
        const listItemLocal = this.cart?.Total?.Product?.Quantity;
        if (listItemLocal !== 0) {
          this.hasProduct = true;
        }
        this.loadedCart = true;
      },
      onError: () => {
        this.loadedCart = true;
      },
    });
  },
  computed: {
    guestUser() {
      return this.$store.state.user.guest.guestUser;
    },

    isLogin() {
      return this.$auth.loggedIn || this.guestUser;
    },
  },
  data() {
    return {
      loadedCart: false,
      hasProduct: false,
    };
  },
  components: {
    CartMain,
    StepPayment,
    CartEmptyError,
  },
  methods: {
    getInfo() {},
  },
};
