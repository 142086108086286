
import { MessageSquareIcon } from "vue-feather-icons";
import AccountBody from "@/pages/account/components/AccountBody.vue";
import TopMobileBack from "@/pages/account/components/TopMobileBack.vue";
import AccountContentCard from "@/pages/account/components/AccountContentCard.vue";
import CustomerServiceProvider from "@/pages/account/components/CustomerServiceProvider.vue";
import { CustumerHelpers } from "@/helpers/CustumerHelpers";
import { Form, FormItem, Input, Button } from "element-ui";
import MessageTicketForm from "@/pages/account/components/MessageTicketForm.vue";

export default {
  layout: "account",
  name: "serviceRequestAccountPage",
  // middleware: "authenticated",
  middleware: "auth",
  data() {
    return {
      custumerHelpers: new CustumerHelpers(this.$http, this.$auth),
    };
  },
  computed: {
    product() {
      const item = this.lastBoughtProducts.find((item) => {
        return (
          item.IdProductAttribute ===
          Number(this.$route.params.productAttribute)
        );
      });

      if (item) {
        return item;
      }
      return null;
    },
    lastBoughtProducts() {
      const products =
        this.$store.state.user.customerService.lastBoughtProducts;
      return products;
    },

    image() {
      try {
        return this.product.ImagesUrl.product_small[0].Url;
      } catch (error) {
        return require(`~/assets/img/default-img.jpg`);
      }
    },
    productTitle() {
      if (this.product) {
        if (this.$i18n.locale === "en") {
          return this.product.Title[1].Title;
        } else if (this.$i18n.locale === "de") {
          return this.product.Title[2].Title;
        }
        return this.product.Title[0].Title;
      }
      return "";
    },
  },
  methods: {
    async sendMessage(message) {
      this.$store.commit("shared/wind/setIsLoading", true);
      const data = {
        IdOrder: this.product.IdOrder,
        Title: "Ticket : " + this.productTitle,
        Description: message,
        Priority: 2,
        IdProductAttribute: this.product.IdProductAttribute,
      };
      try {
        await this.custumerHelpers.createTicket(data);

        this.$router.push({
          path: this.localePath("/account/service/tickets"),
        });
      } catch (error) {
      } finally {
        this.$store.commit("shared/wind/setIsLoading", false);
      }
    },
  },
  components: {
    AccountBody,
    TopMobileBack,
    MessageSquareIcon,
    AccountContentCard,
    CustomerServiceProvider,
    Form,
    FormItem,
    Input,
    Button,
    MessageTicketForm,
  },
};
