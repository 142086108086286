import { render, staticRenderFns } from "./FooterApp.vue?vue&type=template&id=5437eba9"
import script from "./FooterApp.vue?vue&type=script&lang=js"
export * from "./FooterApp.vue?vue&type=script&lang=js"
import style0 from "./FooterApp.vue?vue&type=style&index=0&id=5437eba9&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterAppTop: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/FooterAppTop.vue').default,FotterLinks: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/FotterLinks.vue').default,NewsLetter: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/NewsLetter.vue').default,BrandLogo: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/BrandLogo.vue').default})
