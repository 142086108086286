
import { Button } from "element-ui";
import { ProductHelpers } from "@/helpers/ProductHelpers.js";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      productHelpers: new ProductHelpers(this.$http, this, this.$i18n.locale),
      categorySeo: [],
      ourSelectionProducts: [],
    };
  },
  computed: {
    mainCategorie() {
      return this.$store.state.shop.categories.mainCategorie;
    },
  },
  methods: {
    getCategoryLink() {
      const seoCat = this.categorySeo;
      try {
        let seo = seoCat[0];
        if (this.$i18n.locale === "en") {
          seo = seoCat[1];
        }
        if (this.$i18n.locale === "de") {
          seo = seoCat[2];
        }
        return seo.URL;
      } catch (error) {}

      return "product";
    },
    safeLink(link) {
      let url = this.localePath(link);
      if (link.includes("http")) {
        const listWords = link.split("/");
        url = "";
        for (let i = 3; i < listWords.length; i++) {
          const element = listWords[i];
          url += "/" + element;
        }
        return url;
      }
      if (link[0] !== "/") {
        url = this.localePath("/" + link);
      }

      return url;
    },
    getCategory() {
      try {
        if (this.mainCategorie) {
         

          this.productHelpers
            .categoryProducts({
              idCategory: this.mainCategorie?.IdCategory,
              offset: 0,
              limit: 8,
              languageIsoCode: this.$i18n.locale,
              currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
            })
            .then((data) => {
              if (data?.Products) {
                this.ourSelectionProducts = data?.Products;
              }
            });
        }
      } catch (error) {
      }
    },
  },
  async mounted() {
    this.getCategory();
    // const productHelpers = new ProductHelpers(
    //   this.$http,
    //   this,
    //   this.$i18n.locale
    // );
    // try {
    //   const response = await productHelpers.category({
    //     idCategory: 37,
    //     languageIsoCode: this.$i18n.locale,
    //   });
    //   this.categorySeo = response.SEO;
    // } catch (error) {}
    // try {
    //   const data = await productHelpers.categoryProducts({
    //     idCategory: 37,
    //     page: 1,
    //     offset: 8,
    //     languageIsoCode: this.$i18n.locale,
    //     currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
    //   });
    //   if (data?.Products) {
    //     this.ourSelectionProducts = data?.Products;
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  },
  watch: {
    mainCategorie(val) {
      if (val) {
        this.getCategory();
      }
    },
  },
  components: {
    Button,
  },
};
