
import { HeartIcon } from 'vue-feather-icons';
import Favorites from '@/pages/account/components/Favorites.vue';
import AccountBody from '@/pages/account/components/AccountBody.vue';
import TopMobileBack from '@/pages/account/components/TopMobileBack.vue';

export default {
  layout: 'account',
  name: 'favoritesPage',
  // middleware: "authenticated",
  middleware: 'auth',
  components: {
    AccountBody,
    TopMobileBack,
    Favorites,
    HeartIcon,
  },
};
