
import { ChevronDownIcon } from "vue-feather-icons";
import OrderDetailsContent from "./OrderDetailsContent.vue";
export default {
  props: {
    type: {
      type: String,
      default: "details",
    },
    order: {
      type: Object,
      default: null,
    },
    feedback: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      listIsOpen: false,
    };
  },
  computed: {
    idOrder() {
      if (this.order) {
        return this.order.IdOrder;
      }
      if (this.feedback) {
        return this.feedback.IdOrder;
      }
    },

    idFeedBack() {
      if (this.feedback) {
        return this.feedback.IdOrderReturn;
      }
      return "";
    },

    state() {
      if (this.order) {
        return this.order?.State?.Label;
      }

      if (this.feedback) {
        return this.feedback?.State?.Value || "";
      }
    },
  },
  mounted() {},
  components: {
    ChevronDownIcon,
    OrderDetailsContent,
  },
};
