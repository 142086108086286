
import RelaiPointCard from "./RelaiPointCard.vue";
import { TunnelHelper } from "@/helpers/TunnelHelper.js";
import { Input, Tabs, TabPane } from "element-ui";
import GoogleAutoComplete from "./GoogleAutoComplete.vue";
import addressMixin from "@/mixins/addressMixin.js";

export default {
  mixins: [addressMixin],
  components: {
    RelaiPointCard,
    Input,
    GoogleAutoComplete,
    Tabs,
    TabPane,
  },
  props: {
    relayPoints: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // Map variables
      currentRelayPoint: null,
      circleOptions: {},
      locations: [
        {
          lat: 44.933076,
          lng: 15.629058,
        },
        {
          lat: 45.815,
          lng: "15.9819",
        },
        {
          lat: "45.12",
          lng: "16.21",
        },
      ],
      pins: {
        selected: require(`~/assets/img/icons/map-marker-selected.png`),
        notSelected: require(`~/assets/img/icons/map-marker.png`),
      },
      mapStyle: [],
      clusterStyle: [
        {
          url: require(`~/assets/img/icons/map-marker-group.png`),
          width: 56,
          height: 56,
          textColor: "#fff",
        },
      ],
      mapStyles: [
        {
          featureType: "administrative.land_parcel",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.neighborhood",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.business",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],

      // Card variables
      searchFor: "",
      relayPointsCopy: null,

      activeTabName: "cards",
    };
  },
  computed: {
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },
  },
  mounted() {
    this.relayPointsCopy = this.relayPoints;
    this.currentRelayPoint = this.relayPointsCopy[0];
    this.searchFor = this.getAddressDelivery?.Address1;
  },
  methods: {
    // Map methodes
    markerClick(relayPoint) {
      this.currentRelayPoint = relayPoint;
      this.$emit("onMarkerClick", relayPoint);
      const relaiPointScrolTo = this.$refs[`rpc-${relayPoint.Id}`][0];
      this.activeTabName = "cards";
      setTimeout(() => {
        relaiPointScrolTo.scrollIntoView({ behavior: "smooth" });
      }, 200);
    },
    getLat(relayPoint) {
      return Number(relayPoint.Location.Latitude);
    },
    getlng(relayPoint) {
      return Number(relayPoint.Location.Longitude);
    },
    getPosition(relayPoint) {
      return { lat: this.getLat(relayPoint), lng: this.getlng(relayPoint) };
    },

    //card methods
    selectRelaitPoint(relayPoint) {
      this.relayPointSelected = relayPoint;
      this.$emit("onSelectRelaitPoint", relayPoint);
    },

    onRelaiPointCardClick($event) {
      this.currentRelayPoint = $event;
      this.activeTabName = "map";
    },

    async onSelectAddress($event) {
      
      const city = $event.city;
      const postalCode = $event.postalCode;
      
      const tunnelHelper = new TunnelHelper(this.$http, this.$auth);
      const lisRelaitPoints =
      await tunnelHelper.getRelayPointsByCityAndPostcode(city, postalCode);
      
      this.relayPointsCopy = lisRelaitPoints.RelayPoints;
      if (this.relayPointsCopy.length) {
        this.currentRelayPoint = this.relayPointsCopy[0];
      }
    },
  },
};
