
import { MessageSquareIcon } from "vue-feather-icons";
import AccountBody from "@/pages/account/components/AccountBody.vue";
import TopMobileBack from "@/pages/account/components/TopMobileBack.vue";
import AccountContentCard from "@/pages/account/components/AccountContentCard.vue";
import CustomerServiceProvider from "@/pages/account/components/CustomerServiceProvider.vue";
import { CustumerHelpers } from "@/helpers/CustumerHelpers";
import { Button } from "element-ui";
export default {
  layout: "account",
  name: "serviceAccountPage",
  // middleware: "authenticated",
  middleware: "auth",
  data() {
    return {
      custumerHelpers: new CustumerHelpers(this.$http, this.$auth),
      fromProductNumber: 0,
      toProductNumber: 8,
    };
  },
  computed: {
    lastBoughtProducts() {
      const products =
        this.$store.state.user.customerService.lastBoughtProducts;
      return products;
    },
  },
  methods: {
    getImage(product) {
      try {
        return product.ImagesUrl.product_small[0].Url;
      } catch (error) {
        return require(`~/assets/img/default-img.jpg`);
      }
    },

    async getLastBoughtProducts() {
      try {
        this.fromProductNumber += this.toProductNumber;

        const lastBoughtProducts =
          await this.custumerHelpers.lastBoughtProducts(
            this.fromProductNumber,
            this.toProductNumber
          );
        this.$store.commit(
          "user/customerService/push",
          lastBoughtProducts.Products
        );
      } catch (error) {}
    },
  },
  components: {
    AccountBody,
    TopMobileBack,
    MessageSquareIcon,
    AccountContentCard,
    CustomerServiceProvider,
    Button,
  },
};
