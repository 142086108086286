
import WishListBtn from "./WishListBtn.vue";
import ColorsProduct from "./ColorsProduct.vue";
import { ShoppingBagIcon } from "vue-feather-icons";
import { Button } from "element-ui";
import { CartHelper } from "/helpers/CartHelper.js";
import Teleport from "vue2-teleport";

export default {
  // props: ['product', 'type'],
  data() {
    return {
      productCopy: null,
    };
  },
  props: {
    h3: {
      type: Boolean,
      default: false,
    },
    product: {
      // default:() => ({})
      required: true,
      type: Object,
    },
    size: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    type: {
      required: true,
      type: String,
    },

    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    imageSize: {
      type: String,
      default: "medium",
    },
    favorite: {
      type: Boolean,
      default: false,
    },
    dejaVu: {
      type: Boolean,
      default: false,
    },
    forCategory: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    WishListBtn,
    ColorsProduct,
    ShoppingBagIcon,
    Button,
    Teleport,
  },
  mounted() {
    this.productCopy = this.product;
  },
  computed: {
    getProductCopy() {
      return this.productCopy;
    },
    productImges() {
      return this.productCopy.ImagesUrls;
    },
    productImageThumbnailUrl() {
      return this.getProductImage(this.productCopy, 0);
    },
    productImageThumbnailUrlHover() {
      if (
        require(`~/assets/img/default-img.jpg`) ===
        this.getProductImage(this.productCopy, 1)
      ) {
        return this.productImageThumbnailUrl;
      }
      return this.getProductImage(this.productCopy, 1);
    },

    productTitle() {
      if (this.productCopy != null) {
        try {
          if (this.$i18n.locale === "en") {
            return this.productCopy.ProductDescription[1].Title;
          }
          if (this.$i18n.locale === "de") {
            return this.productCopy.ProductDescription[2].Title;
          }
          return this.productCopy.ProductDescription[0].Title;
        } catch (error) {}
      }
      return "";
    },
    hasPromotion() {
      try {
        let price;
        if (Array.isArray(this.productCopy.Prices)) {
          price = this.productCopy.Prices[0];
        } else {
          price = this.productCopy.Prices;
        }

        return price.InPromotion === "true";
      } catch (error) {}
      return false;
    },
    promotionPercentage() {
      const price = Number(this.productPrice);
      const pricePromotion = Number(this.productPricePromotion);

      return ((1 - pricePromotion / price) * 100).toFixed(0);
    },
    productPricePromotion() {
      let price;
      if (Array.isArray(this.productCopy.Prices)) {
        price = this.productCopy.Prices[0];
      } else {
        price = this.productCopy.Prices;
      }
      return this.$productPrice(price.PromotionPrices[0].TaxExcl);
    },
    productPrice() {
      let price;
      if (Array.isArray(this.productCopy.Prices)) {
        price = this.productCopy.Prices[0];
      } else {
        price = this.productCopy.Prices;
      }

      return this.$productPrice(price.RegularPrices[0].TaxExcl);
    },
    currencySign() {
      return this.$localisation.currencySign;
    },

    sizes() {
      return this.productCopy.Sizes;
    },

    getProductRelationship() {
      return this.product.ProductRelationship;
    },

    getVideoUrl() {
      if (this.productCopy.ProductVideoCover) {
        return this.productCopy.VideoUrl;
      }
      return "";
    },
    urlCategory() {
      try {
        const seo = this.productCopy.SEO;
        if (seo) {
          const mainCategory = seo.MainCategory.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode;
          });
          if (!mainCategory) {
            return "product";
          }
          if (mainCategory.CategoryDescriptionFriendlyURL) {
            return mainCategory.CategoryDescriptionFriendlyURL;
          }
        }
      } catch (error) {
        console.error(error);
      }
      return "product";
    },

    urlProduct() {
      try {
        const seo = this.productCopy.SEO;
        if (seo) {
          const productSeo = seo.Product.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode;
          });
          if (!productSeo) {
            return this.product.IdProduct;
          }
          if (productSeo.ProductDescriptionFriendlyURL) {
            return productSeo.ProductDescriptionFriendlyURL;
          }
        }
      } catch (error) {
      }
      return this.product.IdProduct;
    },

    productUrl() {
      if (this.forCategory && this.productCopy.SEO.MainCategoryFriendlyURL) {
        return this.link;
      }
      if (
        this.favorite ||
        this.dejaVu ||
        this.type === "one" ||
        this.productCopy.SEO
      ) {
        if (this.productCopy.SEO.MainCategory) {
          return this.localePath(`/${this.urlCategory}/${this.urlProduct}`);
        }
      }
      return this.link;
    },

    link() {
      try {
        if (this.productCopy.SEO) {
          const catUrl = this.productCopy.SEO.MainCategoryFriendlyURL;
          const url = `/${catUrl ? catUrl : "product"}/${
            this.productCopy.SEO.ProductDescriptionFriendlyURL
          }`;
          return this.localePath(url);
        }
      } catch (error) {}

      return "#";
    },

    showMobileSizes() {
      return this.$store.state.popups.addToCartMobile.show;
    },
  },
  methods: {
    setToWishList: function (event) {
      event.preventDefault();
    },

    addToCart(size) {
      if (!this.disabled) {
        // this.size = size;
        const cartHelper = new CartHelper(this.$http, this.$store, this.$auth);
        const item = {
          product: this.productCopy,
          color: this.color,
          size: size,
          quantity: 1,
        };

        if (this.$auth.loggedIn) {
          cartHelper.addProductToCart(item);
        } else {
          cartHelper.addProductToCartGuest(item);
        }
        this.$gtag.event("Add to cart", {
          event_category: "Cart",
          event_label: "Product : " + this.productTitle,
          value: "Id product : " + this.productCopy.IdProduct,
        });

        fbq("track", "InitiateCheckout", {
          content_ids: [this.productCopy.IdProduct + "-" + this.productTitle],
          eventref: "", // or set to empty string
        });
        // this.showMobileSizes = false;
      }
    },
    getProductImage(product, index = 0) {
      try {
        if (this.type === "favorite") {
          const imgurl = product.ProductImageUrl.product_small[index].Url;
          return imgurl;
        }

        if (product.ImagesUrls) {
          const imgurl = product.ImagesUrls.product_medium[index].Url;
          return imgurl;
        }

        let images = null;
        if (product.ProductImagesUrl) {
          // if (this.imageSize === "small") {
          //   const imgurl = product.ProductImagesUrl.product_small[index].Url;
          //   return imgurl;
          // } else {
          //   const imgurl = product.ProductImagesUrl.product_medium[index].Url;
          //   return imgurl;
          // }
          images = product.ProductImagesUrl;
        }

        if (product.ProductImageUrl) {
          images = product.ProductImageUrl;
        }

        if (images) {
          if (images.product_small) {
            const imgurl = images.product_small[index].Url;
            return imgurl;
          } else {
            if (images.product_large) {
              if (images.product_large.Url) {
                const imgurl = images.product_large.Url;
                return imgurl;
              }
              const imgurl = images.product_large[index].Url;
              return imgurl;
            } else {
              const imgurl = images.product_medium[index].Url;
              return imgurl;
            }
          }
        }
        return require(`~/assets/img/default-img.jpg`);
      } catch (error) {
        return require(`~/assets/img/default-img.jpg`);
      }
    },
    colorClick($event) {
      this.productCopy = $event;
    },
    leaveCard() {
      this.productCopy = this.product;
    },
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      // if (this.showMobileSizes) {
      //   this.showMobileSizes = false;
      // }
    },
    showAddToCartMobile() {
      this.$store.commit("popups/addToCartMobile/setProduct", {
        product: this.productCopy,
        color: this.color,
      });
    },
  },

  // created() {
  //   if (process.client) {
  //     window.addEventListener("scroll", this.handleScroll);
  //   }
  // },
  // unmounted() {
  //   if (process.client) {
  //     window.removeEventListener("scroll", this.handleScroll);
  //   }
  // },
};
