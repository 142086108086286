
import BlogTop from "@/pages/content/components/BlogTop.vue";
import BlogContent from "@/pages/content/components/BlogContent.vue";
import OtherMeetings from "@/pages/content/components/OtherMeetings.vue";
import SliderBanner from "@/components/SliderBanner.vue";
import { BlogQuery } from "~/graphql/queries/Blogs";

export default {
  layout: "MainLayout",
  name: "BlogPage",
  auth: false,
  async asyncData({ app, store, query, i18n, route }) {

    const res = await app.apolloProvider.defaultClient.query({
      query: BlogQuery,
      variables: {
        lang: i18n.locale,
        permalink: route.params.uid,
      },
    });

    const articles = res.data.articles;

    const page = articles.data[0].attributes;
    const links = {};
    links[page.locale] = { uid: page.seo.permalink };

    page.localizations.data.map((item) => {
      links[item.attributes.locale] = {
        uid: item.attributes.seo.permalink,
      };
    });
    await store.dispatch("i18n/setRouteParams", links);
    return {
      articles,
      breadcrumbLinks: [
        {
          link: app.localePath("/"),
          text: app.i18n.t("Home"),
        },
        {
          link: app.localePath("/blogs"),
          text: app.i18n.t("Blogs"),
        },
        {
          text: articles.data[0].attributes.title,
        },
      ],
    };
  },
  data() {
    return {
      localI18N: "",
    };
  },
  // apollo: {
  //   articles: {
  //     query: BlogQuery,
  //     variables() {
  //       return {
  //         permalink: this.$route.params.uid,
  //         lang: this.$i18n.locale,
  //       };
  //     },
  //   },
  // },
  mounted() {},
  components: { BlogTop, BlogContent, SliderBanner, OtherMeetings },
};
