
import { ProductHelpers } from "@/helpers/ProductHelpers.js";
export default {
  props: {
    products: {
      type: Array,
    },
    productIdActive: {
      type: String | Number,
    },
  },
  data() {
    return {
      productHelpers: new ProductHelpers(this.$http, this, this.$i18n.locale),
      activeProduct: null,

      productsAllReadyLoaded: [],
    };
  },
  methods: {
    getImage(product) {
      try {
        return product.Media.Images.product_xsmall[0];
      } catch (error) {}

      return null;
    },
    async getProduct(idProduct) {
      let prd = this.isAlreadyLoaded(idProduct);
      try {
        if (prd === undefined) {
          const { data } = await this.productHelpers.getProductV2({
            permalink: idProduct,
            languageIsoCode: this.$i18n.locale,
            currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
          });

          this.productsAllReadyLoaded.push(data);
          prd = data;
        }

        this.$emit("select", {
          product: prd,
        });
      } catch (error) {
      }
    },

    isAlreadyLoaded(idProduct) {
      return this.productsAllReadyLoaded.find(
        (item) => Number(item.IdProduct) === Number(idProduct)
      );
    },
  },
};
