
import Breadcrumb from "@/components/Breadcrumb.vue";
import { ShopPageDetail, ShopPage } from "~/graphql/queries/Shop";
import CardListAddress from "@/pages/shops/components/CardListAddress.vue";
import CardAddressDetails from "../shops/components/CardAddressDetails.vue";

export default {
  layout: "MainLayout",
  name: "ShopPage",
  auth: false,
  nuxtI18n: {
    paths: {
      en: "/shop/:permalink",
      fr: "/boutique/:permalink",
      de: "/geschaft/:permalink",
    },
  },

  async asyncData({ app, store, query, i18n, route, error }) {
    function notFound() {
      return error({
        statusCode: 404,
        message: i18n.t("This shop doesn't exist"),
      });
    }

    const res = await app.apolloProvider.defaultClient.query({
      query: ShopPageDetail,
      variables: {
        lang: i18n.locale,
        permalink: route.params.permalink,
      },
    });

    const addressShops = res.data.addressShops;

    if (addressShops.data.length === 0) {
      return notFound();
    }

    const page = addressShops.data[0].attributes;
    const links = {};
    links[page.locale] = { uid: page.seo.permalink };

    page.localizations.data.map((item) => {
      links[item.attributes.locale] = {
        uid: item.attributes.seo.permalink,
      };
    });
    await store.dispatch("i18n/setRouteParams", links);
    return {
      addressShops,
    };
  },
  data() {
    return {
      addressShops: null,
    };
  },

  apollo: {
    shopPage: {
      query: ShopPage,
      variables() {
        return {
          lang: this.$i18n.locale,
        };
      },
    },
  },
  // apollo: {
  //   addressShops: {
  //     query: ShopPageDetail,
  //     variables() {
  //       return {
  //         permalink: this.$route.params.permalink,
  //       };
  //     },
  //   },
  // },
  computed: {
    breadcrumbLinks() {
      if (this.addressShops === null) {
        return [];
      }
      return [
        {
          link: "/",
          text: this.$t("Home"),
        },
        {
          link: `/${this.$t("shops_url")}`,
          text: this.$t("Our shops"),
        },
        {
          text: this.addressShops
            ? this.addressShops.data[0].attributes.title
            : "",
        },
      ];
    },
  },
  components: { Breadcrumb, CardListAddress, CardAddressDetails },
};
