
import ProductCardDefault from "@/components/ProductCard/ProductCardDefault.vue";

export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    stitle: {
      required: false,
      type: String,
    },
    items: {
      required: true,
      type: Array,
    },
    textCenter: {
      required: false,
      type: Boolean,
    },
    categoryLink: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      responsiveSlider: {
        0: {
          slidesPerView: 2,
          spaceBetween: 8,
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 18,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
      val: false,
    };
  },
  methods: {},
  mounted() {},
  components: { ProductCardDefault },
};
