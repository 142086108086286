
import CardAddress from "./CardAddress.vue";
import { Button } from "element-ui";
import { PlusIcon } from "vue-feather-icons";
import OrderCard from "./OrderCard.vue";
import FavoriesItems from "./FavoriesItems.vue";

export default {
  name: "MyAddress",
  components: {
    CardAddress,
    Button,
    PlusIcon,
    OrderCard,
    FavoriesItems,
  },
  data() {
    return {
    };
  },
  computed: {
    productFavoritesSize() {
      return this.$store.state.shop.favorite?.FavoriteDetail?.length;
    },
  },
  mounted() {
  },
};
