
import { Button } from 'element-ui';
import cartMixin from '@/mixins/cartMixin';
export default {
  mixins: [cartMixin],
  props: {
    userConnected: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    tel() {
      if (this.$i18n.locale === 'fr') {
        return '01.85.14.62.85';
      }

      return '+33.1.85.14.62.85';
    },
  },
  mounted() {},
  components: { Button },
};
