
import { MailIcon } from "vue-feather-icons";
import AccountBody from "@/pages/account/components/AccountBody.vue";
import TopMobileBack from "@/pages/account/components/TopMobileBack.vue";
import { Radio, Button } from "element-ui";
import { ShopHelper } from "@/helpers/ShopHelper";

export default {
  layout: "account",
  name: "favoritesPage",
  // middleware: "authenticated",
  middleware: "auth",
  data() {
    return {
      newsletter: "1",
    };
  },
  methods: {
    async supmit() {
      const shopHelper = new ShopHelper(this.$http, this);

      if (this.newsletter === "1") {
        try {
          const response = await shopHelper.newsletterSubscription({
            email: this.$auth.user.Customer.Email,
            languageIsoCode: this.$i18n.locale,
            currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
          });

          if (response.status === 200) {
            this.$notify({
              title: this.$t("Success"),
              message: this.$t("You have successfully registered"),
              type: "success",
            });
          }
        } catch (error) {}
      } else {
        try {
          const response = await shopHelper.newsletterUnSubscription({
            email: this.$auth.user.Customer.Email,
          });

          if (response.status === 200) {
            this.$notify({
              title: this.$t("Success"),
              message: this.$t("You have successfully unsubscribed"),
              type: "success",
            });
          }
        } catch (error) {}
      }
    },
  },
  components: {
    AccountBody,
    TopMobileBack,
    MailIcon,
    Radio,
    Button,
  },
};
