
import CardAddress from "./CardAddress.vue";
import { Button } from "element-ui";
import { PlusIcon } from "vue-feather-icons";
import addressMixin from "@/mixins/addressMixin.js";

export default {
  name: "MyAddress",
  mixins: [addressMixin],
  components: { CardAddress, Button, PlusIcon },
  data() {
    return {
      // Addresses: [],
    };
  },
  mounted() {
    this.addressService.fetchAddresses();
    if (process.client) {
      document.body.classList.remove("account-index-page");
    }
  },
  activated() {},
  computed: {},
  methods: {},
};
