
export default {
  props: {
    idCategory: {
      type: Number,
    },
    items: {
      type: Array,
      default: [],
    },
    rightItem: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    leftItems() {
      return this.items.filter((item) => {
        return item.type === 'left';
      });
    },
    centerItems() {
      return this.items.filter((item) => {
        return item.type === 'center';
      });
    },
    rightItems() {
      return this.items.filter((item) => {
        return item.type === 'right';
      });
    },
  },
  methods: {
    getLink(item) {
      try {
        const link = item.link;
        if (link.charAt(link.length - 1) === '/') {
          link = link.slice(0, -1);
        }

        // const parts = link.split("/");
        // const permalink = parts[parts.length - 1];
        return link;
      } catch (error) {}

      return '/';
    },
  },
};
