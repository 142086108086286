
import OrderCard from "./OrderCard.vue";
import { ChevronLeftIcon } from "vue-feather-icons";
const order = {
  ref: "37896",
  state: "delivered",
  products: [
    {
      IdProduct: 4527,
      Reference: "RACALO869-75831/JC483",
      Color: "75831/JC483",
      ProductDescription: [
        {
          Title: "Robe mi-longue en fibranne imprimée matière éco-responsable",
          Description:
            "<h3><strong>D&eacute;tails produit :</strong></h3>\r\n\r\n<ul>\r\n\t<li>Robe mi-longue &agrave; volants&nbsp;en fibranne imprim&eacute;e.</li>\r\n\t<li>Manche courte.</li>\r\n\t<li>Encolure avec d&eacute;coupe sous poitrine.</li>\r\n\t<li>Encolure en V.</li>\r\n\t<li>Sous poitrine 3 volants fronc&eacute;s donne un effet tr&egrave;s vaporeux.</li>\r\n\t<li>De coupe large.</li>\r\n</ul>\r\n",
          IdLang: "1",
        },
        {
          Title: "Mid-length dress in eco-responsible printed fibranne",
          Description:
            "<h3><strong>Product details:</strong></h3>\r\n\r\n<ul>\r\n\t<li>Mid-length ruffled dress in printed fibranne.</li>\r\n\t<li>Short sleeve.</li>\r\n\t<li>Neckline with underbust cutout.</li>\r\n\t<li>V neckline.</li>\r\n\t<li>Under the bust 3 gathered ruffles give a very vaporous effect.</li>\r\n\t<li>Wide cut.</li>\r\n</ul>\r\n",
          IdLang: "2",
        },
      ],
      ProductImageUrl:
        "https://cdn.digipart.fr/upload/images/retouched/thumb/thumbnail-4527-RACALO869-75831-JC483-59785.jpg",
      Prices: [
        {
          InPromotion: "false",
          RegularPrices: [
            {
              TaxIncl: "139.00",
              TaxExcl: "115.83",
            },
          ],
        },
      ],
      AvailableSizes: ["2(42/44)", "4(46/48)", "6(50/52)", "8(54/56)"],
    },
    {
      IdProduct: 4102,
      Reference: "RACALO841-32228B",
      Color: "32228B",
      ProductDescription: [
        {
          Title: "Robe mi-longue en fibranne imprimée matière éco-responsable",
          Description:
            "<h3><strong>D&eacute;tails produit :</strong></h3>\r\n\r\n<ul>\r\n\t<li>Robe mi-longue &agrave; volants&nbsp;en fibranne imprim&eacute;e.</li>\r\n\t<li>Manche courte.</li>\r\n\t<li>Encolure avec d&eacute;coupe sous poitrine.</li>\r\n\t<li>Encolure en V.</li>\r\n\t<li>Sous poitrine 3 volants fronc&eacute;s donne un effet tr&egrave;s vaporeux.</li>\r\n\t<li>De coupe large.</li>\r\n</ul>\r\n",
          IdLang: "1",
        },
        {
          Title: "Mid-length dress in printed fibranne éco-responsable fabrics",
          Description:
            "<h3><strong>Product details:</strong></h3>\r\n\r\n<ul>\r\n\t<li>Mid-length ruffled dress in printed fibranne.</li>\r\n\t<li>Short sleeve.</li>\r\n\t<li>Neckline with underbust cutout.</li>\r\n\t<li>V neckline.</li>\r\n\t<li>Under the bust 3 gathered ruffles give a very vaporous effect.</li>\r\n\t<li>Wide cut.</li>\r\n</ul>\r\n",
          IdLang: "2",
        },
      ],
      ProductImageUrl:
        "https://cdn.digipart.fr/upload/images/retouched/thumb/thumbnail-4102-ROCOCO841-33228B-53879.jpg",
      Prices: [
        {
          InPromotion: "true",
          RegularPrices: [
            {
              TaxIncl: "139.00",
              TaxExcl: "115.83",
            },
          ],
          PromotionType: [
            {
              Type: "percentage",
              Name: "Soldes 20%",
            },
          ],
          PromotionPrices: [
            {
              TaxIncl: "111.2",
              TaxExcl: "92.67",
            },
          ],
          PromotionSchedule: [
            {
              StartDate: "2022-09-01 00:01:00",
              EndDate: "2022-10-31 23:59:00",
            },
          ],
        },
      ],
      AvailableSizes: ["2(42/44)", "4(46/48)", "6(50/52)", "8(54/56)"],
    },
    {
      IdProduct: 3294,
      Reference: "ROCOCO841-DEV31136A",
      Color: "DEV31136A",
      ProductDescription: [
        {
          Title: "Robe longue en fibranne imprimée  tissus  éco-responsable",
          Description:
            "<h3><strong>D&eacute;tails produit :</strong></h3>\r\n\r\n<ul>\r\n\t<li>Robe longue &agrave; volants&nbsp;en fibranne imprim&eacute;e.</li>\r\n\t<li>Manche courte.</li>\r\n\t<li>Encolure avec d&eacute;coupe sous poitrine.</li>\r\n\t<li>Encolure en V.</li>\r\n\t<li>Sous poitrine 3 volants fronc&eacute;s donne un effet tr&egrave;s vaporeux.</li>\r\n\t<li>De coupe large.</li>\r\n</ul>\r\n",
          IdLang: "1",
        },
        {
          Title: "Long printed fibranne dress",
          Description:
            "<h3><strong>Product details:</strong></h3>\r\n\r\n<ul>\r\n\t<li>Long ruffled dress in printed fibranne.</li>\r\n\t<li>Short sleeve.</li>\r\n\t<li>Neckline with cut under chest.</li>\r\n\t<li>V-neck</li>\r\n\t<li>Under the chest 3 gathered ruffles give a very vaporous effect.</li>\r\n\t<li>Wide cut.</li>\r\n</ul>\r\n",
          IdLang: "2",
        },
      ],
      ProductImageUrl:
        "https://cdn.digipart.fr/upload/images/retouched/thumb/thumbnail-3294-ROCOCO841-DEV31136A-3.jpg",
      Prices: [
        {
          InPromotion: "true",
          RegularPrices: [
            {
              TaxIncl: "139.00",
              TaxExcl: "115.83",
            },
          ],
          PromotionType: [
            {
              Type: "percentage",
              Name: "Soldes 20%",
            },
          ],
          PromotionPrices: [
            {
              TaxIncl: "111.2",
              TaxExcl: "92.67",
            },
          ],
          PromotionSchedule: [
            {
              StartDate: "2022-09-01 00:01:00",
              EndDate: "2022-10-31 23:59:00",
            },
          ],
        },
      ],
      AvailableSizes: ["2(42/44)", "4(46/48)", "6(50/52)", "8(54/56)"],
    },
    {
      IdProduct: 4281,
      Reference: "ROCOCO841-32228B",
      Color: "32228B",
      ProductDescription: [
        {
          Title: "Robe longue en fibranne imprimée",
          Description:
            "<h3><strong>D&eacute;tails produit :</strong></h3>\r\n\r\n<ul>\r\n\t<li>Robe longue &agrave; volants&nbsp;en fibranne imprim&eacute;e.</li>\r\n\t<li>Manche courte.</li>\r\n\t<li>Encolure avec d&eacute;coupe sous poitrine.</li>\r\n\t<li>Encolure en V.</li>\r\n\t<li>Sous poitrine 3 volants fronc&eacute;s donne un effet tr&egrave;s vaporeux.</li>\r\n\t<li>De coupe large.</li>\r\n</ul>\r\n",
          IdLang: "1",
        },
        {
          Title: "Long printed fibranne dress",
          Description:
            "<h3><strong>Product details:</strong></h3>\r\n\r\n<ul>\r\n\t<li>Long ruffled dress in printed fibranne.</li>\r\n\t<li>Short sleeve.</li>\r\n\t<li>Neckline with cut under chest.</li>\r\n\t<li>V-neck</li>\r\n\t<li>Under the chest 3 gathered ruffles give a very vaporous effect.</li>\r\n\t<li>Wide cut.</li>\r\n</ul>\r\n",
          IdLang: "2",
        },
      ],
      ProductImageUrl:
        "https://cdn.digipart.fr/upload/images/retouched/thumb/thumbnail-4281-ROCOCO841-32228B-55260.jpg",
      Prices: [
        {
          InPromotion: "true",
          RegularPrices: [
            {
              TaxIncl: "139.00",
              TaxExcl: "115.83",
            },
          ],
          PromotionType: [
            {
              Type: "percentage",
              Name: "Soldes 20%",
            },
          ],
          PromotionPrices: [
            {
              TaxIncl: "111.2",
              TaxExcl: "92.67",
            },
          ],
          PromotionSchedule: [
            {
              StartDate: "2022-09-01 00:01:00",
              EndDate: "2022-10-31 23:59:00",
            },
          ],
        },
      ],
      AvailableSizes: ["2(42/44)", "4(46/48)", "6(50/52)", "8(54/56)"],
    },
    {
      IdProduct: 4457,
      Reference: "ROCOCO869-76558",
      Color: "76558",
      ProductDescription: [
        {
          Title: "Robe longue en fibranne imprimée",
          Description:
            "<h3><strong>D&eacute;tails produit :</strong></h3>\r\n\r\n<ul>\r\n\t<li>Robe longue &agrave; volants&nbsp;en fibranne imprim&eacute;e.</li>\r\n\t<li>Manche courte.</li>\r\n\t<li>Encolure avec d&eacute;coupe sous poitrine.</li>\r\n\t<li>Encolure en V.</li>\r\n\t<li>Sous poitrine 3 volants fronc&eacute;s donne un effet tr&egrave;s vaporeux.</li>\r\n\t<li>De coupe large.</li>\r\n</ul>\r\n",
          IdLang: "1",
        },
        {
          Title: "Long printed fibranne dress",
          Description:
            "<h3><strong>Product details:</strong></h3>\r\n\r\n<ul>\r\n\t<li>Long ruffled dress in printed fibranne.</li>\r\n\t<li>Short sleeve.</li>\r\n\t<li>Neckline with cut under chest.</li>\r\n\t<li>V-neck</li>\r\n\t<li>Under the chest 3 gathered ruffles give a very vaporous effect.</li>\r\n\t<li>Wide cut.</li>\r\n</ul>\r\n",
          IdLang: "2",
        },
      ],
      ProductImageUrl:
        "https://cdn.digipart.fr/upload/images/retouched/thumb/thumbnail-4457-ROCOCO869-76558-58834.jpg",
      Prices: [
        {
          InPromotion: "true",
          RegularPrices: [
            {
              TaxIncl: "149.00",
              TaxExcl: "124.17",
            },
          ],
          PromotionType: [
            {
              Type: "percentage",
              Name: "Soldes 20%",
            },
          ],
          PromotionPrices: [
            {
              TaxIncl: "119.2",
              TaxExcl: "99.33",
            },
          ],
          PromotionSchedule: [
            {
              StartDate: "2022-09-01 00:01:00",
              EndDate: "2022-10-31 23:59:00",
            },
          ],
        },
      ],
      AvailableSizes: ["2(42/44)", "4(46/48)", "6(50/52)", "8(54/56)"],
    },
  ],
};
export default {
  data() {
    return {
      order,
    };
  },
  components: { OrderCard, ChevronLeftIcon },
};
