
import { CornerUpLeftIcon, CornerDownRightIcon } from "vue-feather-icons";
import AccountBody from "@/pages/account/components/AccountBody.vue";
import Returns from "@/pages/account/components/Returns.vue";
import TopMobileBack from "@/pages/account/components/TopMobileBack.vue";

export default {
  layout: "account",
  name: "returnsPage",
  // middleware: "authenticated",
  middleware: "auth",
  data() {
    return {
      tab: "",
    };
  },
  components: {
    AccountBody,
    Returns,
    TopMobileBack,
    CornerUpLeftIcon,
    CornerDownRightIcon,
  },
  mounted() {},
};
