
import { Button, Checkbox } from 'element-ui';
import { InfoIcon } from 'vue-feather-icons';
import { loadScript } from '@paypal/paypal-js';
import PaypalPayment from '@/services/payments/PaypalPayment';
import cartMixin from '@/mixins/cartMixin';
import addressMixin from '@/mixins/addressMixin';

export default {
  mixins: [cartMixin, addressMixin],
  props: {
    paymentMethod: {
      type: Object,
    },
  },
  data() {
    return {
      disabled: false,
      generalConditionsSale: false,
    };
  },
  computed: {
    paypalPayment() {
      return new PaypalPayment({
        cart: this.cart,
        auth: this.$auth,
        http: this.$http,
        addressService: this.addressService,
      });
    },
    getCurrency() {
      return this.cart?.Currency?.IsoCode;
    },
  },
  mounted() {
    if (this.$refs['paypal-button-container'].innerHTML.trim() === '') {
      this.startPayement();
    }
  },
  activated() {},
  methods: {
    startPayement() {
      const lang = this.$i18n.locale;
      let locale;

      if (lang === 'fr') {
        locale = `${lang}_${lang.toUpperCase()}`;
      }
      if (lang === 'de') {
        locale = `${lang}_${lang.toUpperCase()}`;
      }
      if (lang === 'en') {
        locale = `${lang}_US`;
      }

      loadScript({
        'client-id': process.env.PAYPAL_CLIENT_ID,
        currency: this.getCurrency,
        locale: locale,
      })
        .then((paypal) => {
          paypal
            .Buttons({
              style: {
                disableMaxWidth: true,
                layout: 'horizontal',
                color: 'black',
                shape: 'rect',
                label: 'paypal',
              },
              // Order is created on the server and the order id is returned
              createOrder: async (data, actions) => {
                let discount = this.cart?.Total?.Discount?.TaxIncl;

                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        currency_code: this.getCurrency,
                        value: this.cart?.Total?.ToPay?.TaxIncl,
                        breakdown: {
                          item_total: {
                            /* Required when including the items array */
                            currency_code: this.getCurrency,
                            value: this.cart?.Total?.Product?.TaxIncl,
                          },
                          discount: {
                            currency_code: this.getCurrency,
                            value: discount,
                          },
                          shipping: {
                            currency_code: this.getCurrency,
                            value: this.paypalPayment.shipping?.TaxIncl,
                          },
                        },
                      },
                      custom_id: this.cart.IdCart,
                      items: this.paypalPayment.basket,
                    },
                  ],
                });
              },
              // Finalize the transaction on the server after payer approval
              onApprove: (data, actions) => {
                const $this = this;
                return actions.order.capture().then(async function (details) {
                  // Handle the successful payment

                  $this.$router.push(
                    $this.localePath(
                      `/cart/thanks/accepted?orderid=${data.orderID}&cartid=${$this.cartService.idCart}`
                    )
                  );
                });
                // return true;
              },
            })
            .render('#paypal-button-container');
        })
        .catch((err) => {
          console.error('failed to load the PayPal JS SDK script', err);
        });
    },
  },
  components: { Button, InfoIcon, Checkbox },
};
