
import { Button, Dialog } from "element-ui";
import { InfoIcon } from "vue-feather-icons";
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sizeSelected: null,
      colorSelected: null,
      valide: true,
      idProductAttributeSelected: null,
      mobileShowSizes: false,
    };
  },
  computed: {
    tel() {
      if (this.$i18n.locale === "fr") {
        return "01.85.14.62.85";
      }

      return "+33.1.85.14.62.85";
    },
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },
    hasPromotion() {
      let price = this.product.Prices[0];
      return price.InPromotion === "true";
    },
    getProductImgs() {
      if (this.product.ProductImagesUrl) {
        return this.product.ProductImagesUrl.product_xlarge;
      }
      return [];
    },
    productImage() {
      if (this.getProductImgs.length) {
        return this.getProductImgs[0].Url;
      }
      return "";
    },

    productTitle() {
      if (this.product != null) {
        try {
          if (this.$i18n.locale === "en") {
            return this.product.ProductDescription[1].Title;
          }
          if (this.$i18n.locale === "de") {
            return this.product.ProductDescription[2].Title;
          }
          return this.product.ProductDescription[0].Title;
        } catch (error) {}
      }
      return "";
    },
    productPricePromotion() {
      let price = this.product.Prices[0];
      return this.$productPrice(price.PromotionPrices[0].TaxExcl);
    },
    currencySign() {
      return this.$localisation.currencySign;
    },

    promotionPercentage() {
      const price = Number(this.productPrice);
      const pricePromotion = Number(this.productPricePromotion);

      return ((1 - pricePromotion / price) * 100).toFixed(0);
    },
    productPrice() {
      let price = this.product.Prices[0];

      return this.$productPrice(price.RegularPrices[0].TaxExcl);
    },
    productColor() {
      if (this.product != null) {
        return this.product.Attributes[0].Color;
      }
      return "";
    },
  },
  methods: {
    selectSize(attr) {
      if (attr.Quantity > 0) {
        this.colorSelected = attr.Color;
        this.sizeSelected = attr.Size;
        this.idProductAttributeSelected = attr.IdProductAttribute;
        this.valide = true;
      }
      if (this.$SETTINGS.isMobile) {
        this.emitNext();
      }
    },
    emitNext() {
      this.$emit("next", {
        size: this.sizeSelected,
        color: this.colorSelected,
        idProductAttribute: this.idProductAttributeSelected,
      });
    },
    next() {
      if (!this.$SETTINGS.isMobile) {
        if (this.idProductAttributeSelected === null) {
          this.valide = false;
          return;
        }
        this.emitNext();
      } else {
        this.mobileShowSizes = true;
      }
    },
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      if (this.mobileShowSizes) {
        this.mobileShowSizes = false;
      }
    },
  },
  mounted() {},
  created() {
    if (process.client) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  unmounted() {
    if (process.client) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  },
  components: {
    Button,
    Dialog,
    InfoIcon,
  },
};
