
import { Button } from "element-ui";

// import SliderBanner from "../components/SliderBanner.vue";
// import FullImgCard from "../components/FullImgCard.vue";
// import DoubleSquareCard from "../components/DoubleSquareCard.vue";
// import BlockImageCenter from "../components/BlockImageCenter.vue";
// import OurSelection from "../components/OurSelection.vue";
// import OurShops from "../components/OurShops.vue";
// import FollowUs from "../components/FollowUs.vue";

// const SliderBanner = await import('@/components/SliderBanner.vue');
// const FullImgCard = await import('@/components/FullImgCard.vue');
// const DoubleSquareCard = await import('@/components/DoubleSquareCard.vue');
// const BlockImageCenter = await import('@/components/BlockImageCenter.vue');
// const OurSelection = await import('@/components/OurSelection.vue');
// const OurShops = await import('@/components/OurShops.vue');
// const FollowUs = await import('@/components/FollowUs.vue');

import { HomeQuery } from "~/graphql/queries/Home";

export default {
  layout: "MainLayout",
  name: "HomePage",
  auth: false,
  head() {
    return {
      title: this.$t("home_title"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("home_meta_discription"),
        },
      ],
    };
  },
  data() {
    return {
      homePage: null,
    };
  },
  apollo: {
    homePage: {
      query: HomeQuery,
      variables() {
        return {
          lang: this.$i18n.locale,
        };
      },
    },
  },
  mounted() {},
  components: {
    Button,
    // SliderBanner,
    // FullImgCard,
    // DoubleSquareCard,
    // BlockImageCenter,
    // OurSelection,
    // OurShops,
    // FollowUs,
  },
};
