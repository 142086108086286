
export default {
  props: {
    imgsrc: {
      type: String,
      default: require(`~/assets/img/robes-min.jpg`),
    },
    imgsrcMobile: {
      type: String,
      default: require(`~/assets/img/robes-min.jpg`),
    },
    videoUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      videoWidth: 1900,
    };
  },
  computed: {
    vdScale() {
      if (process.client) {
        return (window.innerHeight / window.innerWidth) * 2;
      }

      return 1;
    },
  },
  methods: {},
  mounted() {},
};
