
import {
  BoxIcon,
  CornerUpRightIcon,
  CornerDownLeftIcon,
  CreditCardIcon,
  HelpCircleIcon,
  ShoppingBagIcon,
  MessageSquareIcon,
} from "vue-feather-icons";
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  components: {
    BoxIcon,
    CornerUpRightIcon,
    CornerDownLeftIcon,
    CreditCardIcon,
    HelpCircleIcon,
    ShoppingBagIcon,
    MessageSquareIcon,
  },
};
