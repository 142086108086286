
import { CheckIcon } from 'vue-feather-icons';
import { Button } from 'element-ui';
import LoyaltyOurStatusItems from './LoyaltyOurStatusItems.vue';
export default {
  components: {
    CheckIcon,
    Button,
    LoyaltyOurStatusItems,
  },

  computed: {
    loyaltyLink() {
      let link = this.localePath(`/account/loyalty/membership`);
      if (!this.$auth.loggedIn) {
        return this.localePath(
          `/authenticate/login?redirect=${this.$t('loyalty_path')}`
        );
      }
      return link;
    },
  },
};
