import { render, staticRenderFns } from "./AlmaForm.vue?vue&type=template&id=1a2c18e8&scoped=true"
import script from "./AlmaForm.vue?vue&type=script&lang=js"
export * from "./AlmaForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a2c18e8",
  null
  
)

export default component.exports