
import { Button, Form, FormItem, Input, Checkbox, Dialog } from "element-ui";

import { ShopHelper } from "@/helpers/ShopHelper";
import { MailIcon, CheckIcon } from "vue-feather-icons";

export default {
  data() {
    return {
      isFocus: false,
      loading: false,
      newsletter: {
        email: "",
        privacy: [],
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t("Required Field"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("Please input correct email address"),
            trigger: "blur",
          },
        ],
        // privacy: [
        //   {
        //     required: true,
        //     message: this.$t('Required Field'),
        //     trigger: "blur",
        //   },
        // ],
      },
      showed: false,
    };
  },
  methods: {
    async onSubmit() {
      await this.$refs["newsletterForm"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const shopHelper = new ShopHelper(this.$http, this);
          try {
            const response = await shopHelper.newsletterSubscription({
              email: this.newsletter.email,
              languageIsoCode: this.$i18n.locale,
              currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
            });

            if (response.status === 200) {
              this.showed = true;
              this.newsletter.email = "";
            }
          } catch (error) {
          } finally {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    },
    closeDialog() {
      this.showed = false;
    },
  },
  components: {
    Button,
    Form,
    FormItem,
    Input,
    Checkbox,
    Dialog,
    MailIcon,
    CheckIcon,
  },
};
