
import { UserIcon, ChevronLeftIcon } from "vue-feather-icons";
export default {
  props: {
    title: {
      typeof: String,
      default: "",
    },
    icon: {
      typeof: String,
      default: "user",
    },
  },
  components: {
    UserIcon,
    ChevronLeftIcon,
  },
};
