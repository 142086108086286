
import CartItem from "./CartItem.vue";
import { Button, Empty } from "element-ui";
import { ChevronDownIcon } from "vue-feather-icons";
import CartItemCodesPromo from "./CartItemCodesPromo";
import cartMixin from "@/mixins/cartMixin.js";

export default {
  mixins: [cartMixin],
  props: {
    mincart: {
      type: Boolean,
      default: false,
    },
    pageCart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // products: [],
      maxItem: 2,
      showMore: false,
      hasQuantiyErrors: [],
      hasDoubleItemErrors: [],
    };
  },

  computed: {
    allItems() {
      return this.cart?.Products;
    },

    firtsItems() {
      return this.allItems.slice(0, this.maxItem);
    },

    lastItems() {
      return this.allItems.slice(this.maxItem, this.allItems.length);
    },

    items() {
      if (this.mincart) {
        return this.firtsItems;
      }

      return this.allItems;
    },
    hasError() {
      const error =
        this.hasQuantiyErrors.length > 0 || this.hasDoubleItemErrors.length > 0;
      this.$emit("onErrorHandler", error);
      return error;
    },
  },
  mounted() {},

  methods: {
    // addTodo(e) {
    //   this.$store.commit("todos/add", e.target.value);
    //   e.target.value = "";
    // },
    // ...mapMutations({
    //   toggle: "todos/toggle",
    // }),

    getQuantity(val) {
      if (typeof val === "string") {
        return parseInt(val);
      }

      return val;
    },
    checkQuantityError($event, item) {
      // const { hasError, product, size, quantity } = $event;
      // const idCartDetail = item.idCartDetail;
      // if ($event.hasError) {
      //   this.hasQuantiyErrors.push($event);
      // } else {
      //   const index = this.hasQuantiyErrors.findIndex(
      //     (item) => item.idCartDetail === idCartDetail
      //   );
      //   this.hasQuantiyErrors.splice(index, 1);
      // }
      // this.checkDoupleItemError($event, item);
    },
  },

  components: { CartItem, Empty, Button, ChevronDownIcon, CartItemCodesPromo },
};
