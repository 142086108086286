
export default {
  props: {
    nbrProduct: {
      type: Number,
      default: 0,
    },
    categoryTitle: {
      type: String,
    },
    sizes: {
      type: Array,
    },
    colors: {
      type: Array,
    },
    price: {
      type: Object,
    },
    showSort: {
      type: Boolean,
      default: true,
    },
    showFilterBtn: {
      type: Boolean,
      default: true,
    },
    category: {
      type: Boolean,
      default: false,
    },
    h1: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
};
