
export default {
  props: {},

  computed: {
    mainCategorie() {
      return this.$store.state.shop.categories.mainCategorie;
    },
    link() {
      let url = "/";
      try {
        if (this.mainCategorie) {
          url = this.mainCategorie?.CategoryDescriptionFriendlyURL || url;
        }
      } catch (error) {}

      return url;
    },
  },
  methods: {},

  components: {},
};
