
import Breadcrumb from "../../components/Breadcrumb.vue";
import BlockImgsRandom from "../../components/BlockImgsRandom.vue";
import { MarkPage } from "~/graphql/queries/Mark";

export default {
  layout: "MainLayout",
  auth: false,
  data() {
    return {
      markPage: {},
    };
  },
  apollo: {
    markPage: {
      query: MarkPage,
      variables() {
        return {
          lang: this.$i18n.locale,
        };
      },
    },
  },
  computed: {
    breadcrumbLinks() {
      return [
        {
          link: "/",
          text: this.$t("Home"),
        },
        {
          text: this.markPage.data ? this.markPage.data.attributes.title : "",
        },
      ];
    },
  },
  components: { Breadcrumb, BlockImgsRandom },
};
