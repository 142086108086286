
import { Button } from "element-ui";
export default {
  components: {
    Button,
  },
  methods: {
    checkVisible() {
      if (process.client) {
        const elm = this.$refs.rightbar;
        if (elm) {
          var rect = elm.getBoundingClientRect();
          var viewHeight = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight
          );
          return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
        }
      }
      return false;
    },
    rightBarPos() {
      //   const rightbar = this.$refs.rightbar;
      //   const scrollToToFixed = 197;
      //   const main = this.$refs.main;
      const ordertotalmobile = this.$refs.ordertotalmobile;

      //   const rightbarPoint = rightbar.getBoundingClientRect();
      //   const mainPoint = main.getBoundingClientRect();
      //   const scrollToToGo =
      //     scrollToToFixed + mainPoint.height - rightbarPoint.height;
      //   let $this = this;

      //   if (window.innerWidth > 1024) {
      // if (window.scrollY > scrollToToGo) {
      //   rightbar.style.transform =
      //     "translateY(-" + (window.scrollY - scrollToToGo) + "px)";
      // } else {
      //   rightbar.style.transform = "translateY(0px)";
      // }
      // if (window.scrollY > scrollToToFixed) {
      //   rightbar.classList.add("posfixeed");
      // } else if (window.scrollY <= scrollToToFixed) {
      //   rightbar.classList.remove("posfixeed");
      //   rightbar.style.transform = "translateY(0px)";
      // }
      //   }
      if (ordertotalmobile) {
        if (this.$SETTINGS.isMobile) {
          if (this.checkVisible()) {
            ordertotalmobile.classList.add("hide");
          } else {
            ordertotalmobile.classList.remove("hide");
          }
        }
      }
    },
  },
  mounted() {
    // window.scrollTo(window.scrollX, 0);
    this.rightBarPos();
    window.addEventListener("scroll", (e) => {
      this.rightBarPos();
    });

    // this.$root.$on("child-event", this.rightBarPos);
  },
};
