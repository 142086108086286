
import SliderBanner from "@/components/SliderBanner.vue";
import { ArticlesPerCategory } from "~/graphql/queries/Blogs";
import { CategoriesQuery, CategoryPage } from "~/graphql/queries/Categories";
import AllBlogs from "@/pages/content/components/AllBlogs.vue";
import CardBlogFull from "@/pages/content/components/CardBlogFull.vue";

export default {
  layout: "MainLayout",
  name: "BlogsPage",
  auth: false,

  async asyncData({ app, store, query, i18n, route }) {

    const start = 0;
    const limit = 7;

    const res = await app.apolloProvider.defaultClient.query({
      query: ArticlesPerCategory,
      variables: {
        lang: i18n.locale,
        permalink: route.params.permalink,
        start: start,
        limit: limit,
      },
    });

    const categories = res.data.categories;
    const category = categories.data[0].attributes;
    const articles = category.articles.data;

    const links = {};
    links[category.locale] = { permalink: category.seo.permalink };

    category.localizations.data.map((item) => {
      links[item.attributes.locale] = {
        permalink: item.attributes.seo.permalink,
      };
    });
    await store.dispatch("i18n/setRouteParams", links);
    return {
      articles,
      start: start + limit,
      limit: 6,
    };
  },
  data() {
    return {
      // listBlogs: listBlogsAll,
      categoryActive: "0",
      // articles: {},
      // page: 7,
      disableLoadMore: false,
      hideLoadMore: false,
    };
  },
  apollo: {
    // articles: {
    //   query: ArticlePerPage,
    //   variables() {
    //     return {
    //       page: this.page,
    //       permalink: this.$route.params.permalink,
    //       lang: this.$i18n.locale,
    //     };
    //   },
    // },
    categories: {
      query: CategoriesQuery,
      variables() {
        return {
          lang: this.$i18n.locale,
        };
      },
    },
    categoryPage: {
      query: CategoryPage,
      variables() {
        return {
          lang: this.$i18n.locale,
        };
      },
    },
  },
  methods: {
    // filter(id) {
    //   this.categoryActive = id;
    //   this.listBlogs = listBlogsAll.filter((blog) => {
    //     return blog.category.id === id || id === 0;
    //   });
    // },
    async loadMoreArticles() {
      this.disableLoadMore = true;
      try {
        const res = await this.$apollo.query({
          query: ArticlesPerCategory,
          variables: {
            lang: this.$i18n.locale,
            permalink: this.$route.params.permalink,
            start: this.start,
            limit: this.limit,
          },
        });

        const categories = res.data.categories;
        const category = categories.data[0].attributes;
        const articles = category.articles.data;
        if (articles.length) {
          this.articles = [...this.articles, ...articles];
        } else {
          this.hideLoadMore = true;
        }

        this.start += this.limit;
      } catch (error) {
        console.error(error);
      } finally {
        this.disableLoadMore = false;
      }

      // const res = await this.$apollo
      //   .query({
      //     query: getUser,
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     return;
      //   });
    },
  },
  watch: {
    articles() {},
    page(val) {},
  },
  mounted() {},
  components: { SliderBanner, AllBlogs, CardBlogFull },
};
