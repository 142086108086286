
import { Button } from "element-ui";
import LoyaltyRewards from "@/pages/account/components/LoyaltyRewards.vue";
import LoyaltyNotSubscirbe from "@/pages/account/components/LoyaltyNotSubscirbe.vue";
import LoyaltyTop from "@/pages/account/components/LoyaltyTop.vue";
import { LoyaltyService } from "@/helpers/LoyaltyService";
export default {
  name: "Loyalty",

  data() {
    return {
      loading: true,
      customer: {
        points: 0,
        state: false,
        events: [],
      },
    };
  },
  mounted() {
    this.getLoyaltyData();
    this.$store.dispatch("shop/loyalty/fetchLoyaltyCustomer");
    this.$store.dispatch("shop/loyalty/fetchMyRewards", {
      loyaltyRewardState: "all",
    });
  },
  methods: {
    getLoyaltyData() {
      const loyaltyService = new LoyaltyService(this.$http);
      loyaltyService
        .loyaltyHistory({ languageIsoCode: this.$i18n.locale })
        .then((data) => {
          this.customer.points = data.TotalPoints;
          this.customer.state = data.Subscribed;
          this.customer.events = data.LoyaltyEvents;
        })
        .catch((err) => {
          console.err(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getEventImage(eventName) {
      if (eventName === "SUBSCRIBE") {
        return require(`~/assets/img/j-icon.svg`);
      }

      return require(`~/assets/img/j-icon.svg`);
    },
  },
  computed: {
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },

    listRewards() {
      return this.$store.state.shop.loyalty?.myRewards?.LoyaltyReward;
    },
    myRewardsState() {
      return this.$store.state.shop.loyalty.myRewards.LoyaltyRewardState;
    },
  },
  components: {
    LoyaltyTop,
    Button,
    LoyaltyNotSubscirbe,
    LoyaltyRewards,
  },
};
