
export default {
  computed: {
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },

    loading() {
      return this.$store.state.shared.wind.loading;
    },
  },
  methods: {
    setIsMobile() {
      this.$store.commit("shared/wind/toggle");
    },
  },

  mounted() {
    this.setIsMobile();
    window.addEventListener("resize", () => {
      this.setIsMobile();
    });
  },
};
