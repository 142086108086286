
import { StarIcon } from "vue-feather-icons";
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      values: [1, 2, 3, 4, 5],
    };
  },
  methods: {
    starEnter(v) {
      for (let i = 1; i <= v; i++) {
        this.$refs["star-" + i][0].classList.add("hover");
      }
    },
    starLeave() {
      for (let i = 1; i <= 5; i++) {
        this.$refs["star-" + i][0].classList.remove("hover");
      }
    },
    startClick(v) {
      this.$emit("rate", {
        value: v,
      });
    },
  },
  components: {
    StarIcon,
  },
};
