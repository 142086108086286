
import { Button } from "element-ui";
import { InfoIcon } from "vue-feather-icons";
import { PaymentHelper } from "@/helpers/PaymentHelper";

export default {
  data() {
    return {
      bancontactInstance: null,
      hipay: null,
      errors: [],
    };
  },
  computed: {
    defaultLocal() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    try {
      this.initHipay();
      this.bancontactPayement();
    } catch (error) {}
  },
  methods: {
    initHipay() {
      this.hipay = HiPay({
        username: process.env.HIPAY_USERNAME,
        password: process.env.HIPAY_PASSWORD,
        environment: process.env.HIPAY_ENVIRONMENT,
        lang: this.defaultLocal,
      });
    },
    bancontactPayement() {
      const option = {
        selector: "hipay-bancontact",
        template: "auto",
      };
      if (this.hipay != null) {
        this.bancontactInstance = this.hipay.create("bancontact", option);
      }
    },
  },
  components: { Button, InfoIcon },
};
