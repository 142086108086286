
import { XIcon, InfoIcon } from "vue-feather-icons";
import CodePromoItem from "./CodePromoItem.vue";
import cartMixin from "@/mixins/cartMixin.js";

export default {
  mixins: [cartMixin],
  props: {},
  data() {
    return {};
  },

  mounted() {},

  computed: {
    promoCodeData() {
      return this.cart?.Discounts?.PromoCodes;
    },
    cartRulesData() {
      return this.cart?.Discounts?.CartRules;
    },
  
  },
  methods: {
    remove() {},
  },

  components: { XIcon, CodePromoItem },
};
