
import { Button } from 'element-ui';
export default {
  computed: {
    loyaltyLink() {
      let link = this.localePath(`/account/loyalty/membership`);
      if (!this.$auth.loggedIn) {
        return this.localePath(
          `/authenticate/login?redirect=${this.$t('loyalty_path')}`
        );
      }
      return link;
    },
  },
  components: {
    Button,
  },
};
