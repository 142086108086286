
import { XIcon } from "vue-feather-icons";
import cartMixin from "@/mixins/cartMixin.js";

export default {
  mixins: [cartMixin],
  props: {
    idPromoCode: {
      type: String | Number,
    },
    promoCode: {
      type: Object,
    },
  },
  data() {
    return {
    };
  },

  mounted() {},

  computed: {},
  methods: {
    async setUserCodePromos() {
      try {
        await this.$auth.fetchUser();
        const PromoCodes = this.$auth.user.CustomerPromoCode;
        this.$store.commit("shop/codepromo/setPromoCode", {
          data: PromoCodes,
        });
      } catch (error) {
      }
    },
    removeCodePromo(idPromoCode) {
      this.cartService
        .removeCodePromo({
          idPromoCode,
          idCart: this.cart.IdCart,
        })
        .then(async (data) => {
          this.cartService.fetchCart();
        })
        .catch((err) => {});
    },
  },

  components: { XIcon },
};
