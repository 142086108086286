
import CardAddress from './CardAddress.vue';
import { Button, Alert } from 'element-ui';
import { PlusIcon } from 'vue-feather-icons';
import { OrderHelper } from '/helpers/OrderHelper';
import ReturnCard from '@/pages/account/components/ReturnCard.vue';

export default {
  name: 'MyReturns',
  components: { CardAddress, Button, PlusIcon, ReturnCard, Alert },

  data() {
    return {
      responsiveSlider: {
        0: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 4.9,
          spaceBetween: 16,
        },
      },
      feedbacks: [],
    };
  },
  computed: {},
  async mounted() {
    if (process.client) {
      document.body.classList.remove('account-index-page');
    }
    const orderHelper = new OrderHelper(
      this.$http,
      this.$store,
      this.$i18n.locale
    );
    try {
      const data = await orderHelper.getListReturns({
        languageIsoCode: this.$i18n.locale,
      });
      this.feedbacks = data.OrderReturns;
    } catch (error) {}
  },
};
