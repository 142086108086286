
import { Form, FormItem, Input, Button } from "element-ui";
export default {
  props: {
    btnText: {
      type: String,
      default: "Send your request",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      messageForm: {
        message: "",
      },
      rulesMessageForm: {
        message: [
          {
            required: true,
            message: this.$t("Required Field"),
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs["messageForm"].validate((valid) => {
        if (valid) {
          this.$emit("submit", {
            message: this.messageForm.message,
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["messageForm"].resetFields();
    },
  },
  components: {
    Form,
    FormItem,
    Input,
    Button,
  },
};
