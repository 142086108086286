
import CardAddress from './CardAddress.vue';
import { Button } from 'element-ui';
import { PlusIcon } from 'vue-feather-icons';
import OrderCard from '@/pages/account/components/OrderCard.vue';
import accountMixin from '../../../mixins/accountMixin';
import OrdersLoading from './OrdersLoading.vue';

export default {
  name: 'MyAddress',
  mixins: [accountMixin],
  data() {
    return {
      loading: false,
      responsiveSlider: {
        0: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 4.9,
          spaceBetween: 16,
        },
      },
    };
  },
  computed: {
    orders() {
      return this.$store.state.user.orders.list;
    },
  },
  created() {
    this.loading = true;
    this.accountService.fetchOrders({
      onSuccess: () => {
        this.loading = false;
      },
      onError: () => {
        this.loading = false;
      },
    });
  },
  mounted() {
    // const orderHelper = new OrderHelper(
    //   this.$http,
    //   this.$store,
    //   this.$i18n.locale
    // );
    // orderHelper.getList();

    if (process.client) {
      document.body.classList.remove('account-index-page');
    }
  },
  components: { CardAddress, Button, PlusIcon, OrderCard, OrdersLoading },
};
