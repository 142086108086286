
import NewPasswordForm from "@/pages/password/components/NewPasswordForm.vue";
import PasswordLayout from "./components/PasswordLayout.vue";
import BackToLogin from "./components/BackToLogin.vue";
import { PasswordHelper } from "@/helpers/PasswordHelper";

export default {
  layout: "MainLayout",
  name: "NewPasswordPage",
  auth: false,
  middleware: ["authenticated"],
  // nuxtI18n: {
  //   paths: {
  //     en: "/reset",
  //     fr: "/reinitialiser",
  //   },
  // },
  components: {
    NewPasswordForm,
    PasswordLayout,
    BackToLogin,
  },
  async created() {
    const query = this.$route.query;
    try {
      const response = await new PasswordHelper(this.$http).checkTokenAndEmail(
        query
      );

      if (response.status !== 200) {
        this.$router.push({ name: "error", params: { statusCode: 404 } });
      }
    } catch (error) {
      this.$router.push({ name: "error", params: { statusCode: 404 } });
    }
  },
  validate({ params, query, store }) {
    const validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };
    let isOk = true;
    if (!query.token || !query.email) {
      isOk = false;
    }
    if (!validateEmail(query.email)) {
      isOk = false;
    }
    return isOk;
  },
};
