
export default {
  props: {
    productImges: {
      type: Array,
    },
    link: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
    },
    idProduct: {
      type: String | Number,
    },
  },
  computed: {
    imageDefault() {
      return require(`~/assets/img/default-img.jpg`);
    },
  },
  mounted() {},
};
