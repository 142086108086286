
import { InfoIcon } from 'vue-feather-icons';
import { LoyaltyService } from '@/helpers/LoyaltyService';

export default {
  props: {
    idLoyaltyProgram: {
      type: Number,
    },
    tierActive: {
      type: String,
    },
    totleCumulatePoints: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tiers: [],
    };
  },
  computed: {
    pointsToReach() {
      let r = null;
      this.tiers.map((tier, index) => {
        if (
          this.totleCumulatePoints >= tier.LoyaltyProgramTierFromTotalPoint &&
          this.totleCumulatePoints <= tier.LoyaltyProgramTierToTotalPoint
        ) {
          if (this.tiers.length - 1 > index) {
            r = this.tiers[index + 1];
          }
        }
      });

      return r;
    },
  },
  methods: {
    isActive(tierName) {
      if (this.tierActive) {
        return this.tierActive.toLowerCase() === tierName.toLowerCase();
      }

      return false;
    },
    loadata() {
      if (this.idLoyaltyProgram) {
        const loyaltyService = new LoyaltyService(this.$http);
        loyaltyService
          .loyaltyTier({
            LanguageIsoCode: this.$i18n.locale,
            IdLoyaltyProgram: this.idLoyaltyProgram,
          })
          .then((data) => {
            this.tiers = data.LoyaltyProgramTiers;
          })
          .catch((err) => {})
          .finally(() => {});
      }
    },
  },

  mounted() {
    this.loadata();
  },

  components: { InfoIcon },
};
