
import {
  Collapse,
  CollapseItem,
  Input,
  Button,
  FormItem,
  Form,
} from "element-ui";
import cartMixin from "@/mixins/cartMixin.js";

export default {
  mixins: [cartMixin],
  data() {
    return {
      isFocus: false,
      promoCodeForm: {
        code: "",
      },
      error: {
        status: false,
        message: "",
      },
      rules: {
        code: [
          {
            required: true,
            message: this.$t("Required Field"),
            trigger: "change",
          },
        ],
      },
    };
  },

  mounted() {},
  methods: {
    async onSubmit() {
      this.$refs["promoCodeForm"].validate((valid) => {
        if (valid) {
          this.getPromoCodeData();
        } else {
          return false;
        }
      });
    },

    getPromoCodeData() {
      this.$store.dispatch("shop/codepromo/apply", {
        code: this.promoCodeForm.code,
        idCart: this.cart?.IdCart,
        onSuccess: (data) => {
          this.cartService.fetchCart();
        },
        onError: (error) => {
          const msg = error?.response?.data?.message;
          if (msg) {
            this.$notify.error({
              title: this.$t("Promo Code / Voucher"),
              message: msg,
            });
          }
        },
      });
    },
  },
  components: {
    Collapse,
    CollapseItem,
    Input,
    Button,
    FormItem,
    Form,
  },
};
