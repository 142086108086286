
import { Button } from "element-ui";
import { InfoIcon } from "vue-feather-icons";
import { PaymentHelper } from "@/helpers/PaymentHelper";
import { TunnelHelper } from "@/helpers/TunnelHelper";
import { Amount } from "@/helpers/Amount";
import { Tunnel } from "@/helpers/Tunnel";
import { loadScript } from "@paypal/paypal-js";
import { OrderHelper } from "/helpers/OrderHelper";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sofortInstance: null,
      hipay: null,
      errors: [],
      tunnelHelper: new TunnelHelper(this.$http, this.$auth, this.$store),
    };
  },
  computed: {
    paymentMethods() {
      return this.$store.state.shop.payment.paymentMethods.data;
    },
    defaultLocal() {
      return this.$i18n.locale;
    },
    getCurrency() {
      return this.$localisation.appCurrency.CurrencyIsoCode;
    },
    getCart() {
      return this.$store.state.shop.cart;
    },
    getTotolAmount() {
      return this.toNumberFixed(
        this.$productPrice(this.getCart.cartTotal.PromotionPrice),
        2
      );
    },
    getShipping() {
      const carrier = this.tunnelHelper.getDeliveryOption();

      const ShippingTaxRate = this.toNumberFixed(carrier.Tax.TaxRate);
      const ShippingCostTaxExcl = this.toNumberFixed(
        this.$toCurrency(carrier.DeliveryFees)
      );
      const ShippingCostTaxIncl =
        ShippingTaxRate * ShippingCostTaxExcl + ShippingCostTaxExcl;

      const shippingRef = carrier.CarrierType + "-" + carrier.IdCarrier;
      return {
        ref: shippingRef,
        amount: ShippingCostTaxIncl,
      };
    },
    getBasket() {
      const listItemLocal = this.getCart.listItemLocal;
      const basket = [];
      // let ttl = 0;
      listItemLocal.map((item) => {
        // let price = 0,
        //   discount = 0,
        //   tax = 0,
        //   quantite = Number(item.quantity);

        // const priceRegularTaxExcl = Number(
        //   item.product.Prices.RegularPrices[0].TaxExcl
        // );
        // const priceRegularTaxIncl = Number(
        //   this.$productPrice(priceRegularTaxExcl, 2)
        // );

        // price = Number(priceRegularTaxIncl);
        // // tax = (
        // //   ((priceRegularTaxIncl - priceRegularTaxExcl) / priceRegularTaxExcl) *
        // //   100
        // // ).toFixed(2);

        // if (item.product.Prices.InPromotion === "true") {
        //   const pricePromotionTaxExcl = Number(
        //     item.product.Prices.PromotionPrices[0].TaxExcl
        //   );

        //   const pricePromotionTaxIncl = Number(
        //     this.$productPrice(pricePromotionTaxExcl, 2)
        //   );

        //   price = Number(pricePromotionTaxIncl).toFixed(2);
        // }

        const quantity = item.quantity;
        let productPriceRegular = item.product.Prices.RegularPrices[0].TaxExcl;
        let productPromotionPrice = productPriceRegular;

        if (item.product.Prices.InPromotion === "true") {
          productPromotionPrice =
            item.product.Prices.PromotionPrices[0].TaxExcl;
        }

        const amount = new Amount(this);
        amount.fixedNumber = 2;
        const pricePromotion = amount.price(productPromotionPrice);

        const newItem = {
          name: this.productTitle(item.product.ProductDescription),
          description: `Ref :${item.product.Reference}`,
          unit_amount: {
            currency_code: this.getCurrency,
            value: pricePromotion.ttc,
          },
          quantity: quantity,
        };

        // ttl += Number(quantite) * pricePromotion.ttc;

        basket.push(newItem);
      });

      // const tunnel = new Tunnel(this, this.$store);
      // tunnel.fixedNumber = 2;
      // const shipping = tunnel.shipping;
      // const carrier = this.tunnelHelper.getDeliveryOption();
      // if (carrier) {
      //   const shippingRef = carrier.CarrierType + "-" + carrier.IdCarrier;

      //   basket.push({
      //     name: "Shippingfees",
      //     description: `Ref : ${shippingRef}`,
      //     unit_amount: {
      //       currency_code: this.getCurrency,
      //       value: shipping.ttc,
      //     },
      //     quantity: 1,
      //   });
      // }

      // ttl += shipping.euroTTC;

      return basket;
    },
  },
  mounted() {
    if (this.$refs["paypal-button-container"].innerHTML.trim() === "") {
      this.startPayement();
    }
  },
  activated() {
    // if (this.$refs["paypal-button-container"].innerHTML.trim() === "") {
    //   this.startPayement();
    // }
  },
  methods: {
    productTitle(ProductDescription) {
      try {
        if (this.$i18n.locale === "en") {
          return ProductDescription[1].Title;
        }
        if (this.$i18n.locale === "de") {
          return ProductDescription[2].Title;
        }
        return ProductDescription[0].Title;
      } catch (error) {}
      return "Product";
    },
    getPayementMethod(mp) {
      const has = this.paymentMethods.PaymentMethods.findIndex(
        (item) => item.PaymentCode === mp
      );
      return this.paymentMethods.PaymentMethods[has];
    },
    toNumberFixed(val, fixe = 2) {
      return Number(Number(val).toFixed(fixe));
    },
    startPayement() {
      loadScript({
        "client-id": process.env.PAYPAL_CLIENT_ID,
        currency: this.getCurrency,
      })
        .then((paypal) => {
          paypal
            .Buttons({
              style: {
                layout: "horizontal",
                color: "black",
                shape: "rect",
                label: "paypal",
              },
              // Order is created on the server and the order id is returned
              createOrder: async (data, actions) => {
                // const total =
                //   Number(this.getTotolAmount) + Number(this.getShipping.amount);

                await this.verifyCodePromo();
                const tunnel = new Tunnel(this, this.$store);
                const paymentMethodSelected = this.getPayementMethod("PAYPAL");
                tunnel.paymentMethod = paymentMethodSelected;
                tunnel.fixedNumber = 2;

                const totalProductPromotion = tunnel.totalProduct.promotion.ttc;
                const total = totalProductPromotion + tunnel.shipping.ttc;

                this.tunnelHelper.saveCart(JSON.stringify(tunnel.custom_data));

         

                let discount = 0;
                if (tunnel.promoCode) {
                  discount = tunnel.totalProduct.promoCode.ttc;
                }

                discount = Math.abs(discount);

                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        currency_code: this.getCurrency,
                        value: (total - discount).toFixed(2),
                        breakdown: {
                          item_total: {
                            /* Required when including the items array */
                            currency_code: this.getCurrency,
                            value: totalProductPromotion.toFixed(2),
                          },
                          discount: {
                            currency_code: this.getCurrency,
                            value: discount.toFixed(2),
                          },
                          shipping: {
                            currency_code: this.getCurrency,
                            value: tunnel.shipping.ttc.toFixed(2),
                          },
                        },
                      },
                      custom_id: tunnel.cart.idCart,
                      items: this.getBasket,
                    },
                  ],
                });
              },
              // Finalize the transaction on the server after payer approval
              onApprove: (data, actions) => {
               

                const $this = this;
                return actions.order.capture().then(function (details) {
                  // Handle the successful payment

                  $this.$router.push(
                    $this.localePath(
                      `/cart/thanks/accepted?orderid=${data.orderID}`
                    )
                  );
                });
                // return true;
              },
            })
            .render("#paypal-button-container");
        })
        .catch((err) => {
          console.error("failed to load the PayPal JS SDK script", err);
        });
    },
    async verifyCodePromo() {
      const orderHelper = new OrderHelper(
        this.$http,
        this.$store,
        this.$i18n.locale
      );
      if (this.$auth.user.PromoCode) {
        try {
          const response = await orderHelper.verifyPromoCode();
          if (response.PromoCode === null) {
            this.$store.commit("shop/cart/setPromoCode", {
              $auth: this.$auth,
              data: null,
            });
          }
        } catch (error) {}
      }
    },
  },
  components: { Button, InfoIcon },
};
