
import { Button } from "element-ui";
import EarnPoints from "@/pages/account/components/EarnPoints.vue";
import LoyaltyJoinUs from "@/pages/account/components/LoyaltyJoinUs.vue";
import LoyaltyOurStatus from "@/pages/account/components/LoyaltyOurStatus.vue";
import LoyaltyAwardsCatalog from "@/pages/account/components/LoyaltyAwardsCatalog.vue";

export default {
  name: "Loyalty",
  components: {
    EarnPoints,
    Button,
    LoyaltyOurStatus,
    LoyaltyAwardsCatalog,
    LoyaltyJoinUs,
  },
};
