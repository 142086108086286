
import { InputNumber, Select, Option } from "element-ui";
import { XIcon, InfoIcon } from "vue-feather-icons";
import cartMixin from "@/mixins/cartMixin.js";

let timerUpdateQuantity;

export default {
  mixins: [cartMixin],
  props: {
    idCartDetail: {
      required: false,
      type: String | Number,
    },
    product: {
      required: true,
      type: Object,
    },
    mincart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quantitySelected: 0,
      idProductAttributeSelected: null,
      quantityErrorMsg: this.$t(
        "This product is no longer available in the chosen version or quantity, modify or delete this product"
      ),
      doubleItemErrorMsg: this.$t(
        "This product already exists with this size, modify or delete to continue"
      ),
      quantityLoading: false,
      sizeLoading: false,
    };
  },

  mounted() {
    this.quantitySelected = this.product?.Quantity;
    this.idProductAttributeSelected = this.product.IdProductAttribute;
  },

  computed: {
    productTitle() {
      return this.product?.Description?.Title;
    },
    variants() {
      return this.product?.Variant;
    },
    variantSelected() {
      const variantSelected = this.variants.find(
        (item) => item.Selected === true
      );

      return variantSelected;
    },
    color() {
      return this.getColorFromVariant(this.variantSelected);
    },
    size() {
      return this.getSizeFromVariant(this.variantSelected);
    },
    productImageThumbnailUrl() {
      try {
        return this.product?.Media?.Images?.product_xsmall[0].Src;
      } catch (error) {
        return require(`~/assets/img/default-img.jpg`);
      }
    },
    availableSizes() {
      return this.product.AvailableSizes;
    },
    // sizeNotDispo() {
    //   if (this.itemSelected) {
    //     if (Number(this.itemSelected.Quantity) === 0) {
    //       return true;
    //     }
    //   }
    //   return false;
    // },
    // itemSelected() {
    //   if (this.availableSizes) {
    //     const sizeFind = this.availableSizes.find((item) => {
    //       return item.Size === this.idProductAttributeSelected;
    //     });
    //     if (sizeFind) {
    //       return sizeFind;
    //     }
    //   }
    //   return null;
    // },
    maxQuantiy() {
      return this.variantSelected?.Quantity;
    },
    hasError() {
      // return this.sizeNotDispo;
      return false;
    },
    hasDoubleItemError() {
      return false;
    },
    hasPromotion() {
      let price = this.product.Price;
      return price.PromotionalPrice;
    },
    promotionPercentage() {
      return this.product.Price?.PromotionalPrice?.PriceRulePercentage;
    },
    productPricePromotion() {
      let price = this.product.Price;
      return price?.PromotionalPrice?.PriceTaxIncl;
    },
    productPrice() {
      let price = this.product.Price;
      return price?.RegularPrice?.PriceTaxIncl;
    },
    currencySign() {
      return this.$localisation.currencySign;
    },

    linkCategory() {
      try {
        const seo = this.product.SEO;
        if (seo) {
          const mainCategory = seo.MainCategory.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode;
          });
          if (!mainCategory) {
            return "product";
          }
          if (mainCategory.CategoryDescriptionFriendlyURL) {
            return mainCategory.CategoryDescriptionFriendlyURL;
          }
        }
      } catch (error) {}
      return "product";
    },
    linkProduct() {
      try {
        const seo = this.product.SEO;
        if (seo) {
          const productSeo = seo.Product.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode;
          });
          if (!productSeo) {
            return this.product.IdProduct;
          }
          if (productSeo.ProductDescriptionFriendlyURL) {
            return productSeo.ProductDescriptionFriendlyURL;
          }
        }
      } catch (error) {}
      return this.product.IdProduct;
    },
    productUrl() {
      return this.localePath(`/${this.linkCategory}/${this.linkProduct}`);
    },
  },
  methods: {
    getColorFromVariant(variant) {
      return variant?.Combination?.Attributes.find(
        (item) => item.GroupName === "Color"
      );
    },
    getSizeFromVariant(variant) {
      return variant?.Combination?.Attributes.find(
        (item) => item.GroupName === "Size"
      );
    },

    async setUserCodePromos() {
      try {
        await this.$auth.fetchUser();

        const PromoCodes = this.$auth.user.CustomerPromoCode;
        this.$store.commit("shop/codepromo/setPromoCode", {
          data: PromoCodes,
        });
      } catch (error) {
      }
    },
    async removeProduct() {
      this.cartService
        .removeProductCart({
          idCart: this.cart.IdCart,
          product: this.product,
        })
        .then((data) => {
          this.cartService.fetchCart();
        })
        .finally(() => {
          this.quantityLoading = false;
        });
    },
    updateProduct(onFinally) {
      this.cartService
        .updateProductCart({
          idCart: this.cart.IdCart,
          product: this.product,
          quantity: this.quantitySelected,
          newIdProductAttribute: this.idProductAttributeSelected,
        })
        .then((data) => {
          this.cartService.fetchCart();
        })
        .finally(() => {
          // this.quantityLoading = false;
          onFinally();
        });
    },
    selectSize() {
      this.sizeLoading = true;
      this.updateProduct(() => {
        this.sizeLoading = false;
      });
    },

    handleChange() {
      clearTimeout(timerUpdateQuantity);
      timerUpdateQuantity = setTimeout(async () => {
        this.quantityLoading = true;
        // this.cartService
        //   .updateProductCart({
        //     idCart: this.cart.IdCart,
        //     product: this.product,
        //     quantity: this.quantitySelected,
        //     newIdProductAttribute: this.idProductAttributeSelected,
        //   })
        //   .then((data) => {
        //     this.cartService.fetchCart();
        //   })
        //   .finally(() => {
        //     this.quantityLoading = false;
        //   });
        this.updateProduct(() => {
          this.quantityLoading = false;
        });
      }, 1000);
    },
    setQuantity() {},
  },

  components: { InputNumber, XIcon, InfoIcon, Select, Option },
};
