
import LoyaltyRewards from "@/pages/account/components/LoyaltyRewards.vue";
import { Alert } from "element-ui";
export default {
  computed: {
    customer() {
      return this.$store.state.shop.loyalty.customer;
    },
    listMyRewards() {
      return this.$store.state.shop.loyalty.myRewards.LoyaltyRewards;
    },
    myRewardsState() {
      return this.$store.state.shop.loyalty.myRewards.LoyaltyRewardState;
    },
  },
  mounted() {
    this.$store.dispatch("shop/loyalty/fetchMyRewards", {
      loyaltyRewardState: "all",
    });
    this.$store.dispatch("shop/loyalty/fetchLoyaltyCustomer");
    this.$store.dispatch("shop/loyalty/fetchRewards");
  },
  components: {
    LoyaltyRewards,
    Alert,
  },
};
