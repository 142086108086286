
import { Button } from "element-ui";
import RegisterFormInfo from "@/pages/authenticate/components/RegisterFormInfo.vue";
import { ReferralService } from "../../services/ReferralService";
export default {
  layout: "MainLayout",
  auth: false,

  data() {
    return {
      referralService: new ReferralService({ http: this.$http }),
      step: 1,
      breadcrumbLinks: [
        {
          link: "/",
          text: this.$t("Home"),
        },
        {
          text: this.$t("Refer a friend"),
        },
      ],
      sponsorData: null,
    };
  },

  computed: {
    hasRegisterStep() {
      return !this.$auth.loggedIn;
    },
  },
  methods: {
    start() {
      if (this.hasRegisterStep) {
        this.step = 2;
      } else {
        this.getCode();
      }
    },
    getCode() {
      this.referralService
        .getCode({ languageIsoCode: this.$i18n.locale })
        .then((data) => {
          this.sponsorData = data;
          this.step = 3;
        })
        .catch((error) => {
        })
        .finally(() => {});
    },
  },
  components: {
    Button,
    RegisterFormInfo,
  },
};
