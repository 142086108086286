
export default {
  props: {
    status: {
      type: String,
      default: '',
    },
    codebar: {
      type: String,
    },
    number: {
      type: String,
    },
    type: {
      type: 'yellow' | 'pink' | 'black',
      default: '',
    },
  },

  computed: {
    cardClass() {
      if (this.type === 'yellow') {
        return 'cardloyalty--yellow';
      }
      if (this.type === 'pink') {
        return 'cardloyalty--pink';
      }
      if (this.type === 'black') {
        return 'cardloyalty--black';
      }
    },
  },
};
