
import { SectionListAddress } from "~/graphql/queries/Shop";
export default {
  props: {
    addresses: {
      type: Array,
    },
    title: {
      type: String,
      default: "NOS ADRESSES",
    },
    image: {
      type: Object,
    },
  },
  data() {
    return {
      // sectionListAddress: null,
    };
  },
  // apollo: {
  //   sectionListAddress: {
  //     query: SectionListAddress,
  //   },
  // },
};
