
import KlarnaPayments from '../../../../../services/payments/KlarnaPayments';
import cartMixin from '@/mixins/cartMixin';
import addressMixin from '@/mixins/addressMixin';

import { Button, Checkbox } from 'element-ui';

export default {
  mixins: [cartMixin, addressMixin],
  props: {
    paymentMethod: {
      type: Object,
    },
  },
  head() {
    return {
      script: [
        {
          src: 'https://x.klarnacdn.net/kp/lib/v1/api.js',
          // async: true,
          callback: () => {
            this.loadLkarna();
          },
        },
      ],
    };
  },
  data() {
    return {
      generalConditionsSale: false,
      klarnaSession: null,
    };
  },
  created() {},
  computed: {
    klarnaPayments() {
      return new KlarnaPayments({
        cart: this.cart,
        auth: this.$auth,
        http: this.$http,
        addressService: this.addressService,
      });
    },
  },
  mounted() {
    // this.loadLkarna();
  },
  methods: {
    toggleLoader(val) {
      this.$store.commit('shared/wind/setIsLoading', val);
    },
    startKlarna(token) {
      if (process.client) {
        // window.klarnaAsyncCallback = function () {
        if (Klarna !== undefined) {
          Klarna.Payments.init({
            client_token: token,
          });
          Klarna.Payments.load({
            container: '#klarna-payments-container',
            payment_method_category: 'pay_over_time',
            function(res) {},
          });
        }

        // }
      }
    },
    async loadLkarna() {
      this.klarnaSession = await this.klarnaPayments.setSession();
      this.startKlarna(this.klarnaSession.data.client_token);
    },
    getAuthKlarna() {
      if (process.client) {
        const $this = this;
        // window.klarnaAsyncCallback = function () {
        if (Klarna !== undefined) {
          Klarna.Payments.authorize(
            this.klarnaPayments.getData(),
            async function (res) {
              console.debug(res);

              if (res.approved) {
                //   $this.$router.push(
                //     $this.localePath(`/cart/thanks/accepted?orderid=${idorder}`)
                //   );
                $this.toggleLoader(true);
                try {
                  const response = await $this.klarnaPayments.placeOrder({
                    auth_token: res.authorization_token,
                    session_id: $this.klarnaSession.data.session_id,
                  });
                  const orderRes = await $this.klarnaPayments.createOrder({
                    order_id: response.data.order_id,
                  });
                  try {
                    if (orderRes.IdOrder) {
                      $this.$router.push(
                        $this.localePath(
                          `/cart/thanks/accepted?orderid=${orderRes.IdOrder}&cartid=${$this.cartService.idCart}`
                        )
                      );
                    }
                  } catch (error) {
                  } finally {
                    $this.toggleLoader(false);
                  }
                } catch (error) {
                  console.error(error);
                }
              }
            }
          );
        }

        // }
      }
    },
  },
  components: { Button, Checkbox },
};
