
import { ChevronDownIcon } from 'vue-feather-icons';
import { Button, Progress, Dialog } from 'element-ui';
import LoyaltyPosts from '@/pages/account/components/LoyaltyPosts.vue';
import LoyaltyRewards from '@/pages/account/components/LoyaltyRewards.vue';
import LoyaltyTop from '@/pages/account/components/LoyaltyTop.vue';
import LoyaltyNotSubscirbe from '@/pages/account/components/LoyaltyNotSubscirbe.vue';
import GoogeWalletButton from './GoogeWalletButton.vue';
import LoyaltyTier from '@/pages/account/components/LoyaltyTier.vue';
import LoyaltyOurStatusItems from './LoyaltyOurStatusItems.vue';

export default {
  name: 'Loyalty',
  data() {
    return {
      showed: false,
    };
  },
  computed: {
    customer() {
      return this.$store.state.shop.loyalty.customer;
    },
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },
    circleOptions() {
      if (this.getIsMobile) {
        return {
          width: 220,
          strokeWidth: 20,
        };
      } else {
        return {
          width: 449,
          strokeWidth: 56,
        };
      }
    },
    listRewards() {
      return this.$store.state.shop.loyalty.listRewards;
    },
    listMyRewards() {
      return this.$store.state.shop.loyalty.myRewards.LoyaltyRewards;
    },
    myRewardsState() {
      return this.$store.state.shop.loyalty.myRewards.LoyaltyRewardState;
    },
  },
  mounted() {
    this.$store.dispatch('shop/loyalty/fetchMyRewards', {
      loyaltyRewardState: 'all',
    });
    this.$store.dispatch('shop/loyalty/fetchLoyaltyCustomer', {
      onSuccess: () => {
        this.gotToPageHash();
      },
    });
    this.$store.dispatch('shop/loyalty/fetchRewards');
  },
  watch: {
    $route() {
      if (this.$route.hash) {
        this.gotToPageHash();
      }
    },
  },
  methods: {
    scrollTo(ref) {
      window.scrollTo({
        top: this.$refs[ref].offsetTop - 60,
        behavior: 'smooth',
      });
    },
    gotToPageHash() {
      const hash = this.$route.hash;
      if (hash) {
        setTimeout(() => {
          const ref = this.$refs[hash];
          if (ref) {
            this.scrollTo(hash);
          }
        }, 500);
      }
    },
  },
  components: {
    LoyaltyPosts,
    LoyaltyTop,
    ChevronDownIcon,
    Progress,
    Button,
    LoyaltyNotSubscirbe,
    GoogeWalletButton,
    LoyaltyRewards,
    Dialog,
    LoyaltyTier,
    LoyaltyOurStatusItems,
  },
};
