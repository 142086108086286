
import { Button } from 'element-ui';

export default {
  props: {
    address: {
      type: Object,
      required: true,
    },
    imgRight: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    Monday() {
      return this.address.attributes.schedules.filter(
        (item) => item.day === 'Monday'
      );
    },
    Tuesday() {
      return this.address.attributes.schedules.filter(
        (item) => item.day === 'Tuesday'
      );
    },
    Wednesday() {
      return this.address.attributes.schedules.filter(
        (item) => item.day === 'Wednesday'
      );
    },
    Thursday() {
      return this.address.attributes.schedules.filter(
        (item) => item.day === 'Thursday'
      );
    },
    Friday() {
      return this.address.attributes.schedules.filter(
        (item) => item.day === 'Friday'
      );
    },
    Saturday() {
      return this.address.attributes.schedules.filter(
        (item) => item.day === 'Saturday'
      );
    },
    Sunday() {
      return this.address.attributes.schedules.filter(
        (item) => item.day === 'Sunday'
      );
    },
  },
  data() {
    return {
      responsiveSlider: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    };
  },
  methods: {
    randomKey() {
      return (
        new Date().getTime() + Math.floor(Math.random() * 10000).toString()
      );
    },
  },
  components: {
    Button,
  },
};
