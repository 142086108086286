
import Breadcrumb from "@/components/Breadcrumb.vue";
import { ShopPage } from "~/graphql/queries/Shop";
import CardAddress from "@/pages/shops/components/CardAddress.vue";
import CardListAddress from "@/pages/shops/components/CardListAddress.vue";
import { Button } from "element-ui";

export default {
  layout: "MainLayout",
  auth: false,
  nuxtI18n: {
    paths: {
      en: "/shops/index",
      fr: "/boutiques/index",
      de: "/geschafte/index",
    },
  },
  data() {
    return {
      shopPage: null,
    };
  },

  computed: {
    breadcrumbLinks() {
      return [
        {
          link: "/",
          text: this.$t("Home"),
        },
        {
          text: this.$t("Our shops"),
        },
      ];
    },
  },
  apollo: {
    shopPage: {
      query: ShopPage,
      variables() {
        return {
          lang: this.$i18n.locale,
        };
      },
    },
  },
  components: { Breadcrumb, CardAddress, CardListAddress, Button },
};
