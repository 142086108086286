
import { CheckIcon } from "vue-feather-icons";
import cartMixin from "@/mixins/cartMixin.js";
import { Button } from "element-ui";

export default {
  mixins: [cartMixin],
  props: {
    listMyRewards: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    // listMyRewards() {
    //   return this.$store.state.shop.loyalty.myRewards.s;
    // },
  },
  methods: {
    applyCodePromo(code) {
      this.loading = true;
      const promoCode = this.isInCart(code);
      if (promoCode) {
        this.cartService
          .removeCodePromo({
            idPromoCode: promoCode?.IdPromoCode,
            idCart: this.cart.IdCart,
          })
          .then(async (data) => {
            this.cartService.fetchCart();
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store
          .dispatch("shop/codepromo/apply", {
            code: code,
            idCart: this.cart?.IdCart,
            loyaltyRewardStateLabel: "available",
          })
          .then((data) => {
            this.cartService.fetchCart();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    isInCart(code) {
      return this.cartService.hasCodePromo(code);
    },
  },
  mounted() {
    // this.$store.dispatch("shop/loyalty/fetchMyRewards", {
    //   loyaltyRewardState: "available",
    // });
  },
  components: {
    CheckIcon,
    Button,
  },
};
