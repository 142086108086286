
import { HeartIcon } from "vue-feather-icons";
import favoriteMixin from "@/mixins/favoriteMixin.js";

export default {
  mixins: [favoriteMixin],
  data() {
    return {
      loading: false,
    };
  },
  props: {
    product: {
      required: true,
      type: Object,
    },
    size: {
      type: String,
      default: "",
    },
  },
  components: {
    HeartIcon,
  },
  computed: {
    active() {
      return this.isInFavorite(this.product);
    },
  },
  methods: {
    setToWishList: function (event) {
      event.preventDefault();
      this.loading = true;
      if (this.active) {
        this.favoriteService
          .removeProductFavorite({ product: this.product })
          .then((data) => {
            this.favoriteService.fetchFavorite();
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.favoriteService
          .addToFavorite({ product: this.product })
          .then((data) => {
            this.favoriteService.fetchFavorite({
              onSuccess: () => {
                this.openRightBarFavorite();
              },
            });
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      }


      event.stopPropagation();
    },
  },
};
