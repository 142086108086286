
import { Drawer } from 'element-ui';
import { InfoIcon } from 'vue-feather-icons';
export default {
  data() {
    return {
      drawer: true,
      direction: 'rtl',
      toggleMenu: false,
    };
  },
  components: {
    Drawer,
    InfoIcon,
  },
};
