
import { Tabs, TabPane, Collapse, CollapseItem } from 'element-ui';
import { HomeIcon, MapPinIcon } from 'vue-feather-icons';
import Carriers from '@/pages/cart/components/Carriers.vue';

import addressMixin from '@/mixins/addressMixin.js';
import carriersMixin from '@/mixins/carriersMixin.js';
export default {
  mixins: [addressMixin, carriersMixin],
  props: {},
  data() {
    return {
      // activeTab: "Home",
    };
  },
  computed: {
    activeTab: {
      get() {
        return this.$store.state.carriers.activeTab;
      },
      set(value) {
        this.$store.commit('carriers/setActiveTab', value);
      },
    },
    idAddress() {
      return this.idAddressDelivery;
    },
    CarriersHome() {
      const list = this.carriers.filter((item) => {
        return item.CarrierType === 'AtHome';
      });

      return list;
    },
    CarriersRelayPoint() {
      const list = this.carriers.filter((item) => {
        return item.CarrierType === 'RelayPoint';
      });

      return list;
    },
    CarriersClickAndCollect() {
      const list = this.carriers.filter((item) => {
        return item.CarrierType === 'ClickAndCollect';
      });

      return list;
    },
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },
  },
  methods: {
    async onTabChecked() {
      // if (this.activeTab === "RelayPoint") {
      await this.carriersService.fetchCarriers({
        idAddress: this.idAddressDelivery,
        carrierType: this.activeTab,
        responseLevel: 'details',
      });
    },

    setCarrier(carrier) {},
  },
  mounted() {
    // this.onTabChecked();
  },
  components: {
    Tabs,
    TabPane,
    HomeIcon,
    MapPinIcon,
    Carriers,
    Collapse,
    CollapseItem,
  },
};
