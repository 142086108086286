
import { Button, Table, TableColumn } from "element-ui";

export default {
  layout: "account",
  name: "MyAccount",
  data() {
    return {
      userSession: this.$auth.user.Customer,
      // promoCodeData: [],
    };
  },
  mounted() {
    this.initUser();
  },

  activated() {
    this.initUser();
  },
  updated() {
    if (process.client) {
      document.body.classList.add("account-index-page");
    }
  },
  unmounted() {
    if (process.client) {
      document.body.classList.remove("account-index-page");
    }
  },
  methods: {
    initUser() {
      this.$auth.fetchUser().then((res) => {
        this.userSession = res.data.AuthData.Customer;
      });

    },

  },
  components: {
    Button,
    Table,
    TableColumn,
  },
};
