
import BankCardForm from './BankCardForm.vue';
import { Button, Checkbox } from 'element-ui';
import hipayMixin from '../hipayMixin';

export default {
  mixins: [hipayMixin],
  props: {
    paymentMethod: {
      type: Object,
    },
  },
  data() {
    return {
      generalConditionsSale: false,
      valide: false,
    };
  },
  computed: {
    validate() {
      return this.valide;
    },
  },
  components: {
    Button,
    Checkbox,
    BankCardForm,
  },
  methods: {
    submit() {
      const bankCardForm = this.$refs['bankCardForm'];
      const $this = this;
      if (bankCardForm.formIsValide) {
        bankCardForm.onSubmit().then(
          function (response) {
            $this.startPayment({
              paymentData: response,
              paymentMethod: $this.paymentMethod,
            });
          },
          function (errors) {
            /* Display first error */
            $this.errors = errors;
          }
        );
      }
    },
    validateForm($event) {
      this.valide = $event.valid;
    },
  },
};
