
import Breadcrumb from "@/components/Breadcrumb.vue";
import ContentLayout from "@/pages/content/components/ContentLayout.vue";
import CardTitleBody from "@/components/CardTitleBody.vue";
import { Button } from "element-ui";
import { FaqQuery } from "~/graphql/queries/Faq";

export default {
  layout: "MainLayout",
  name: "FaqPage",
  auth: false,
  data() {
    return {
      faqPage: {},
    };
  },
  apollo: {
    faqPage: {
      query: FaqQuery,
      variables() {
        return {
          lang: this.$i18n.locale,
        };
      },
    },
  },
  components: { Breadcrumb, ContentLayout, CardTitleBody, Button },
};
