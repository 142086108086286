
export default {
  props: {
    title: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
    ImgNotLazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    safeLink(link) {
      let url = this.localePath(link);
      if (link.includes('http')) {
        const listWords = link.split('/');
        url = '';
        for (let i = 3; i < listWords.length; i++) {
          const element = listWords[i];
          url += '/' + element;
        }
        return url;
      }
      if (link[0] !== '/') {
        url = this.localePath('/' + link);
      }

      return url;
    },
    getImage(imageMb) {
      try {
        return imageMb
          ? this.$GLOBAL_VARIABLES.cmsUrl + imageMb.data.attributes.url
          : this.$GLOBAL_VARIABLES.cmsUrl + item.image.data.attributes.url;
      } catch (error) {
        return null;
      }
    },
  },
  components: {},
};
