
import CardBlog from '@/pages/content/components/CardBlog.vue';
import { Button } from 'element-ui';
import { BlogQuery } from '~/graphql/queries/Blogs';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      responsiveSlider: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 74,
        },
      },
    };
  },
  apollo: {
    articles: {
      query: BlogQuery,
      variables() {
        return {
          lang: this.$i18n.locale,
        };
      },
    },
  },
  components: { CardBlog, Button },
};
