
export default {
  data() {
    return {
      animated: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.animated = true;
    }, 100);
  },
};
