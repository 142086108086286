
import { Button, Dialog, Select, Option, Label } from "element-ui";
import { LocalisationHelper } from "@/helpers/LocalisationHelper";
export default {
  data() {
    const localisationHelper = new LocalisationHelper(
      this.$http,
      this.$store,
      this.$auth,
      this.$i18n.locale
    );

    const defaultIdCountry = localisationHelper.getAppCountry()
      ? localisationHelper.getAppCountry().IdCountry
      : 0;

    return {
      localisationHelper,
      langueValue: this.$i18n.locale,
      countryValue: defaultIdCountry,
      currencyValue: "",
    };
  },

  created() {},
  async mounted() {
    const defaultIdCountry = this.localisationHelper.getAppCountry()
      ? this.localisationHelper.getAppCountry().IdCountry
      : 0;

    this.countryValue = defaultIdCountry;
    this.currencyValue =
      this.localisationHelper.getAppCurrency().CurrencyIsoCode;
  },
  computed: {
    showLocalisation: {
      get() {
        return this.$store.state.shop.localisation.localisationDialogState;
      },
      set(value) {
        this.$store.commit("shop/localisation/toggleDialog", value);
      },
    },
    languages() {
      return this.$i18n.localeCodes;
    },
    countries() {
      return this.localisationHelper.getCountries();
    },
    currencies() {
      const selectedCountries = this.countries.filter((item) => {
        return item.IdCountry === this.countryValue;
      });

      if (selectedCountries.length) {
        return selectedCountries[0].CountryCurrencies;
      }
      return null;
    },
    countrySelectd() {
      const countrySelectedIndex = this.countries.findIndex(
        (item) => item.IdCountry === this.countryValue
      );
      // this.langueValue = this.countries[countrySelectedIndex].LanguageIsoCode;

      return this.countries[countrySelectedIndex];
    },

    currencySelectd() {
      const currencySelectedIndex = this.currencies.findIndex(
        (item) => item.CurrencyIsoCode === this.currencyValue
      );

      return this.currencies[currencySelectedIndex];
    },
  },
  methods: {
    showDefaultVal() {
      this.localisationHelper.setAppCountry(this.countrySelectd);
      this.localisationHelper.setAppCurrency(this.currencySelectd);
      this.$i18n.setLocale(this.langueValue);
      // this.showLocalisation = false;
      // switchLocalePath(lg);

      if (process.client) {
        window.location.href = this.switchLocalePath(this.langueValue);
      }
    },
    onCountrySelected() {
      const currencyList = this.countrySelectd.CountryCurrencies;
      if (currencyList.length > 0) {
        const currencyIndex = currencyList.findIndex(
          (item) => item.IsMainCurrency === true
        );

        if (currencyIndex != -1) {
          this.currencyValue = currencyList[currencyIndex].CurrencyIsoCode;
        } else {
          this.currencyValue = currencyList[0].CurrencyIsoCode;
        }
      }

      const lgIndex = this.languages.findIndex(
        (item) => item === this.countrySelectd.LanguageIsoCode
      );

      if (lgIndex !== -1) {
        this.langueValue = this.languages[lgIndex];
      }
    },
  },
  components: {
    Button,
    Dialog,
    Select,
    Option,
    Label,
  },
};
