
import { ChevronDownIcon } from "vue-feather-icons";
import SlideDownUp from "./transitions/SlideDownUp.vue";
import SlideDownUp2 from "./transitions/SlideDownUp2.vue";
import { Collapse, CollapseItem } from "element-ui";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      active: false,
      subCatSelected: 0,
      height: null,
      activeNames: "",
    };
  },
  methods: {
    openToggle() {
      const container = this.$refs.list;
      if (!container.classList.contains("active")) {
        /** Show the container. */
        container.classList.add("active");
        this.active = true;
        container.style.height = "auto";

        /** Get the computed height of the container. */
        this.height = container.clientHeight + "px";

        /** Set the height of the content as 0px, */
        /** so we can trigger the slide down animation. */
        container.style.height = "0px";

        /** Do this after the 0px has applied. */
        /** It's like a delay or something. MAGIC! */
        setTimeout(() => {
          container.style.height = this.height;
        }, 0);

        // setTimeout(() => {
        //   container.style.height = "auto";
        //   // this.height = null;
        // }, 1000);

        /** Slide up. */
      } else {
        /** Set the height as 0px to trigger the slide up animation. */
        container.style.height = "0px";

        /** Remove the `active` class when the animation ends. */
        const $this = this;
        container.addEventListener(
          "transitionend",
          () => {
            container.classList.remove("active");

            $this.active = false;
          },
          { once: true }
        );
      }
    },
    hasSubCategory(item) {
      let hasSubCat = false;
      if (item.SubCategories) {
        if (item.SubCategories.length) {
          hasSubCat = true;
        }
      }

      return hasSubCat;
    },
  },
  computed: {},
  mounted() {},
  components: {
    ChevronDownIcon,
    SlideDownUp,
    SlideDownUp2,
    Collapse,
    CollapseItem,
  },
};
