
import { Button, Skeleton } from "element-ui";
import { ProductHelpers } from "@/helpers/ProductHelpers";

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    size: {
      required: true,
      type: String,
    },
    idProductAttribute: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      shopSelected: null,
      shops: [],
      valide: true,
    };
  },
  computed: {},
  methods: {
    isSelected(shop) {
      if (this.shopSelected) {
        return shop.IdStore === this.shopSelected.IdStore;
      }
      return false;
    },
    onSelectAddress($event, shop) {
      if (!$event.disabled) {
        this.shopSelected = shop;
        this.valide = true;
      }
    },
    next() {
      if (this.shopSelected === null) {
        this.valide = false;
        return;
      }
      this.$emit("next", {
        shop: this.shopSelected,
      });
    },
    change(step) {
      this.$emit("change", {
        step,
      });
    },
  },
  async mounted() {
    const productHelpers = new ProductHelpers(this.$http, this);
    const response = await productHelpers.reservationShopAvailability(
      this.product.IdProduct,
      this.idProductAttribute
    );
    if (response) {
      this.shops = response.StockInStore;
    }
    if (response.status === 200) {
      this.shops = response.data.StockInStore;
    }
    // this.shops =
  },
  components: {
    Button,
    Skeleton,
  },
};
