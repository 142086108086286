
import Breadcrumb from "@/components/Breadcrumb.vue";
import ContentLayout from "@/pages/content/components/ContentLayout.vue";
import CardTitleBody from "@/components/CardTitleBody.vue";
import { Button, Collapse, CollapseItem } from "element-ui";
import { SingleFAQ } from "~/graphql/queries/Faq";
import { FaqQuery } from "~/graphql/queries/Faq";

export default {
  layout: "MainLayout",
  name: "faq-permalink",
  auth: false,
  async asyncData({ app, store, query, i18n, route, error }) {

    try {
      const res = await app.apolloProvider.defaultClient.query({
        query: SingleFAQ,
        variables: {
          lang: i18n.locale,
          permalink: route.params.permalink,
        },
      });

      const faqs = res.data.faqs;
      const page = faqs.data[0].attributes;
      const links = {};
      links[page.locale] = { permalink: page.seo.permalink };

      page.localizations.data.map((item) => {
        links[item.attributes.locale] = {
          permalink: item.attributes.seo.permalink,
        };
      });
      await store.dispatch("i18n/setRouteParams", links);
      return {
        faqs,
        breadcrumbLinks: [
          {
            link: app.localePath("/"),
            text: app.i18n.t("Home"),
          },
          {
            link: app.localePath("/content/faq"),
            text: app.i18n.t("Faq"),
          },
          {
            text: faqs.data[0].attributes.title,
          },
        ],
      };
    } catch (err) {
      return error({
        statusCode: 404,
        message: i18n.t("Page not found"),
      });
    }
  },
  data() {
    return {
      activeName: "0",
      faqPage: {},
    };
  },
  apollo: {
    faqPage: {
      query: FaqQuery,
      variables() {
        return {
          lang: this.$i18n.locale,
        };
      },
    },
  },
  components: {
    Breadcrumb,
    ContentLayout,
    CardTitleBody,
    Button,
    Collapse,
    CollapseItem,
  },
};
