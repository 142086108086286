
import { Button, FormItem, Form, Input, Checkbox } from "element-ui";
import { ReferralService } from "@/services/ReferralService";
import { CopyIcon } from "vue-feather-icons";
import ContinueMyShoppingLink from "../../components/ContinueMyShoppingLink.vue";
export default {
  layout: "MainLayout",
  auth: "guest",
  middleware: ["auth"],
  head() {
    return {
      title: this.$t("home_title"),
      meta: [
        {
          name: "og:title",
          content: this.$t("home_meta_discription"),
        },
        {
          name: "og:description",
          content: this.$t("home_meta_discription"),
        },
        {
          name: "og:image",
          content: "https://dev.jeanmarcphilippe.com/assets/img/refer2.jpg",
        },
      ],
    };
  },
  data() {
    return {
      referralService: new ReferralService({ http: this.$http }),

      codeData: null,
      animeCopy: false,

      step: 1,
      breadcrumbLinks: [
        {
          link: "/",
          text: this.$t("Home"),
        },
        {
          text: this.$t("Request"),
        },
      ],
      sponsoredForm: {
        email: "",
        newletter: false,
        cvg: "",
      },

      rules: {
        email: [
          {
            required: true,
            message: this.$t("Required Field"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("Please input correct email address"),
            trigger: "blur",
          },
        ],
        cvg: [
          {
            required: true,
            message: this.$t("Required Field"),
            trigger: "change",
          },
        ],
      },
    };
  },

  computed: {
    code() {
      try {
        const codeParam = this.$route?.params?.code?.split("-");
        return codeParam[0];
      } catch (error) {}

      return null;
    },
  },
  methods: {
    onSubmit() {
      this.$refs["sponsoredForm"].validate(async (valid) => {
        if (valid) {
          this.referralService
            .sponsoredPromocode({
              email: this.sponsoredForm.email,
              referralCode: this.code,
              languageIsoCode: this.$i18n.locale,
              newsletter: this.sponsoredForm.newletter,
            })
            .then((data) => {
              if (data?.Promocode?.State?.Active) {
                if (data?.Promocode?.State?.Used) {
                  this.$message.error({
                    message: this.$t("This code is already used"),
                    duration: 3000,
                    showClose: true,
                  });
                } else {
                  this.codeData = data;
                  this.step = 2;
                }
              } else {
                this.$message.error({
                  message: this.$t("This code is disabled"),
                  duration: 3000,
                  showClose: true,
                });
              }
            })
            .catch((error) => {
              const msg = error?.response?.data?.message;
              if (msg) {
                this.$message.error({
                  message: msg,
                  duration: 3000,
                  showClose: true,
                });
              }
            })
            .finally(() => {});
        } else {
          return false;
        }
      });
    },

    copyPromoCode(text) {
      if (process.client) {
        navigator.clipboard.writeText(text);
        this.animeCopy = true;
        setTimeout(() => {
          this.animeCopy = false;
        }, 1000);
        this.$message.info({
          message: this.codeData?.Promocode?.Code + " - " + this.$t("Copied"),
          duration: 1000,
          showClose: true,
        });
      }
    },
  },
  components: {
    Button,
    FormItem,
    Form,
    Input,
    Checkbox,
    CopyIcon,
  },
};
