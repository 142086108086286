
import { postData } from '@/helpers/postData.js';
import { Button, CollapseTransition } from 'element-ui';

export default {
  layout: 'authenticate',
  name: 'HomePage',
  async asyncData(context) {},
  data() {
    return {
      val: '',
    };
  },
  components: {
    Button,
  },
  mounted() {},
  methods: {
    setData() {
      this.$auth.$storage.setUniversal('testi', { name: 'omar 10' });
    },
    getData() {
      this.val = this.$auth.$storage.getUniversal('testi');
    },
  },
};
