
import { InfoIcon } from "vue-feather-icons";
import { Button } from "element-ui";

export default {
  props: {
    reward: {
      type: Object,
    },
  },
  components: {
    InfoIcon,
    Button,
  },
};
